import { observer } from 'mobx-react-lite'
import React from 'react'

import { useGameContext } from '@/pages/game/context/game-context'

import { useSpotify } from '../../useSpotify'
import { PlaybackButtons } from '../PlaybackButtons'
import { useExternalPlayback } from '../useExternalPlayback'

interface PlaybackControlsInternalProps {
  setShowDeviceSelectorModal: (show: boolean) => void
}

export const PlaybackControlsExternal = observer(function PlaybackControlsExternal({
  setShowDeviceSelectorModal
}: PlaybackControlsInternalProps): React.ReactElement {
  const { gameData, isStartedGame } = useGameContext()

  const { devices, playback } = useSpotify()
  const { activeDevice } = devices

  const {
    handleClickNext,
    handleClickPause,
    handleClickPlay,
    handleClickPrevious,
    isLoading: isLoadingPlaybackQuery,
    isPlaying
  } = useExternalPlayback(setShowDeviceSelectorModal)

  const { isLoading } = playback

  const isPlaybackDisabled = !isStartedGame || !activeDevice || isLoading || isLoadingPlaybackQuery

  return (
    <PlaybackButtons
      currentItemIndex={gameData.currentItemIndex}
      isPlaybackDisabled={isPlaybackDisabled}
      isPlaying={isPlaying}
      onClickNext={handleClickNext}
      onClickPause={handleClickPause}
      onClickPlay={handleClickPlay}
      onClickPrevious={handleClickPrevious}
    />
  )
})
