import { TriviaList } from '@repo/types'
import { Container, Description, Heading, IconButton, Panel, Spinner, TrashIcon } from '@repo/ui'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Balancer from 'react-wrap-balancer'

import { apiClient } from '@/api/api-client'
import { AppHeader } from '@/components/AppHeader'
import { Button } from '@/components/Button'
import { ExternalLink } from '@/components/ExternalLink'
import { BackButton } from '@/components/icon-buttons/IconButtons'
import { List } from '@/components/List'
import { Main } from '@/components/Main'
import { Page } from '@/components/Page'
import { PageHeading } from '@/components/PageHeading'
import { TipBadge } from '@/components/TipBadge'
import { useDialogContext } from '@/dialogs/DialogContext'
import { MixpanelService } from '@/mixpanel'
import { InvalidIcon } from '@/pages/trivia-lists/InvalidIcon'
import { useDeleteUserTriviaList } from '@/pages/trivia-lists/queries/useDeleteUserTriviaList'
import { useQueryUserTriviaLists } from '@/pages/trivia-lists/queries/useQueryUserTriviaLists'
import StoreService from '@/store/StoreService'

import { TRIVIA_KNOWLEDGE_BASE_URL } from '../edit-trivia-list/edit-trivia-list'
import { validateTriviaList } from '../edit-trivia-list/utils/validate-list'

const PAGE_TITLE = 'Manage Trivia Lists'

export const ManageTriviaLists = observer(() => {
  const { data: triviaLists = [], isLoading } = useQueryUserTriviaLists()
  const { mutate: deleteUserTriviaList } = useDeleteUserTriviaList()
  const store = StoreService.getStore()

  const [isStartingGame, setIsStartingGame] = useState<string | undefined>()
  const history = useHistory()

  const handleClick = async (triviaList: TriviaList) => {
    setIsStartingGame(triviaList.id)

    runInAction(() => {
      store.isLoadingGame = true
    })

    const { gameId } = await apiClient.createGame({ triviaList, type: 'trivia' })

    history.push('/game/setup/gameplay')

    setIsStartingGame(undefined)

    MixpanelService.createGameTrivia(gameId, {
      triviaList,
      type: 'trivia'
    })
  }

  const { setConfirmPrompt } = useDialogContext()

  const handleClickDelete = (listId: string) => {
    setConfirmPrompt({
      description: 'This action cannot be undone',
      heading: 'Are you sure you want to delete this trivia list?',
      onConfirm: () => deleteUserTriviaList(listId)
    })
  }

  if (isLoading) return <Spinner label='Loading...' />

  const validatedTriviaLists = triviaLists.map(validateTriviaList)

  return (
    <Page title={PAGE_TITLE}>
      <AppHeader
        actionsLeft={<BackButton url='/' />}
        header={<PageHeading>{PAGE_TITLE}</PageHeading>}
        secondaryHeader={
          <Container className='py-6' size='medium'>
            <Button text='Create Trivia List' to='/trivia-lists/create' />
          </Container>
        }
      />

      <Main>
        <Container className='flex flex-col gap-12' size='medium'>
          {!validatedTriviaLists.length && (
            <Panel className='text-center'>You haven't created any trivia bingo lists yet</Panel>
          )}

          {Boolean(validatedTriviaLists.length) && (
            <div className='flex flex-col gap-4'>
              <Heading className='mb-0'>Your Trivia Lists</Heading>
              <List>
                {validatedTriviaLists.map(triviaList => (
                  <List.Item
                    actions={
                      <div className='flex items-center gap-3'>
                        <Button
                          disabled={
                            !triviaList.validation.isValid || isStartingGame === triviaList.id
                          }
                          isLoading={isStartingGame === triviaList.id}
                          size='small'
                          text='Start Game'
                          onClick={() => handleClick(triviaList)}
                        />
                        <Button
                          size='small'
                          text='Edit'
                          to={`/trivia-lists/edit/${triviaList.id}`}
                        />

                        <IconButton
                          size='small'
                          title='Delete Playlist'
                          onClick={() => {
                            handleClickDelete(triviaList.id)
                            // MixpanelService.track('Custom Trivia List Deleted')
                          }}
                        >
                          <TrashIcon />
                        </IconButton>
                      </div>
                    }
                    key={triviaList.id}
                    showActionsOnHover
                  >
                    <div className='flex gap-2'>
                      {triviaList.validation.isValid ? null : (
                        <div className='flex size-6 items-center'>
                          <InvalidIcon className='size-4' />
                        </div>
                      )}

                      <div className='flex flex-col gap-1'>
                        <span>{triviaList.title}</span>

                        {triviaList.validation.isValid ? null : (
                          <Balancer className='text-muted text-xs'>
                            This list requires editing before it can be used to play a game
                          </Balancer>
                        )}
                      </div>
                    </div>
                  </List.Item>
                ))}
              </List>
            </div>
          )}

          <Panel>
            <TipBadge />
            <Description className='mt-2'>
              <ExternalLink href={TRIVIA_KNOWLEDGE_BASE_URL}>
                Need a hand? Visit our knowledge base
              </ExternalLink>
            </Description>
          </Panel>
        </Container>
      </Main>
    </Page>
  )
})
