import { ColourPalette, PlaylistType, Song } from '@repo/types'
import { Badge, ButtonGroup, Container, Heading, Label, Panel } from '@repo/ui'
import { Dialog } from '@repo/ui/client'
import { observer } from 'mobx-react-lite'
import React, { useRef, useState } from 'react'
import CSVReader, { IFileInfo } from 'react-csv-reader'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { AppHeader } from '@/components/AppHeader'
import { AppHeaderLogo } from '@/components/AppHeaderLogo'
import { Button } from '@/components/Button'
import { ExternalLink } from '@/components/ExternalLink'
import { BackButton } from '@/components/icon-buttons/IconButtons'
import { List } from '@/components/List'
import { Main } from '@/components/Main'
import { Page } from '@/components/Page'
import { PageHeading } from '@/components/PageHeading'
import { TermsOfService } from '@/components/TermsOfService'
import { Title } from '@/components/Title'
import { MixpanelService } from '@/mixpanel'
import { MINIMUM_ITEM_COUNT } from '@/utils/constants'

import { SongSpreadsheetInputSchema } from './playlist-schema'
import { useCreateUserPlaylist } from '../playlist-queries/user-playlists/useCreateUserPlaylist'

export const ImportSpreadsheetRoute = observer(
  function ImportSpreadsheetRoute(): React.ReactElement {
    return <ImportSpreadsheet />
  }
)

export const ImportSpreadsheetRouteAdmin = observer(
  function ImportSpreadsheetRouteAdmin(): React.ReactElement {
    return <ImportSpreadsheet baseUrl='/admin/' />
  }
)

interface ImportSpreadsheetProps {
  baseUrl?: string
}

const ImportSpreadsheet = observer(function ImportSpreadsheet({
  baseUrl = '/'
}: ImportSpreadsheetProps): React.ReactElement {
  const inputRef = useRef<HTMLInputElement | null>(null)

  const [fileData, setFileData] = useState<FileData | null>(null)
  const history = useHistory()
  const [isCreatingPlaylist, setIsCreatingPlaylist] = useState(false)

  const { mutateAsync: createUserPlaylist } = useCreateUserPlaylist()

  const [errors, setErrors] = useState<string[]>([])

  const extractedHeadings = useRef<string[]>([])

  const transformHeader = (header: string) => {
    if (header) {
      extractedHeadings.current.push(header.toLowerCase())
    }
    return header.toLowerCase().replace(/\W/g, '_')
  }

  const handleFileLoaded = (data: { artist: string; title: string }[], fileInfo: IFileInfo) => {
    const errorMessages: string[] = []

    const headings = extractedHeadings.current
    const required = ['title', 'artist']
    const headingsValid = required.every(item => headings.indexOf(item) > -1)

    if (!headingsValid) {
      errorMessages.push(
        `Please ensure your file is in CSV format and contains headings labelled "Title" and "Artist"`
      )
    } else {
      const fileContentsErrors = validateFileContents(data)
      if (fileContentsErrors.length !== 0) {
        errorMessages.push(...fileContentsErrors)
      }
    }

    if (errorMessages.length) {
      setErrors(errorMessages)
      reset()
      return
    }

    const transformedData = convertToPlaylistSongs(data)
    setFileData({
      fileInfo,
      songs: transformedData
    })
  }

  const reset = () => {
    setFileData(null)
    extractedHeadings.current = []

    if (inputRef?.current) {
      inputRef.current.value = ''
    }
  }

  const handleCancelClick = () => {
    reset()
    setErrors([])
  }

  const handleSubmit = async () => {
    if (!fileData?.songs) return

    const parseResult = SongSpreadsheetInputSchema.safeParse(fileData.songs)

    if (!parseResult.success) {
      console.error(parseResult.error.errors)
      setErrors([
        `There was an error importing the file. Please make sure each row contains a "title" and an "artist".`
      ])
      return
    }

    setIsCreatingPlaylist(true)

    const { playlistId } = await createUserPlaylist({
      colour: ColourPalette.PURPLE,
      id: '',
      playbackOptions: [],
      songs: parseResult.data,
      title: 'New Playlist',
      type: PlaylistType.USER_CUSTOM_PLAYLISTS
    })

    MixpanelService.importPlaylistSpreadsheet(playlistId, parseResult.data.length)

    setIsCreatingPlaylist(false)

    history.push(`${baseUrl}playlists/edit/${playlistId}`)
  }

  const generateCSVTemplate = () => {
    const rows = [
      ['Title', 'Artist'],
      ['Hey Jude', 'The Beatles'],
      ['Gimme Shelter', 'The Rolling Stones']
    ]

    const csvContent = 'data:text/csv;charset=utf-8,' + rows.map(e => e.join(',')).join('\n')

    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', 'Rockstar Bingo - Sample CSV Template.csv')
    document.body.appendChild(link)

    link.click()
  }

  return (
    <Page title='Import a Spreadsheet'>
      <AppHeader
        actionsLeft={<BackButton url={`${baseUrl}playlists/create`} />}
        header={<AppHeaderLogo />}
      />

      <Main>
        <Container size='medium'>
          <PageHeading className='mb-6 text-center' size='large'>
            Import a Spreadsheet
          </PageHeading>
        </Container>

        <Container size='small'>
          <Panel>
            {!fileData && (
              <>
                <div className='flex flex-col gap-6'>
                  <div className='flex flex-col gap-2'>
                    <Label>Required Format:</Label>
                    <div className='text-lg'>CSV (comma-separated values)</div>
                    <p className='text-sm text-muted'>
                      UTF-8 format.{' '}
                      <ExternalLink href='https://en.wikipedia.org/wiki/Comma-separated_values'>
                        Learn more.
                      </ExternalLink>
                    </p>
                  </div>

                  <div className='flex flex-col gap-2'>
                    <Label>Minimum songs:</Label>
                    <Badge>{MINIMUM_ITEM_COUNT}</Badge>
                  </div>

                  <div className='flex flex-col gap-2'>
                    <Label>Maximum songs:</Label>
                    <Badge>300</Badge>
                  </div>

                  <div className='mt-4'>
                    The CSV file must include a heading for &quot;Title&quot; (the song name) and a
                    heading for &quot;Artist&quot;. The headings are not case sensitive.
                  </div>

                  <div className='py-2'>
                    <Button
                      text='Download a sample CSV file'
                      variant='anchor'
                      onClick={generateCSVTemplate}
                    />
                  </div>

                  <FileInputContainerStyled>
                    <Button
                      text='Upload Spreadsheet'
                      onClick={() => {
                        inputRef?.current?.click()
                      }}
                    />

                    <CSVReader
                      inputId='csvUpload'
                      parserOptions={{
                        dynamicTyping: true,
                        header: true,
                        skipEmptyLines: true,
                        transformHeader
                      }}
                      ref={inputRef}
                      onError={error => {
                        alert(error)
                      }}
                      onFileLoaded={handleFileLoaded}
                    />
                  </FileInputContainerStyled>
                </div>
              </>
            )}

            {fileData && (
              <>
                <Label>Ready for import</Label>

                <Title
                  className='mt-6'
                  description={`${fileData.songs.length} songs`}
                  heading={fileData?.fileInfo.name}
                />

                <ButtonGroup className='mt-8' direction='column'>
                  <Button text='Cancel' variant='outlined' onClick={handleCancelClick} />

                  <Button
                    disabled={
                      (fileData?.songs && fileData?.songs.length < MINIMUM_ITEM_COUNT) ||
                      isCreatingPlaylist
                    }
                    isLoading={isCreatingPlaylist}
                    text='Continue'
                    onClick={handleSubmit}
                  />
                </ButtonGroup>
              </>
            )}
          </Panel>
        </Container>

        <TermsOfService />
      </Main>

      <Dialog isVisible={Boolean(errors.length)}>
        <Dialog.Body className='pt-0'>
          <Heading className='mb-4'>Sorry, something went wrong</Heading>
          <Heading className='mb-2' size='small'>
            Errors
          </Heading>
          <List>
            {errors.map((error, index) => (
              <List.Item key={index}>{error}</List.Item>
            ))}
          </List>
        </Dialog.Body>
        <Dialog.Footer>
          <Button text='Continue' onClick={handleCancelClick} />
        </Dialog.Footer>
      </Dialog>
    </Page>
  )
})

const FileInputContainerStyled = styled.div`
  input[type='file'] {
    display: none;
  }
`

function convertToPlaylistSongs(data: { artist: string; title: string }[]): Song[] {
  return data.map(({ artist, title }, index) => {
    return {
      artist,
      id: index,
      title
    }
  })
}

function validateFileContents(data: { artist: string; title: string }[]) {
  const errors = []

  const rowCount = data.length

  if (rowCount < MINIMUM_ITEM_COUNT) {
    errors.push(
      `Please include a minimum of ${MINIMUM_ITEM_COUNT} songs. This import only contained ${rowCount}.`
    )
  }

  if (rowCount > 300) {
    errors.push(`Please include a maximum of 300 songs. This import contained ${rowCount}.`)
  }

  return errors
}

interface FileData {
  fileInfo: IFileInfo
  songs: Song[]
}
