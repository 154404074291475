import { Container } from '@repo/ui'
import { ScrollContainer } from '@repo/ui/client'
import { observer } from 'mobx-react-lite'

import { ColumnName } from './ColumnName'
import { CurrentDrawnItem } from './CurrentDrawnItem'
import { Columns, generateBingoBoard } from './generateBingoBoard'
import { NumbersItem } from './NumbersItem'
import { PreviousDrawnItemsCalled } from './PreviousDrawnItems'
import { useGameContext } from '../context/game-context'

export const NumbersBoard = observer(() => {
  const {
    currentColumnName,
    currentDrawnItem,
    drawnItemsCalled,
    gameData,
    previousDrawnItemsCalled
  } = useGameContext()

  if (gameData.type !== 'numbers') return null

  const board = generateBingoBoard(drawnItemsCalled, currentDrawnItem)
  const showCurrentDrawnItem = currentDrawnItem && currentColumnName

  return (
    <ScrollContainer className='justify-center'>
      {showCurrentDrawnItem && (
        <Container className='my-8 flex items-center justify-center gap-4' size='extra-large'>
          <PreviousDrawnItemsCalled previousDrawnItemsCalled={previousDrawnItemsCalled} />

          <CurrentDrawnItem drawnItem={currentDrawnItem} />

          <div className='flex w-full'></div>
        </Container>
      )}

      <Container
        className='m-auto mb-8 flex flex-col items-center justify-center gap-1 sm:gap-2'
        size='extra-large'
      >
        {Object.values(Columns).map(columnName => (
          <div className='grid-cols-16 grid w-full gap-1 sm:gap-2' key={columnName}>
            <ColumnName columnName={columnName} isCurrent={currentColumnName === columnName} />

            {board[columnName].map(item => (
              <NumbersItem key={item.id} {...item} />
            ))}
          </div>
        ))}
      </Container>
    </ScrollContainer>
  )
})
