export function createFilename(input: string): string {
  let filename = input.toLowerCase()
  filename = filename.replace(/\s+/g, '-')

  // Remove all non-alphanumeric characters except for hyphens
  filename = filename.replace(/[^a-z0-9-]/gi, '')

  // Enforce a maximum of 200 characters, minus the 5 characters needed for the .json extension
  if (filename.length > 195) {
    filename = filename.substring(0, 195)
  }

  return `${filename}.json`
}

export function downloadFile(json: string, filename: string) {
  const element = document.createElement('a')
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(json))
  element.setAttribute('download', filename)
  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}
