import { Collections, TriviaList } from '@repo/types'
import { collection, doc, getDoc, getDocs, orderBy, query } from 'firebase/firestore'

import { databaseRef } from '@/firebase/firebase'
import { queryClient } from '@/queryClient'
import StoreService from '@/store/StoreService'

export class TriviaListService {
  get user() {
    const store = StoreService.getStore()
    return store.user
  }

  get queries() {
    return {
      userTriviaList: {
        queryFn: (listId: string) => this.fetchTriviaList(listId),
        queryKey: (listId: string) => [
          Collections.USER_TRIVIA_LISTS,
          `userId:${this.user.userId}`,
          `listId:${listId}`
        ]
      },

      userTriviaLists: {
        queryFn: () => this.fetchUserTriviaLists(),
        queryKey: [Collections.USER_TRIVIA_LISTS, `userId:${this.user.userId}`]
      }
    }
  }

  async fetchUserTriviaLists(): Promise<TriviaList[]> {
    const triviaListsCollection = collection(
      databaseRef,
      Collections.USERS,
      this.user.userId,
      Collections.USER_TRIVIA_LISTS
    )

    const triviaListsQuery = query(triviaListsCollection, orderBy('timestamp', 'desc'))

    const triviaListSnapshot = await getDocs(triviaListsQuery)

    if (triviaListSnapshot.empty) return []

    const userTriviaLists: TriviaList[] = []

    triviaListSnapshot.docs.forEach(doc => {
      const triviaList = doc.data() as TriviaList
      triviaList.id = doc.id
      userTriviaLists.push(triviaList)
    })

    return userTriviaLists
  }

  async fetchTriviaList(listId: string) {
    const documentRef = doc(
      databaseRef,
      Collections.USERS,
      this.user.userId,
      Collections.USER_TRIVIA_LISTS,
      listId
    )

    const snapshot = await getDoc(documentRef)
    return snapshot.data() as TriviaList
  }

  queryUserTriviaLists() {
    return queryClient.fetchQuery(this.queries.userTriviaLists.queryKey, () =>
      this.fetchUserTriviaLists()
    )
  }

  queryUserTriviaList(listId: string) {
    const { queryFn, queryKey } = this.queries.userTriviaList

    return queryClient.fetchQuery(queryKey(listId), () => queryFn(listId))
  }
}
