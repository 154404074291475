import { TriviaList } from '@repo/types'
import { useMutation, useQueryClient } from 'react-query'

export function useCreateTriviaList(
  queryKey: string[],
  mutationFn: (triviaList: TriviaList) => Promise<{ listId: string }>,
  queryFn: () => Promise<TriviaList[]>
) {
  const queryClient = useQueryClient()

  const mutation = useMutation(mutationFn, {
    onError: (_error, _newPlaylist, context) => {
      if (!context?.previousData) return

      // Rollback on error
      queryClient.setQueryData(queryKey, context.previousData)
    },
    onMutate: async (triviaList: TriviaList) => {
      await queryClient.cancelQueries(queryKey)

      const previousData = queryClient.getQueryData<TriviaList[]>(queryKey)
      if (!previousData) return

      const updatedLists = [...previousData, triviaList]

      // Optimistically add the list to the cache
      queryClient.setQueryData(queryKey, updatedLists)

      return { previousData }
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKey)
      queryClient.fetchQuery(queryKey, queryFn)
    }
  })

  return mutation
}
