import { differenceInDays, format, formatDistanceToNow } from 'date-fns'
import { observer } from 'mobx-react-lite'
import { now } from 'mobx-utils'

const MAX_RELATIVE_TIME_IN_DAYS = 1 // Threshold: 1 day

export const LastSaved = observer(
  ({ isSaving, timestamp }: { isSaving: boolean; timestamp: Date | undefined }) => {
    const currentTime = now(60 * 1000) // Update every minute

    if (!timestamp) {
      return null
    }

    if (isSaving) {
      return <div className='text-sm'>Saving...</div>
    }

    const daysDifference = differenceInDays(currentTime, timestamp)

    return (
      <div className='text-sm text-muted'>
        Last saved:{' '}
        {daysDifference < MAX_RELATIVE_TIME_IN_DAYS
          ? formatDistanceToNow(timestamp, { addSuffix: true })
          : format(timestamp, 'MMMM d, yyyy h:mm a')}
      </div>
    )
  }
)
