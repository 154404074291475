import { Column, DrawnTriviaItem } from '@repo/types'
import { Button, Container, merge } from '@repo/ui'
import { ScrollContainer } from '@repo/ui/client'
import classNames from 'classnames'
import debounce from 'lodash.debounce'
import { observer } from 'mobx-react-lite'
import React, {
  forwardRef,
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import styled from 'styled-components'

import {
  getNextIndex,
  updateGameCurrentDrawnItem,
  updateGameDrawnItems
} from '@/store/game/updateGameDocument'

import { useGameContext } from '../pages/game/context/game-context'

const DEBOUNCE_TIME_MS = 300

interface TriviaListRow extends DrawnTriviaItem {
  availableTargetIndexes?: number[]
  className?: string
  currentItemIndex: number | null
  index: number
  isStartedGame?: boolean
  onChangeIndex: (index: number, targetIndex: number) => void
  onClickDrawNow: (index: number) => void
  onClickQueueNext: (index: number) => void
}

export const TriviaListRow = observer(
  forwardRef<HTMLLIElement, TriviaListRow>(
    (
      {
        availableTargetIndexes = [],
        className,
        column,
        currentItemIndex,
        index,
        isStartedGame = false,
        onChangeIndex,
        onClickDrawNow,
        onClickQueueNext,
        trivia
      }: TriviaListRow,
      ref
    ) => {
      const canShowDrawAndQueue = () => {
        if (!isStartedGame) return false

        return currentItemIndex !== null && index > currentItemIndex
      }

      const showDrawAndQueue = canShowDrawAndQueue()

      const showControls = currentItemIndex !== null && index > currentItemIndex

      const isActive = currentItemIndex === index
      const hasBeenDrawn = Boolean(currentItemIndex && currentItemIndex > index)

      return (
        <ListRowStyled
          className={classNames(
            'list-row',
            'relative',
            'flex',
            {
              active: isActive,
              played: hasBeenDrawn,
              'show-controls': showDrawAndQueue
            },
            className
          )}
          ref={ref}
        >
          <div className='flex flex-1 flex-wrap gap-2'>
            <div className='flex w-full min-w-64 flex-1 items-center gap-3'>
              <ColumnLabelStyled className='size-12'>
                <span>{Column[column]}</span>
              </ColumnLabelStyled>

              <TriviaItem
                isActive={isActive}
                key={`${trivia.question}-${hasBeenDrawn}`}
                showAnswer={hasBeenDrawn}
                trivia={trivia}
              />
            </div>

            {showControls && (
              <div className='flex flex-1 items-center justify-end gap-2'>
                {showDrawAndQueue && (
                  <>
                    {currentItemIndex !== index && (
                      <div className='flex-1 lg:max-w-32'>
                        <Button
                          disabled={!isStartedGame || currentItemIndex === index}
                          size='small'
                          text='Draw Now'
                          onClick={() => onClickDrawNow(index)}
                        />
                      </div>
                    )}

                    {currentItemIndex !== index && (
                      <div className='flex-1 lg:max-w-32'>
                        <Button
                          disabled={
                            (currentItemIndex === null && index === 1) ||
                            (currentItemIndex !== null && currentItemIndex + 1 === index)
                          }
                          size='small'
                          text='Queue Next'
                          variant='outlined'
                          onClick={() => onClickQueueNext(index)}
                        />
                      </div>
                    )}
                  </>
                )}

                <MoveControl
                  availableTargetIndexes={availableTargetIndexes}
                  index={index}
                  onChangeIndex={onChangeIndex}
                />
              </div>
            )}
          </div>

          {currentItemIndex !== null && index <= currentItemIndex && (
            <NumberStyled className='w-16'>{index + 1}</NumberStyled>
          )}
        </ListRowStyled>
      )
    }
  )
)

const MoveControl = observer(
  ({
    availableTargetIndexes,
    index,
    onChangeIndex
  }: {
    availableTargetIndexes: number[]
    index: number
    onChangeIndex: (index: number, targetIndex: number) => void
  }) => {
    return (
      <div className='relative min-h-8 min-w-14'>
        <div className='bg-shade-3 absolute -z-0 flex size-full items-center justify-between overflow-hidden rounded-lg p-1 pl-4 pr-2 focus:ring-1 focus:ring-purple-500'>
          <div className='-mt-1.5'>{index + 1}</div>

          <svg
            fill='currentColor'
            height='16'
            viewBox='0 0 24 24'
            width='16'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z' />
          </svg>
        </div>

        <select
          className='absolute z-10 h-8 opacity-0'
          value={index}
          onChange={e => onChangeIndex(index, Number(e.target.value))}
        >
          {availableTargetIndexes.map(option => (
            <option className='text-xs' disabled={index === option} key={option} value={option}>
              {option + 1}
            </option>
          ))}
        </select>
      </div>
    )
  }
)

const TriviaItem = observer(
  ({
    isActive,
    trivia,
    ...props
  }: {
    isActive?: boolean
    showAnswer?: boolean
    trivia: { answer: string; question: string }
  }) => {
    const [showAnswer, setShowAnswer] = useState(props.showAnswer ?? false)

    return (
      <div className='flex w-full items-center justify-between'>
        <div className='flex flex-col gap-1'>
          {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
          <div className='title'>
            <h3 className='m-0 text-base'>{trivia.question}</h3>
          </div>

          {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
          <div className='subtitle h-5'>
            {showAnswer && trivia.answer}

            {!showAnswer && (
              <Button
                className={merge({ '!text-white': isActive })}
                size='small'
                text='Show Answer'
                variant='anchor'
                onClick={() => setShowAnswer(true)}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
)

interface Props {
  className?: string
  enableScrollToItem?: boolean
  scrollContainer?: 'document' | 'element'
  scrollToItem?: 'current' | 'next' | 'previous'
  showFullList?: boolean
}

export const TriviaList = observer(function TriviaList({
  className,
  enableScrollToItem = true,
  scrollContainer = 'document',
  scrollToItem = 'current',
  showFullList = true
}: Props): React.ReactElement | null {
  const { currentItemIndex, gameData, hasCalledBingo, isStartedGame } = useGameContext()

  if (gameData.type !== 'trivia') throw new Error(`Game type music be 'trivia'`)

  const { drawnItems } = gameData

  const drawnItemsOrdered = addDrawnIndex(drawnItems)

  const list = showFullList
    ? drawnItemsOrdered
    : getPreviousSongs(drawnItemsOrdered, currentItemIndex, 3)

  const refs = list.reduce((acc: { [key: number]: RefObject<HTMLLIElement> }, _, index) => {
    acc[index] = React.createRef<HTMLLIElement>()
    return acc
  }, {})

  const containerRef = useRef<HTMLDivElement | null>(null)

  const handleScrollToItem = useCallback(() => {
    if (!enableScrollToItem) return

    let scrollToSongRef

    switch (scrollToItem) {
      case 'current':
        scrollToSongRef = refs[currentItemIndex]?.current
        break
      case 'next':
        scrollToSongRef =
          refs[currentItemIndex < 73 ? currentItemIndex + 1 : currentItemIndex]?.current
        break
      case 'previous':
        scrollToSongRef =
          refs[currentItemIndex > 0 ? currentItemIndex - 1 : currentItemIndex]?.current
        break
    }

    if (!scrollToSongRef) return

    const currentItemPosition = scrollToSongRef?.offsetTop - 24
    const html = document.documentElement

    const elementToScroll = scrollContainer === 'element' ? containerRef.current : html

    if (elementToScroll && currentItemPosition) {
      requestAnimationFrame(() => elementToScroll.scrollTo(0, currentItemPosition))
    }
  }, [currentItemIndex, scrollToItem, enableScrollToItem, refs, scrollContainer])

  useLayoutEffect(() => {
    handleScrollToItem()
  }, [
    containerRef,
    currentItemIndex,
    enableScrollToItem,
    handleScrollToItem,
    refs,
    scrollContainer,
    scrollToItem
  ])

  const debouncedScrollToSong = useMemo(
    () => debounce(handleScrollToItem, DEBOUNCE_TIME_MS),
    [handleScrollToItem]
  )

  useEffect(() => {
    window.addEventListener('resize', debouncedScrollToSong)

    return () => window.removeEventListener('resize', debouncedScrollToSong)
  }, [debouncedScrollToSong])

  const reorderItems = (index: number, targetIndex: number) => {
    const updatedItems = drawnItemsOrdered.filter((_, i) => i !== index)
    updatedItems.splice(targetIndex, 0, drawnItemsOrdered[index])

    return addDrawnIndex(updatedItems)
  }

  const handleClickDrawNow = (index: number) => {
    const targetIndex = getNextIndex(currentItemIndex)

    if (index === currentItemIndex) return

    const reorderedDrawnItems = reorderItems(index, targetIndex)

    updateGameDrawnItems(gameData.gameId, reorderedDrawnItems)

    updateGameCurrentDrawnItem(gameData.gameId, targetIndex)
  }

  const handleClickQueueNext = (index: number) => {
    const targetIndex = getNextIndex(currentItemIndex)

    if (isStartedGame && (index === currentItemIndex || index === targetIndex)) return

    const reorderedDrawnItems = reorderItems(index, targetIndex)

    updateGameDrawnItems(gameData.gameId, reorderedDrawnItems)
  }

  const getAvailableTargetIndexes = () => {
    if (currentItemIndex === null || !isStartedGame) return drawnItems.map((_, i) => i)

    const output: number[] = []

    drawnItems.forEach((_, i) => {
      if (i <= currentItemIndex) return

      output.push(i)
    })

    return output
  }

  const availableTargetIndexes = getAvailableTargetIndexes()

  const handleChangeIndex = (index: number, targetIndex: number) => {
    const reorderedDrawnItems = reorderItems(index, targetIndex)

    updateGameDrawnItems(gameData.gameId, reorderedDrawnItems)
  }

  return (
    <ScrollContainer
      ref={ref => {
        if (!ref) return

        containerRef.current = ref.innerRef.current
      }}
    >
      <Container size='large'>
        <ListStyled
          className={merge(
            'list',
            'is-scrollable',
            {
              'has-bingo': hasCalledBingo
            },
            className
          )}
        >
          <ul>
            {list.map((item, index) => (
              <TriviaListRow
                {...item}
                availableTargetIndexes={availableTargetIndexes}
                currentItemIndex={isStartedGame ? currentItemIndex : null}
                index={item.drawnIndex}
                isStartedGame={isStartedGame}
                key={item.id}
                ref={refs[index]}
                onChangeIndex={handleChangeIndex}
                onClickDrawNow={handleClickDrawNow}
                onClickQueueNext={handleClickQueueNext}
              />
            ))}
          </ul>
        </ListStyled>
      </Container>
    </ScrollContainer>
  )
})

interface DrawnTriviaItemOrdered extends DrawnTriviaItem {
  drawnIndex: number
}

function addDrawnIndex(drawnItems: DrawnTriviaItem[]): DrawnTriviaItemOrdered[] {
  return drawnItems.map((item, index) => ({ ...item, drawnIndex: index }))
}

const getPreviousSongs = (
  drawnItems: DrawnTriviaItemOrdered[],
  currentItemIndex: number | null,
  limit: number
): DrawnTriviaItemOrdered[] => {
  if (currentItemIndex === null) return []

  const previousSongs = drawnItems.filter(
    (_, index) => currentItemIndex && index < currentItemIndex
  )

  return previousSongs.slice(Math.max(previousSongs.length - limit, 0))
}

export const ColumnLabelStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bg-02);
  font-weight: 800;
  font-size: 1.25em;
  aspect-ratio: 1;
  border-radius: 8px;

  span {
    position: relative;
    top: -0.05em;
  }
`

const NumberStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  top: -0.125em;
  position: relative;
`

const ListRowStyled = styled.li`
  .light & {
    --list-active-track: var(--orange-200);
    --list-active-track-text: var(--text);
  }

  .dark & {
    --list-active-track: var(--orange-800);
    --list-active-track-text: var(--text);
  }

  align-items: center;
  padding: 0.625em;
  grid-gap: 12px;
  opacity: 0.8;
  transition:
    opacity 0.3s ease-in-out,
    background-color 0.3s ease-in-out,
    padding 0.3s ease-in-out;
  border-radius: 12px;

  .title {
    line-height: 1.2;
  }

  .subtitle {
    color: var(--text-muted);
    text-transform: uppercase;
    font-size: 0.667em;
    letter-spacing: 0.00125em;
    line-height: 1.2;
  }

  @media (min-width: 768px) {
    font-size: 1.33em;
  }

  &.active {
    margin: 1em 0;
    opacity: 1;
    background: var(--list-active-track);
    border: 4px solid var(--orange-500);
    color: var(--list-active-track-text);

    .subtitle {
      color: var(--text);
      opacity: 0.75;
    }

    &:not(.compact) {
      @media (min-width: 768px) {
        font-size: 1.5em;
      }
    }

    ${ColumnLabelStyled} {
      background: var(--orange-500);
      color: var(--text);
    }

    ${NumberStyled} {
      opacity: 1;
    }
  }

  &.m-0 {
    margin: 0;
  }
`

const ListStyled = styled.div`
  padding-bottom: 24px;

  &.has-bingo {
    ${ListRowStyled} {
      &.active {
        background: var(--bg-04);
        border: 4px solid var(--bg-00);

        ${ColumnLabelStyled} {
          background: var(--bg-00);
          color: var(--text);
        }
      }
    }
  }

  &.dim-played-songs {
    ${ListRowStyled} {
      &.played {
        opacity: 0.33;
      }
    }
  }
`
