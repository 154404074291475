import { Spinner } from '@repo/ui'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'

import { EditTriviaList } from './edit-trivia-list'
import { useQueryUserTriviaList } from '../queries/useQueryUserTriviaList'

interface RouteParams {
  listId: string
}

export const EditTriviaListRoute = observer(() => {
  const { listId } = useParams<RouteParams>()
  const { data, isLoading, isSuccess } = useQueryUserTriviaList(listId)

  if (isLoading) return <Spinner />

  if (!isSuccess) return null

  return <EditTriviaList data={data} listId={listId} />
})
