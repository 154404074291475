import { TriviaList } from '@repo/types'
import { useFormikContext } from 'formik'
import debounce from 'lodash.debounce'
import isEqual from 'lodash.isequal'
import { useEffect, useMemo, useState } from 'react'

export function AutosaveForm({ children }: { children: React.ReactNode }) {
  const { dirty, isSubmitting, isValid, submitForm, values } = useFormikContext<TriviaList>()
  const [lastValues, setLastValues] = useState(values)

  const debouncedSave = useMemo(
    () =>
      debounce(values => {
        setLastValues(values)
        submitForm()
      }, 1_000),
    [submitForm]
  )

  useEffect(() => {
    if (isEqual(values, lastValues)) return

    if (isValid && !isSubmitting) {
      debouncedSave(values)
    }
  }, [debouncedSave, values, dirty, isValid, isSubmitting, lastValues])

  return <>{children}</>
}
