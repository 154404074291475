import { merge } from '@repo/ui'
import { ColumnDef, Getter, Table } from '@tanstack/react-table'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'

import { InputMaxLength } from '@/components/forms/TextField'

import { Item, SCROLL_INTO_VIEW_OPTIONS } from './TriviaListTable'

type InputCellProps = {
  columnDef?: ColumnDef<Item>
  getValue: Getter<string>
  id: string
  index: number
  table: Table<Item>
}

export function InputCell({ columnDef, getValue, id, index, table }: InputCellProps) {
  const cellRef = useRef<HTMLInputElement | null>(null)
  const { focusedCell, onBlurCell, onFocusCell } = table.options.meta!

  const item = table.getRowModel().rows.find(row => row.index === index)

  const isValid = Boolean(item?.original?.validation?.isValid)
  const isSimilar = Boolean(item?.original?.validation?.isSimilar)
  const hasLengthError = Boolean(item?.original?.validation?.hasLengthError)
  const isRowFocused = focusedCell?.rowIndex === index

  useLayoutEffect(() => {
    if (focusedCell?.rowIndex === index && focusedCell.columnId === id) {
      cellRef.current?.focus()
      cellRef.current?.scrollIntoView(SCROLL_INTO_VIEW_OPTIONS)
    }
  }, [id, index, focusedCell])

  const initialValue = getValue<string>()
  const [value, setValue] = useState(initialValue)

  const onBlur = () => {
    table.options.meta?.updateData(index, id, value)
    onBlurCell?.({ columnId: id, rowIndex: index })
  }

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Tab') {
      e.preventDefault()
      if (e.shiftKey) {
        table.options.meta?.focusPreviousCell?.({ columnId: id, rowIndex: index })
      } else {
        table.options.meta?.focusNextCell?.({ columnId: id, rowIndex: index })
      }
    } else if (e.key === 'Enter') {
      const isLastRow = index === table.getRowModel().rows.length - 1
      const currentRow = table.getRowModel().rows[index]

      // Check if both question and answer are filled out
      const question = currentRow.getValue('question') as string
      const answer = currentRow.getValue('answer') as string
      const areBothFieldsFilled = question?.trim() && answer?.trim()

      if (isLastRow && areBothFieldsFilled) {
        table.options.meta?.addNewItem?.()
      } else {
        table.options.meta?.focusNextCell?.({ columnId: id, rowIndex: index })
      }
    }

    // if right arrow key is pressed, focus next cell, if left arrow key is pressed, focus previous cell
    if (e.key === 'ArrowRight') {
      e.preventDefault()
      table.options.meta?.focusNextCell?.({ columnId: id, rowIndex: index })
    } else if (e.key === 'ArrowLeft') {
      e.preventDefault()
      table.options.meta?.focusPreviousCell?.({ columnId: id, rowIndex: index })
    }

    // if up arrow key is pressed, focus previous row, if down arrow key is pressed, focus next row. preserve the current column
    if (e.key === 'ArrowUp') {
      e.preventDefault()
      table.options.meta?.focusCell?.({ columnId: id, rowIndex: index - 1 })
    } else if (e.key === 'ArrowDown') {
      e.preventDefault()
      table.options.meta?.focusCell?.({ columnId: id, rowIndex: index + 1 })
    }
  }

  return (
    <div className='relative w-full'>
      <input
        className={merge(
          'flex-1 ring-inset transition-colors duration-300 group-hover/row:border-shade-4 group-hover/row:bg-shade-4',
          {
            'bg-red-200 group-hover/row:border-red-300 group-hover/row:bg-red-300 dark:bg-red-700 dark:border-red-700 dark:group-hover/row:border-red-600 dark:group-hover/row:bg-red-600':
              !isValid && !isRowFocused
          },
          {
            'bg-yellow-200 group-hover/row:border-yellow-300 group-hover/row:bg-yellow-300 dark:bg-yellow-900 dark:border-yellow-900 dark:group-hover/row:border-yellow-800 dark:group-hover/row:bg-yellow-800':
              isValid && isSimilar && !isRowFocused
          }
        )}
        placeholder={columnDef?.meta?.placeholder}
        ref={cellRef}
        value={value}
        onBlur={onBlur}
        onChange={e => setValue(e.target.value)}
        onFocus={() => onFocusCell?.({ columnId: id, rowIndex: index })}
        onKeyDown={handleKeyDown}
      />

      {(isRowFocused || hasLengthError) && (
        <InputMaxLength
          className='absolute right-2 top-2 rounded-md bg-shade-4 px-2 pb-2 pt-1'
          maxLength={columnDef?.meta?.maxLength}
          value={value}
        />
      )}
    </div>
  )
}
