import { Container } from '@repo/ui'
import 'firebase/auth'
import { observer } from 'mobx-react-lite'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'

import { AppHeader } from '@/components/AppHeader'
import { BannerGameEnded } from '@/components/BannerGameEnded'
import { GameHeader } from '@/components/GameHeader'
import { SettingsButton } from '@/components/icon-buttons/IconButtons'
import { LobbyIcon } from '@/components/LobbyIcon'
import { Main } from '@/components/Main'
import { Page } from '@/components/Page'
import { TriviaList } from '@/components/TriviaList'
import { useGameContext } from '@/pages/game/context/game-context'

import { MessageQueue } from '../message-queue/MessageQueue'

export const SidekickTrivia = observer(() => {
  const { gameData, isCompletedGame } = useGameContext()

  const { pathname } = useLocation()
  const getPathname = () => pathname.replace(/\/\s*$/, '')

  if (gameData.type !== 'trivia') return null

  return (
    <Page title='Sidekick'>
      <Helmet>
        <body className='overflow-hidden' />

        <style type='text/css'>
          {`
           .overflow-hidden #root {
              overflow: hidden;
            }

            body {
              scroll-behavior: smooth;
            }
          `}
        </style>
      </Helmet>

      <MessageQueue />

      <AppHeader
        actionsRight={
          <>
            <LobbyIcon url={`${getPathname()}/lobby`} />
            <SettingsButton url={`${getPathname()}/settings`} />
          </>
        }
        header={<GameHeader />}
        secondaryHeader={
          <>
            {isCompletedGame && (
              <Container className='px-0' size='large'>
                <BannerGameEnded isSidekick />
              </Container>
            )}
          </>
        }
      />

      <Main className='flex flex-col overflow-hidden py-0 lg:pt-0'>
        <TriviaList scrollContainer='element' />
      </Main>
    </Page>
  )
})
