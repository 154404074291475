import { observer } from 'mobx-react-lite'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Route, useRouteMatch } from 'react-router-dom'

import { AnonymousGameRoute } from '@/pages/game/context/anonymous-game-route'
import { SidekickSettingsRoute } from '@/pages/settings/pages/sidekick-settings'

import { SidekickControls } from './sidekick-controls'
import { SidekickLobbyRoute } from './sidekick-lobby'
import { SidekickMusic } from './sidekick-music'

export const SidekickRoute = observer(() => {
  const { path } = useRouteMatch()

  return (
    <AnonymousGameRoute>
      <Helmet>
        <title>Sidekick | Rockstar Bingo</title>
      </Helmet>

      <Route path={`${path}/lobby/:setup?`}>
        <SidekickLobbyRoute />
      </Route>

      <Route path={`${path}/settings`}>
        <SidekickSettingsRoute />
      </Route>

      <Route path={path} exact>
        <SidekickControls />
      </Route>
    </AnonymousGameRoute>
  )
})
