import { useQuery } from 'react-query'

import StoreService from '@/store/StoreService'

export function useQueryUserTriviaLists() {
  const { triviaListService } = StoreService.getStore()

  const { queries } = triviaListService

  const { queryFn, queryKey } = queries.userTriviaLists

  return useQuery(queryKey, queryFn, {
    // 24 hours
    cacheTime: 1000 * 60 * 60 * 24 * 7,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24 // 7 days
  })
}
