import { Playlist } from '@repo/types'

import { createFilename, downloadFile } from '@/utils/export'

export function exportPlaylist(playlist: Playlist) {
  const filename = createFilename(playlist.title)

  const updatedPlaylist = {
    ...playlist,
    songs: playlist.songs.map((song, index) => ({
      ...song,
      id: index
    }))
  }

  const json = JSON.stringify(updatedPlaylist)
  downloadFile(json, filename)
}
