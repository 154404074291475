import { Playlist } from '@repo/types'
import { useMutation, useQueryClient } from 'react-query'

export function useCreatePlaylist(
  queryKey: string[],
  mutationFn: (playlist: Playlist) => Promise<{ playlistId: string }>,
  queryFn: () => Promise<Playlist[]>
) {
  const queryClient = useQueryClient()

  const mutation = useMutation(mutationFn, {
    onError: (_error, _newPlaylist, context) => {
      if (!context?.previousData) return

      // Rollback on error
      queryClient.setQueryData(queryKey, context.previousData)
    },
    onMutate: async (playlist: Playlist) => {
      await queryClient.cancelQueries(queryKey)

      const previousData = queryClient.getQueryData<Playlist[]>(queryKey)
      if (!previousData) return

      const updatedPlaylists = [...previousData, playlist]

      // Optimistically add the playlist to the cache
      queryClient.setQueryData(queryKey, updatedPlaylists)

      return { previousData }
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKey)
      queryClient.fetchQuery(queryKey, queryFn)
    }
  })

  return mutation
}
