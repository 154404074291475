import { TriviaList } from '@repo/types'
import {
  Button,
  ButtonGroup,
  CheckCircleIcon,
  CloseIcon,
  Container,
  Description,
  EditIcon,
  ExportIcon,
  Heading,
  Label,
  Panel,
  QuestionCircleIcon,
  TriangleExclamationIcon
} from '@repo/ui'
import { Dialog, InfoTooltip } from '@repo/ui/client'
import { Form, Formik } from 'formik'
import debounce from 'lodash.debounce'
import isEqual from 'lodash.isequal'
import { action, runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { apiClient } from '@/api/api-client'
import { AppFooter } from '@/components/AppFooter'
import { AppHeader } from '@/components/AppHeader'
import { ExternalLink } from '@/components/ExternalLink'
import { TextField } from '@/components/forms/TextField'
import { BackButton } from '@/components/icon-buttons/IconButtons'
import { IconButton } from '@/components/IconButton'
import { Main } from '@/components/Main'
import { Page } from '@/components/Page'
import { PageHeading } from '@/components/PageHeading'
import { TipBadge } from '@/components/TipBadge'
import { MixpanelService } from '@/mixpanel'
import StoreService from '@/store/StoreService'

import { AutosaveForm } from '../AutosaveForm'
import { exportTriviaList } from '../export-trivia-list/export-trivia-list'
import { DownloadTriviaCSVTemplate, ImportTriviaSpreadsheet } from '../import-trivia-spreadsheet'
import { LastSaved } from '../LastSaved'
import { ListValidationIcon } from '../ListValidationIcon'
import { useUpdateUserTriviaList } from '../queries/useUpdateUserTriviaList'
import { SpreadsheetInput } from '../schema'
import { Cell, FilterValue, TriviaListTable } from './table/TriviaListTable'
import { ProcessedRows, processImportedRows } from './utils/deduplication'
import { validateTriviaList } from './utils/validate-list'
import { DownloadTriviaJsonTemplate, ImportTriviaListJson } from '../json/import-trivia-list-json'

const PAGE_TITLE = 'Edit Trivia List'
const UPDATE_DEBOUNCE = 750
export const TRIVIA_KNOWLEDGE_BASE_URL = 'https://go.rockstar.bingo/trivia'

type Props = {
  data: TriviaList
  listId: string
}

export const EditTriviaList = observer(({ data, listId }: Props) => {
  const [isStartingGame, setIsStartingGame] = useState(false)

  const [showStartImportDialog, setShowStartImportDialog] = useState(false)
  const [showStartSpreadsheetImportDialog, setShowStartSpreadsheetImportDialog] = useState(false)
  const [showStartJsonImportDialog, setShowStartJsonImportDialog] = useState(false)
  const [showImportResultDialog, setShowImportResultDialog] = useState(false)
  const [importResult, setImportResult] = useState<ProcessedRows>()

  const history = useHistory()
  const store = StoreService.getStore()

  const handleClickStartGame = action(async () => {
    setIsStartingGame(true)

    runInAction(() => {
      store.isLoadingGame = true
    })

    await apiClient.createGame({ triviaList: data, type: 'trivia' })

    history.push('/game/setup/gameplay')

    setIsStartingGame(false)
  })

  const validatedItems = useMemo(() => validateTriviaList(data), [data])

  const { isLoading: isSaving, mutate: updateUserTriviaList } = useUpdateUserTriviaList({
    listId
  })

  const debouncedUpdate = useMemo(
    () =>
      debounce(
        updatedData => updateUserTriviaList({ ...data, items: updatedData }),
        UPDATE_DEBOUNCE
      ),
    [data, updateUserTriviaList]
  )

  const [isSubmitting, setIsSubmitting] = useState(false)

  const [isEditingTitle, setIsEditingTitle] = useState(false)

  const [focusedCell, setFocusedCell] = useState<Cell | undefined>(undefined)
  const [filter, setFilter] = useState<FilterValue>('')

  const [selectedRows, setSelectedRows] = useState<string[]>([])

  const handleSubmit = async ({ title }: { title: string }) => {
    try {
      setIsSubmitting(true)

      updateUserTriviaList({ ...data, title })
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleImport = (importedData: SpreadsheetInput) => {
    setShowStartSpreadsheetImportDialog(false)

    const { deduplicatedRows, duplicates, similar } = processImportedRows(data.items, importedData)

    updateUserTriviaList({
      ...data,
      items: [
        ...data.items,
        ...deduplicatedRows.map(item => ({
          answer: item.answer,
          question: item.question
        }))
      ].map((item, index) => ({ ...item, id: index }))
    })

    setImportResult({ deduplicatedRows, duplicates, similar })
    setShowImportResultDialog(true)

    MixpanelService.importTriviaSpreadsheet({
      duplicateCount: duplicates.length,
      questionCount: deduplicatedRows.length,
      similarCount: similar.length
    })
  }

  const handleClickAdd = () => {
    const updatedList = [
      ...data.items,
      {
        answer: '',
        id: data.items.length,
        question: ''
      }
    ]

    setFocusedCell({
      columnId: 'question',
      rowIndex: updatedList.length - 1
    })

    setFilter('')

    updateUserTriviaList({ ...data, items: updatedList })

    MixpanelService.addTriviaItem()
  }

  const handleExportClick = () => {
    exportTriviaList(data)
  }

  return (
    <Page title={PAGE_TITLE}>
      <AppHeader
        actionsLeft={<BackButton url='/trivia' />}
        actionsRight={
          <>
            <IconButton
              icon={<ExportIcon />}
              title='Export Trivia List'
              onClick={handleExportClick}
            />

            <IconButton
              icon={<QuestionCircleIcon />}
              title='Need a hand? Visit our knowledge base'
              url={TRIVIA_KNOWLEDGE_BASE_URL}
              isExternal
            />
          </>
        }
        containerSize='largest'
        header={<PageHeading>{PAGE_TITLE}</PageHeading>}
      />

      <Main className='px-8 lg:pt-4'>
        <Container className='mb-4' size='largest'>
          <LastSaved isSaving={isSubmitting || isSaving} timestamp={data.timestamp?.toDate()} />

          <div className='z-50 flex w-full items-center justify-between gap-8 bg-shade-0 py-4'>
            {!isEditingTitle && (
              <div
                className='group flex w-full flex-1 items-center gap-4 py-6'
                role='button'
                onClick={() => setIsEditingTitle(true)}
              >
                <Heading size='large'>{data.title}</Heading>

                <div className='flex items-center gap-2 text-purple-500 opacity-0 transition-opacity duration-300 group-hover:opacity-100 dark:text-purple-400'>
                  <EditIcon className='size-4' />
                  <Label className='text-purple-500 hover:cursor-pointer dark:text-purple-400'>
                    Edit Title
                  </Label>
                </div>
              </div>
            )}

            {isEditingTitle && (
              <Formik initialValues={{ title: data.title }} onSubmit={handleSubmit}>
                {({ values }) => {
                  return (
                    <AutosaveForm>
                      {/* @ts-expect-error Formik types */}
                      <Form className='relative flex max-h-screen flex-col'>
                        <Container className='mx-0 p-0' size='extra-small'>
                          <TextField
                            label='Title'
                            name='title'
                            value={values.title}
                            autoFocus
                            onBlur={() => setIsEditingTitle(false)}
                            onKeyPress={event => {
                              if (event.key === 'Enter') {
                                setIsEditingTitle(false)
                              }
                            }}
                          />
                        </Container>
                      </Form>
                    </AutosaveForm>
                  )
                }}
              </Formik>
            )}

            <div className='flex w-1/2 justify-end gap-3'>
              <Button
                className='max-w-48'
                text='Import'
                variant='outlined'
                onClick={() => setShowStartImportDialog(true)}
              />

              <Button className='max-w-48' text='Add Item' onClick={handleClickAdd} />
            </div>
          </div>

          <div className='mb-4 flex items-center justify-between gap-4 p-1'>
            <Heading>
              <InfoTooltip
                trigger={
                  <button
                    type='button'
                    onClick={() => {
                      setFocusedCell(undefined)
                    }}
                  >
                    List
                  </button>
                }
              >
                The source items that will be used to generate randomized bingo cards
              </InfoTooltip>
            </Heading>

            <div className='flex items-center justify-center gap-4'>
              <ListValidationIcon validatedValues={validatedItems} />
            </div>
          </div>
        </Container>

        <Container size='largest'>
          <TriviaListTable
            filter={filter}
            focusedCell={focusedCell}
            initialValues={validatedItems.items}
            selectedRows={selectedRows}
            setFilter={setFilter}
            validation={validatedItems.validation}
            onAddItem={handleClickAdd}
            onBlurCell={(cell: Cell) => {
              if (
                cell.columnId === focusedCell?.columnId &&
                cell.rowIndex === focusedCell?.rowIndex
              ) {
                setFocusedCell(undefined)
              }
            }}
            onFocusCell={cell => {
              debouncedUpdate.cancel()
              setFocusedCell(cell)
            }}
            onSelectRows={setSelectedRows}
            onUpdate={tableData => {
              const previousItems = validatedItems.items

              if (isEqual(previousItems, tableData)) return

              debouncedUpdate(tableData)
            }}
          />
        </Container>

        {!data.items.length && (
          <Container size='largest'>
            <Panel className='mt-8 flex flex-col gap-4 text-center'>
              <div>You haven't added any items yet</div>
              <div className='text-center'>
                <ExternalLink href={TRIVIA_KNOWLEDGE_BASE_URL}>
                  Need a hand? Visit our knowledge base
                </ExternalLink>
              </div>
            </Panel>
          </Container>
        )}
      </Main>

      <AppFooter className='z-50 border-t border-shade-3 bg-shade-1'>
        <Container size='medium'>
          <Button
            disabled={!validatedItems.validation.isValid}
            isLoading={isStartingGame}
            loadingText='Creating Game...'
            text='Start Game'
            onClick={handleClickStartGame}
          />
        </Container>
      </AppFooter>

      <Dialog isVisible={showStartImportDialog}>
        <Dialog.Body className='flex flex-col gap-8'>
          <div className='flex flex-col gap-4'>
            <Heading size='medium'>Choose Import Source</Heading>
            <Description>
              Select the file format that contains your trivia questions and answers.
            </Description>
          </div>

          <ButtonGroup className='mb-4'>
            <Button
              text='Spreadsheet'
              onClick={() => {
                setShowStartSpreadsheetImportDialog(true)
                setShowStartImportDialog(false)
              }}
            />

            <Button
              text='JSON'
              onClick={() => {
                setShowStartJsonImportDialog(true)
                setShowStartImportDialog(false)
              }}
            />
          </ButtonGroup>
        </Dialog.Body>
        <Dialog.Footer>
          <ButtonGroup>
            <Button
              text='Cancel'
              variant='outlined'
              onClick={() => {
                setShowStartImportDialog(false)
              }}
            />
          </ButtonGroup>
        </Dialog.Footer>
      </Dialog>

      <Dialog isVisible={showStartSpreadsheetImportDialog}>
        <Dialog.Body className='flex flex-col gap-8'>
          <div className='flex flex-col gap-4'>
            <Heading size='medium'>Start Import</Heading>

            <div className='flex flex-col gap-3'>
              <Description>
                Upload a spreadsheet file in{' '}
                <ExternalLink href='https://en.wikipedia.org/wiki/Comma-separated_values'>
                  CSV format
                </ExternalLink>
              </Description>
            </div>

            <Description>
              Requires columns named <strong>"Question"</strong> and <strong>"Answer"</strong>.
            </Description>

            <DownloadTriviaCSVTemplate />
          </div>

          <div className='flex flex-col gap-3 rounded-lg bg-shade-1 p-6'>
            <TipBadge />

            <Description>
              Consider prompting an AI to generate questions for you. You can upload in batches.
            </Description>
          </div>
        </Dialog.Body>
        <Dialog.Footer>
          <ButtonGroup>
            <Button
              text='Cancel'
              variant='outlined'
              onClick={() => {
                setShowStartSpreadsheetImportDialog(false)
              }}
            />
            <ImportTriviaSpreadsheet onImport={handleImport} />
          </ButtonGroup>
        </Dialog.Footer>
      </Dialog>

      <Dialog isVisible={showStartJsonImportDialog}>
        <Dialog.Body className='flex flex-col gap-8'>
          <div className='flex flex-col gap-4'>
            <Heading size='medium'>Start Import</Heading>

            <div className='flex flex-col gap-3'>
              <Description>
                Upload a file in{' '}
                <ExternalLink href='https://en.wikipedia.org/wiki/JSON'>JSON format</ExternalLink>
              </Description>
            </div>

            <Description>
              Requires properties named <strong>"question"</strong> and <strong>"answer"</strong>.
            </Description>

            <DownloadTriviaJsonTemplate />
          </div>

          <div className='flex flex-col gap-3 rounded-lg bg-shade-1 p-6'>
            <TipBadge />

            <Description>
              Consider prompting an AI to generate questions for you. You can upload in batches.
            </Description>
          </div>
        </Dialog.Body>
        <Dialog.Footer>
          <ButtonGroup>
            <Button
              text='Cancel'
              variant='outlined'
              onClick={() => {
                setShowStartSpreadsheetImportDialog(false)
              }}
            />{' '}
            <ImportTriviaListJson
              data={data}
              setImportResult={setImportResult}
              setShowImportResultDialog={setShowImportResultDialog}
              updateUserTriviaList={updateUserTriviaList}
              onImport={() => {
                setShowStartImportDialog(false)
                setShowStartJsonImportDialog(false)
              }}
            />
          </ButtonGroup>
        </Dialog.Footer>
      </Dialog>

      <Dialog isVisible={showImportResultDialog}>
        <Dialog.Body className='flex flex-col gap-8 pb-12'>
          {Boolean(importResult?.deduplicatedRows.length) && (
            <div className='flex flex-col gap-3'>
              <Heading size='medium'>Imported Items</Heading>
              <div className='flex items-center gap-2'>
                <CheckCircleIcon className='size-6 text-green-500' />
                <Description>
                  {importResult?.deduplicatedRows.length} items were imported successfully
                </Description>
              </div>
            </div>
          )}

          {Boolean(importResult?.duplicates.length) && (
            <div className='flex flex-col gap-3'>
              <Heading size='medium'>Duplicate Items</Heading>
              <div className='flex items-center gap-2'>
                <CloseIcon className='size-6 text-red-500' />
                <Description>
                  {importResult?.duplicates.length} duplicate items were skipped
                </Description>
              </div>
            </div>
          )}

          {Boolean(importResult?.similar.length) && (
            <div className='flex flex-col gap-3'>
              <Heading size='medium'>Similar Items</Heading>
              <div className='flex items-center gap-2'>
                <TriangleExclamationIcon className='size-6 text-yellow-500' />
                <Description>
                  {importResult?.similar.length} similar items were detected. Consider reviewing
                  them.
                </Description>
              </div>
            </div>
          )}
        </Dialog.Body>
        <Dialog.Footer>
          <Button
            text='Continue'
            onClick={() => {
              setShowImportResultDialog(false)
            }}
          />
        </Dialog.Footer>
      </Dialog>
    </Page>
  )
})
