import { getErrorMessage } from '@repo/lib'
import { TriviaList } from '@repo/types'
import { Button, Container } from '@repo/ui'
import { useSuccessButton } from '@repo/ui/client'
import { Form, Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { AppHeader } from '@/components/AppHeader'
import { AppHeaderLogo } from '@/components/AppHeaderLogo'
import { FormError } from '@/components/forms/FormError'
import { TextField } from '@/components/forms/TextField'
import { BackButton } from '@/components/icon-buttons/IconButtons'
import { Main } from '@/components/Main'
import { Page } from '@/components/Page'
import { PageTitle } from '@/components/PageTitle'
import { TermsOfService } from '@/components/TermsOfService'

import { useCreateUserTriviaList } from '../queries/useCreateUserTriviaList'

interface Props {
  baseUrl?: string
}

export const CreateTriviaListView = observer(function CreateTriviaListView({
  baseUrl = '/'
}: Props): React.ReactElement {
  const [formError, setFormError] = useState<string | undefined>()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { showSuccess, showSuccessButtonState } = useSuccessButton()
  const history = useHistory()

  const { mutateAsync: createTriviaList } = useCreateUserTriviaList()

  const handleSubmit = async (values: TriviaList) => {
    try {
      setIsSubmitting(true)

      const { listId } = await createTriviaList(values)

      history.push(`${baseUrl}trivia-lists/edit/${listId}`)

      await showSuccessButtonState()
    } catch (error) {
      setFormError(getErrorMessage(error))
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Page title='Create a Custom Trivia List'>
      <AppHeader actionsLeft={<BackButton url={baseUrl} />} header={<AppHeaderLogo />} />

      <Main>
        <Container size='medium'>
          <PageTitle className='mb-6 text-center' size='large' disableTruncation>
            Create a Custom Trivia List
          </PageTitle>

          <Formik
            initialValues={{ id: '', items: [], title: 'New Trivia List' }}
            onSubmit={handleSubmit}
          >
            {/* @ts-expect-error Formik types */}
            <Form className='flex flex-col gap-8'>
              <div className='flex flex-col gap-6'>
                <TextField label='Title' name='title' autoFocus />
              </div>

              <Button
                disabled={isSubmitting}
                isLoading={isSubmitting}
                showSuccess={showSuccess}
                successText='Created'
                text='Create'
                type='submit'
              />

              {formError && <FormError error={formError} />}
            </Form>
          </Formik>
        </Container>

        <TermsOfService />
      </Main>
    </Page>
  )
})
