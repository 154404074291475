"use client";
import { jsxs as ae, jsx as A, Fragment as jt } from "react/jsx-runtime";
import { B as Vn, c as st, C as zt, m as ie, H as _n, I as Hn, a as jn, A as zn, b as Kn, s as qn } from "./globals-BnV4aVvu.js";
import Kt, { Balancer as Un } from "react-wrap-balancer";
import { AnimatePresence as Yn, motion as qt } from "framer-motion";
import * as d from "react";
import ut, { useState as re, useEffect as be, useLayoutEffect as Se, useRef as gt, createContext as Gn, useContext as Xn, useMemo as Ut, forwardRef as ht, useCallback as Yt, useImperativeHandle as Qn } from "react";
import * as Zn from "react-dom";
import { createPortal as Gt } from "react-dom";
function bi({
  children: e,
  isVisible: t = !1,
  onCancel: n,
  onClose: r,
  onConfirm: o
}) {
  const i = () => {
    o(), r();
  }, s = () => {
    n == null || n(), r();
  };
  return /* @__PURE__ */ ae(K, { isVisible: t, size: "small", children: [
    /* @__PURE__ */ A(K.Body, { className: "items-center text-center", children: /* @__PURE__ */ A(Un, { className: "mx-auto", children: e }) }),
    /* @__PURE__ */ A(K.Footer, { className: "pt-6", children: /* @__PURE__ */ ae(Vn, { direction: "column", children: [
      /* @__PURE__ */ A(st, { text: "Yes", onClick: i }),
      /* @__PURE__ */ A(st, { text: "No", variant: "outlined", onClick: s })
    ] }) })
  ] });
}
function K({ children: e, className: t, isVisible: n = !1, size: r = "medium" }) {
  const [o, i] = re(!1);
  return be(() => {
    i(!0);
  }, []), be(() => {
    const { documentElement: s } = document;
    return n ? s.classList.add("overflow-hidden") : s.classList.remove("overflow-hidden"), () => {
      s.classList.remove("overflow-hidden");
    };
  }, [n]), o ? Gt(
    /* @__PURE__ */ A(Yn, { children: n && /* @__PURE__ */ A(K.Backdrop, { children: /* @__PURE__ */ A(zt, { className: "max-sm:mt-auto max-sm:px-0", size: r, children: /* @__PURE__ */ A(K.Content, { className: t, children: e }) }) }) }),
    document.body
  ) : null;
}
K.Backdrop = function({ children: t }) {
  return /* @__PURE__ */ A(
    qt.div,
    {
      animate: { opacity: 1 },
      className: ie(
        "fixed inset-0 z-50 h-[100dvh] w-full",
        "bg-modal-backdrop",
        "overflow-auto",
        "backdrop-blur",
        "flex flex-col",
        "max-sm:pb-[env(safe-area-inset-bottom)]",
        "max-sm:justify-end"
      ),
      exit: { opacity: 0 },
      initial: { opacity: 0 },
      children: t
    }
  );
};
K.Content = function({ children: t, className: n }) {
  return /* @__PURE__ */ A(
    qt.div,
    {
      animate: { opacity: 1, y: 0 },
      className: ie(
        "flex flex-col",
        "border-2 border-shade-2 bg-shade-0 dark:border-shade-1",
        "rounded-xl shadow-xl",
        "p-4 md:p-6",
        "mt-8",
        "lg:mt-24",
        "text-default",
        "max-sm:rounded-b-none",
        "max-h-[100dvh]",
        n
      ),
      exit: { opacity: 0, y: "-48px" },
      initial: { opacity: 0, y: "48px" },
      transition: { bounce: 0, duration: 0.5, type: "spring" },
      children: t
    }
  );
};
K.Header = function({ children: t }) {
  return /* @__PURE__ */ A("header", { className: "sticky top-0 z-50 flex items-center justify-between gap-4 bg-shade-0", children: t });
};
K.Title = function({ children: t }) {
  return /* @__PURE__ */ A("div", { className: "w-full overflow-hidden", children: /* @__PURE__ */ A(_n, { size: "small", children: t }) });
};
K.Body = function({ children: t, className: n }) {
  return /* @__PURE__ */ A("div", { className: ie("flex flex-1 flex-col overflow-hidden py-6", n), children: t });
};
K.Separator = function({ className: t }) {
  return /* @__PURE__ */ A("hr", { className: ie("border-shade-3", "my-4 md:my-6", t) });
};
K.Footer = function({ children: t, className: n }) {
  return /* @__PURE__ */ A("footer", { className: ie("sticky bottom-0 bg-shade-0", n), children: t });
};
K.CloseButton = function({ onClick: t }) {
  return /* @__PURE__ */ A(Hn, { className: "ml-auto", size: "small", title: "Close", onClick: t, children: /* @__PURE__ */ A(jn, {}) });
};
function vi({
  children: e,
  isVisible: t = !1,
  onClose: n,
  onContinue: r
}) {
  const o = () => {
    r == null || r(), n();
  };
  return /* @__PURE__ */ ae(K, { isVisible: t, size: "small", children: [
    /* @__PURE__ */ A(K.Body, { className: "items-center text-center", children: /* @__PURE__ */ A(Kt, { className: "mx-auto", children: e }) }),
    /* @__PURE__ */ A(K.Footer, { className: "pt-6", children: /* @__PURE__ */ A(st, { text: "Continue", onClick: o }) })
  ] });
}
function Xt(e) {
  return Qt(e) ? (e.nodeName || "").toLowerCase() : "#document";
}
function Oe(e) {
  var t;
  return (e == null || (t = e.ownerDocument) == null ? void 0 : t.defaultView) || window;
}
function Jn(e) {
  var t;
  return (t = (Qt(e) ? e.ownerDocument : e.document) || window.document) == null ? void 0 : t.documentElement;
}
function Qt(e) {
  return e instanceof Node || e instanceof Oe(e).Node;
}
function q(e) {
  return e instanceof Element || e instanceof Oe(e).Element;
}
function ve(e) {
  return e instanceof HTMLElement || e instanceof Oe(e).HTMLElement;
}
function ct(e) {
  return typeof ShadowRoot > "u" ? !1 : e instanceof ShadowRoot || e instanceof Oe(e).ShadowRoot;
}
function er(e) {
  return ["html", "body", "#document"].includes(Xt(e));
}
function tr(e) {
  return Oe(e).getComputedStyle(e);
}
function nr(e) {
  if (Xt(e) === "html")
    return e;
  const t = (
    // Step into the shadow DOM of the parent of a slotted node.
    e.assignedSlot || // DOM Element detected.
    e.parentNode || // ShadowRoot detected.
    ct(e) && e.host || // Fallback.
    Jn(e)
  );
  return ct(t) ? t.host : t;
}
function le(e) {
  let t = e.activeElement;
  for (; ((n = t) == null || (r = n.shadowRoot) == null ? void 0 : r.activeElement) != null; ) {
    var n, r;
    t = t.shadowRoot.activeElement;
  }
  return t;
}
function j(e, t) {
  if (!e || !t)
    return !1;
  const n = t.getRootNode && t.getRootNode();
  if (e.contains(t))
    return !0;
  if (n && ct(n)) {
    let r = t;
    for (; r; ) {
      if (e === r)
        return !0;
      r = r.parentNode || r.host;
    }
  }
  return !1;
}
function rr() {
  const e = navigator.userAgentData;
  return e != null && e.platform ? e.platform : navigator.platform;
}
function or() {
  const e = navigator.userAgentData;
  return e && Array.isArray(e.brands) ? e.brands.map((t) => {
    let {
      brand: n,
      version: r
    } = t;
    return n + "/" + r;
  }).join(" ") : navigator.userAgent;
}
function ir(e) {
  if (e.mozInputSource === 0 && e.isTrusted)
    return !0;
  const t = /Android/i;
  return (t.test(rr()) || t.test(or())) && e.pointerType ? e.type === "click" && e.buttons === 1 : e.detail === 0 && !e.pointerType;
}
function sr(e) {
  return e.width === 0 && e.height === 0 || e.width === 1 && e.height === 1 && e.pressure === 0 && e.detail === 0 && e.pointerType !== "mouse" || // iOS VoiceOver returns 0.333• for width/height.
  e.width < 1 && e.height < 1 && e.pressure === 0 && e.detail === 0;
}
function ur() {
  return /apple/i.test(navigator.vendor);
}
function Ne(e, t) {
  const n = ["mouse", "pen"];
  return t || n.push("", void 0), n.includes(e);
}
function cr(e) {
  return "nativeEvent" in e;
}
function lr(e) {
  return e.matches("html,body");
}
function z(e) {
  return (e == null ? void 0 : e.ownerDocument) || document;
}
function Ie(e, t) {
  if (t == null)
    return !1;
  if ("composedPath" in e)
    return e.composedPath().includes(t);
  const n = e;
  return n.target != null && t.contains(n.target);
}
function Zt(e) {
  return "composedPath" in e ? e.composedPath()[0] : e.target;
}
const ar = "input:not([type='hidden']):not([disabled]),[contenteditable]:not([contenteditable='false']),textarea:not([disabled])";
function Jt(e) {
  return ve(e) && e.matches(ar);
}
function Ze(e) {
  e.preventDefault(), e.stopPropagation();
}
const fr = Math.min, dr = Math.max, mr = {
  left: "right",
  right: "left",
  bottom: "top",
  top: "bottom"
}, pr = {
  start: "end",
  end: "start"
};
function St(e, t, n) {
  return dr(e, fr(t, n));
}
function Ke(e, t) {
  return typeof e == "function" ? e(t) : e;
}
function fe(e) {
  return e.split("-")[0];
}
function qe(e) {
  return e.split("-")[1];
}
function en(e) {
  return e === "x" ? "y" : "x";
}
function tn(e) {
  return e === "y" ? "height" : "width";
}
function ye(e) {
  return ["top", "bottom"].includes(fe(e)) ? "y" : "x";
}
function nn(e) {
  return en(ye(e));
}
function gr(e, t, n) {
  n === void 0 && (n = !1);
  const r = qe(e), o = nn(e), i = tn(o);
  let s = o === "x" ? r === (n ? "end" : "start") ? "right" : "left" : r === "start" ? "bottom" : "top";
  return t.reference[i] > t.floating[i] && (s = Be(s)), [s, Be(s)];
}
function hr(e) {
  const t = Be(e);
  return [lt(e), t, lt(t)];
}
function lt(e) {
  return e.replace(/start|end/g, (t) => pr[t]);
}
function br(e, t, n) {
  const r = ["left", "right"], o = ["right", "left"], i = ["top", "bottom"], s = ["bottom", "top"];
  switch (e) {
    case "top":
    case "bottom":
      return n ? t ? o : r : t ? r : o;
    case "left":
    case "right":
      return t ? i : s;
    default:
      return [];
  }
}
function vr(e, t, n, r) {
  const o = qe(e);
  let i = br(fe(e), n === "start", r);
  return o && (i = i.map((s) => s + "-" + o), t && (i = i.concat(i.map(lt)))), i;
}
function Be(e) {
  return e.replace(/left|right|bottom|top/g, (t) => mr[t]);
}
function yr(e) {
  return {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    ...e
  };
}
function wr(e) {
  return typeof e != "number" ? yr(e) : {
    top: e,
    right: e,
    bottom: e,
    left: e
  };
}
function $e(e) {
  const {
    x: t,
    y: n,
    width: r,
    height: o
  } = e;
  return {
    width: r,
    height: o,
    top: n,
    left: t,
    right: t + r,
    bottom: n + o,
    x: t,
    y: n
  };
}
function Ot(e, t, n) {
  let {
    reference: r,
    floating: o
  } = e;
  const i = ye(t), s = nn(t), u = tn(s), c = fe(t), a = i === "y", m = r.x + r.width / 2 - o.width / 2, f = r.y + r.height / 2 - o.height / 2, p = r[u] / 2 - o[u] / 2;
  let l;
  switch (c) {
    case "top":
      l = {
        x: m,
        y: r.y - o.height
      };
      break;
    case "bottom":
      l = {
        x: m,
        y: r.y + r.height
      };
      break;
    case "right":
      l = {
        x: r.x + r.width,
        y: f
      };
      break;
    case "left":
      l = {
        x: r.x - o.width,
        y: f
      };
      break;
    default:
      l = {
        x: r.x,
        y: r.y
      };
  }
  switch (qe(t)) {
    case "start":
      l[s] -= p * (n && a ? -1 : 1);
      break;
    case "end":
      l[s] += p * (n && a ? -1 : 1);
      break;
  }
  return l;
}
const xr = async (e, t, n) => {
  const {
    placement: r = "bottom",
    strategy: o = "absolute",
    middleware: i = [],
    platform: s
  } = n, u = i.filter(Boolean), c = await (s.isRTL == null ? void 0 : s.isRTL(t));
  let a = await s.getElementRects({
    reference: e,
    floating: t,
    strategy: o
  }), {
    x: m,
    y: f
  } = Ot(a, r, c), p = r, l = {}, g = 0;
  for (let h = 0; h < u.length; h++) {
    const {
      name: w,
      fn: v
    } = u[h], {
      x: b,
      y: P,
      data: T,
      reset: C
    } = await v({
      x: m,
      y: f,
      initialPlacement: r,
      placement: p,
      strategy: o,
      middlewareData: l,
      rects: a,
      platform: s,
      elements: {
        reference: e,
        floating: t
      }
    });
    m = b ?? m, f = P ?? f, l = {
      ...l,
      [w]: {
        ...l[w],
        ...T
      }
    }, C && g <= 50 && (g++, typeof C == "object" && (C.placement && (p = C.placement), C.rects && (a = C.rects === !0 ? await s.getElementRects({
      reference: e,
      floating: t,
      strategy: o
    }) : C.rects), {
      x: m,
      y: f
    } = Ot(a, p, c)), h = -1);
  }
  return {
    x: m,
    y: f,
    placement: p,
    strategy: o,
    middlewareData: l
  };
};
async function rn(e, t) {
  var n;
  t === void 0 && (t = {});
  const {
    x: r,
    y: o,
    platform: i,
    rects: s,
    elements: u,
    strategy: c
  } = e, {
    boundary: a = "clippingAncestors",
    rootBoundary: m = "viewport",
    elementContext: f = "floating",
    altBoundary: p = !1,
    padding: l = 0
  } = Ke(t, e), g = wr(l), w = u[p ? f === "floating" ? "reference" : "floating" : f], v = $e(await i.getClippingRect({
    element: (n = await (i.isElement == null ? void 0 : i.isElement(w))) == null || n ? w : w.contextElement || await (i.getDocumentElement == null ? void 0 : i.getDocumentElement(u.floating)),
    boundary: a,
    rootBoundary: m,
    strategy: c
  })), b = f === "floating" ? {
    x: r,
    y: o,
    width: s.floating.width,
    height: s.floating.height
  } : s.reference, P = await (i.getOffsetParent == null ? void 0 : i.getOffsetParent(u.floating)), T = await (i.isElement == null ? void 0 : i.isElement(P)) ? await (i.getScale == null ? void 0 : i.getScale(P)) || {
    x: 1,
    y: 1
  } : {
    x: 1,
    y: 1
  }, C = $e(i.convertOffsetParentRelativeRectToViewportRelativeRect ? await i.convertOffsetParentRelativeRectToViewportRelativeRect({
    elements: u,
    rect: b,
    offsetParent: P,
    strategy: c
  }) : b);
  return {
    top: (v.top - C.top + g.top) / T.y,
    bottom: (C.bottom - v.bottom + g.bottom) / T.y,
    left: (v.left - C.left + g.left) / T.x,
    right: (C.right - v.right + g.right) / T.x
  };
}
const Er = function(e) {
  return e === void 0 && (e = {}), {
    name: "flip",
    options: e,
    async fn(t) {
      var n, r;
      const {
        placement: o,
        middlewareData: i,
        rects: s,
        initialPlacement: u,
        platform: c,
        elements: a
      } = t, {
        mainAxis: m = !0,
        crossAxis: f = !0,
        fallbackPlacements: p,
        fallbackStrategy: l = "bestFit",
        fallbackAxisSideDirection: g = "none",
        flipAlignment: h = !0,
        ...w
      } = Ke(e, t);
      if ((n = i.arrow) != null && n.alignmentOffset)
        return {};
      const v = fe(o), b = ye(u), P = fe(u) === u, T = await (c.isRTL == null ? void 0 : c.isRTL(a.floating)), C = p || (P || !h ? [Be(u)] : hr(u)), S = g !== "none";
      !p && S && C.push(...vr(u, h, g, T));
      const y = [u, ...C], E = await rn(t, w), B = [];
      let H = ((r = i.flip) == null ? void 0 : r.overflows) || [];
      if (m && B.push(E[v]), f) {
        const O = gr(o, s, T);
        B.push(E[O[0]], E[O[1]]);
      }
      if (H = [...H, {
        placement: o,
        overflows: B
      }], !B.every((O) => O <= 0)) {
        var D, N;
        const O = (((D = i.flip) == null ? void 0 : D.index) || 0) + 1, k = y[O];
        if (k)
          return {
            data: {
              index: O,
              overflows: H
            },
            reset: {
              placement: k
            }
          };
        let M = (N = H.filter((W) => W.overflows[0] <= 0).sort((W, x) => W.overflows[1] - x.overflows[1])[0]) == null ? void 0 : N.placement;
        if (!M)
          switch (l) {
            case "bestFit": {
              var R;
              const W = (R = H.filter((x) => {
                if (S) {
                  const F = ye(x.placement);
                  return F === b || // Create a bias to the `y` side axis due to horizontal
                  // reading directions favoring greater width.
                  F === "y";
                }
                return !0;
              }).map((x) => [x.placement, x.overflows.filter((F) => F > 0).reduce((F, _) => F + _, 0)]).sort((x, F) => x[1] - F[1])[0]) == null ? void 0 : R[0];
              W && (M = W);
              break;
            }
            case "initialPlacement":
              M = u;
              break;
          }
        if (o !== M)
          return {
            reset: {
              placement: M
            }
          };
      }
      return {};
    }
  };
};
async function Rr(e, t) {
  const {
    placement: n,
    platform: r,
    elements: o
  } = e, i = await (r.isRTL == null ? void 0 : r.isRTL(o.floating)), s = fe(n), u = qe(n), c = ye(n) === "y", a = ["left", "top"].includes(s) ? -1 : 1, m = i && c ? -1 : 1, f = Ke(t, e);
  let {
    mainAxis: p,
    crossAxis: l,
    alignmentAxis: g
  } = typeof f == "number" ? {
    mainAxis: f,
    crossAxis: 0,
    alignmentAxis: null
  } : {
    mainAxis: f.mainAxis || 0,
    crossAxis: f.crossAxis || 0,
    alignmentAxis: f.alignmentAxis
  };
  return u && typeof g == "number" && (l = u === "end" ? g * -1 : g), c ? {
    x: l * m,
    y: p * a
  } : {
    x: p * a,
    y: l * m
  };
}
const Tr = function(e) {
  return e === void 0 && (e = 0), {
    name: "offset",
    options: e,
    async fn(t) {
      var n, r;
      const {
        x: o,
        y: i,
        placement: s,
        middlewareData: u
      } = t, c = await Rr(t, e);
      return s === ((n = u.offset) == null ? void 0 : n.placement) && (r = u.arrow) != null && r.alignmentOffset ? {} : {
        x: o + c.x,
        y: i + c.y,
        data: {
          ...c,
          placement: s
        }
      };
    }
  };
}, Cr = function(e) {
  return e === void 0 && (e = {}), {
    name: "shift",
    options: e,
    async fn(t) {
      const {
        x: n,
        y: r,
        placement: o
      } = t, {
        mainAxis: i = !0,
        crossAxis: s = !1,
        limiter: u = {
          fn: (w) => {
            let {
              x: v,
              y: b
            } = w;
            return {
              x: v,
              y: b
            };
          }
        },
        ...c
      } = Ke(e, t), a = {
        x: n,
        y: r
      }, m = await rn(t, c), f = ye(fe(o)), p = en(f);
      let l = a[p], g = a[f];
      if (i) {
        const w = p === "y" ? "top" : "left", v = p === "y" ? "bottom" : "right", b = l + m[w], P = l - m[v];
        l = St(b, l, P);
      }
      if (s) {
        const w = f === "y" ? "top" : "left", v = f === "y" ? "bottom" : "right", b = g + m[w], P = g - m[v];
        g = St(b, g, P);
      }
      const h = u.fn({
        ...t,
        [p]: l,
        [f]: g
      });
      return {
        ...h,
        data: {
          x: h.x - n,
          y: h.y - r,
          enabled: {
            [p]: i,
            [f]: s
          }
        }
      };
    }
  };
}, at = Math.min, ge = Math.max, We = Math.round, Pe = Math.floor, ee = (e) => ({
  x: e,
  y: e
});
function Ue() {
  return typeof window < "u";
}
function xe(e) {
  return on(e) ? (e.nodeName || "").toLowerCase() : "#document";
}
function Y(e) {
  var t;
  return (e == null || (t = e.ownerDocument) == null ? void 0 : t.defaultView) || window;
}
function ne(e) {
  var t;
  return (t = (on(e) ? e.ownerDocument : e.document) || window.document) == null ? void 0 : t.documentElement;
}
function on(e) {
  return Ue() ? e instanceof Node || e instanceof Y(e).Node : !1;
}
function Q(e) {
  return Ue() ? e instanceof Element || e instanceof Y(e).Element : !1;
}
function te(e) {
  return Ue() ? e instanceof HTMLElement || e instanceof Y(e).HTMLElement : !1;
}
function At(e) {
  return !Ue() || typeof ShadowRoot > "u" ? !1 : e instanceof ShadowRoot || e instanceof Y(e).ShadowRoot;
}
function Ae(e) {
  const {
    overflow: t,
    overflowX: n,
    overflowY: r,
    display: o
  } = Z(e);
  return /auto|scroll|overlay|hidden|clip/.test(t + r + n) && !["inline", "contents"].includes(o);
}
function Sr(e) {
  return ["table", "td", "th"].includes(xe(e));
}
function Ye(e) {
  return [":popover-open", ":modal"].some((t) => {
    try {
      return e.matches(t);
    } catch {
      return !1;
    }
  });
}
function bt(e) {
  const t = vt(), n = Q(e) ? Z(e) : e;
  return ["transform", "translate", "scale", "rotate", "perspective"].some((r) => n[r] ? n[r] !== "none" : !1) || (n.containerType ? n.containerType !== "normal" : !1) || !t && (n.backdropFilter ? n.backdropFilter !== "none" : !1) || !t && (n.filter ? n.filter !== "none" : !1) || ["transform", "translate", "scale", "rotate", "perspective", "filter"].some((r) => (n.willChange || "").includes(r)) || ["paint", "layout", "strict", "content"].some((r) => (n.contain || "").includes(r));
}
function Or(e) {
  let t = se(e);
  for (; te(t) && !we(t); ) {
    if (bt(t))
      return t;
    if (Ye(t))
      return null;
    t = se(t);
  }
  return null;
}
function vt() {
  return typeof CSS > "u" || !CSS.supports ? !1 : CSS.supports("-webkit-backdrop-filter", "none");
}
function we(e) {
  return ["html", "body", "#document"].includes(xe(e));
}
function Z(e) {
  return Y(e).getComputedStyle(e);
}
function Ge(e) {
  return Q(e) ? {
    scrollLeft: e.scrollLeft,
    scrollTop: e.scrollTop
  } : {
    scrollLeft: e.scrollX,
    scrollTop: e.scrollY
  };
}
function se(e) {
  if (xe(e) === "html")
    return e;
  const t = (
    // Step into the shadow DOM of the parent of a slotted node.
    e.assignedSlot || // DOM Element detected.
    e.parentNode || // ShadowRoot detected.
    At(e) && e.host || // Fallback.
    ne(e)
  );
  return At(t) ? t.host : t;
}
function sn(e) {
  const t = se(e);
  return we(t) ? e.ownerDocument ? e.ownerDocument.body : e.body : te(t) && Ae(t) ? t : sn(t);
}
function oe(e, t, n) {
  var r;
  t === void 0 && (t = []), n === void 0 && (n = !0);
  const o = sn(e), i = o === ((r = e.ownerDocument) == null ? void 0 : r.body), s = Y(o);
  if (i) {
    const u = ft(s);
    return t.concat(s, s.visualViewport || [], Ae(o) ? o : [], u && n ? oe(u) : []);
  }
  return t.concat(o, oe(o, [], n));
}
function ft(e) {
  return e.parent && Object.getPrototypeOf(e.parent) ? e.frameElement : null;
}
function un(e) {
  const t = Z(e);
  let n = parseFloat(t.width) || 0, r = parseFloat(t.height) || 0;
  const o = te(e), i = o ? e.offsetWidth : n, s = o ? e.offsetHeight : r, u = We(n) !== i || We(r) !== s;
  return u && (n = i, r = s), {
    width: n,
    height: r,
    $: u
  };
}
function yt(e) {
  return Q(e) ? e : e.contextElement;
}
function he(e) {
  const t = yt(e);
  if (!te(t))
    return ee(1);
  const n = t.getBoundingClientRect(), {
    width: r,
    height: o,
    $: i
  } = un(t);
  let s = (i ? We(n.width) : n.width) / r, u = (i ? We(n.height) : n.height) / o;
  return (!s || !Number.isFinite(s)) && (s = 1), (!u || !Number.isFinite(u)) && (u = 1), {
    x: s,
    y: u
  };
}
const Ar = /* @__PURE__ */ ee(0);
function cn(e) {
  const t = Y(e);
  return !vt() || !t.visualViewport ? Ar : {
    x: t.visualViewport.offsetLeft,
    y: t.visualViewport.offsetTop
  };
}
function Pr(e, t, n) {
  return t === void 0 && (t = !1), !n || t && n !== Y(e) ? !1 : t;
}
function de(e, t, n, r) {
  t === void 0 && (t = !1), n === void 0 && (n = !1);
  const o = e.getBoundingClientRect(), i = yt(e);
  let s = ee(1);
  t && (r ? Q(r) && (s = he(r)) : s = he(e));
  const u = Pr(i, n, r) ? cn(i) : ee(0);
  let c = (o.left + u.x) / s.x, a = (o.top + u.y) / s.y, m = o.width / s.x, f = o.height / s.y;
  if (i) {
    const p = Y(i), l = r && Q(r) ? Y(r) : r;
    let g = p, h = ft(g);
    for (; h && r && l !== g; ) {
      const w = he(h), v = h.getBoundingClientRect(), b = Z(h), P = v.left + (h.clientLeft + parseFloat(b.paddingLeft)) * w.x, T = v.top + (h.clientTop + parseFloat(b.paddingTop)) * w.y;
      c *= w.x, a *= w.y, m *= w.x, f *= w.y, c += P, a += T, g = Y(h), h = ft(g);
    }
  }
  return $e({
    width: m,
    height: f,
    x: c,
    y: a
  });
}
function wt(e, t) {
  const n = Ge(e).scrollLeft;
  return t ? t.left + n : de(ne(e)).left + n;
}
function ln(e, t, n) {
  n === void 0 && (n = !1);
  const r = e.getBoundingClientRect(), o = r.left + t.scrollLeft - (n ? 0 : (
    // RTL <body> scrollbar.
    wt(e, r)
  )), i = r.top + t.scrollTop;
  return {
    x: o,
    y: i
  };
}
function kr(e) {
  let {
    elements: t,
    rect: n,
    offsetParent: r,
    strategy: o
  } = e;
  const i = o === "fixed", s = ne(r), u = t ? Ye(t.floating) : !1;
  if (r === s || u && i)
    return n;
  let c = {
    scrollLeft: 0,
    scrollTop: 0
  }, a = ee(1);
  const m = ee(0), f = te(r);
  if ((f || !f && !i) && ((xe(r) !== "body" || Ae(s)) && (c = Ge(r)), te(r))) {
    const l = de(r);
    a = he(r), m.x = l.x + r.clientLeft, m.y = l.y + r.clientTop;
  }
  const p = s && !f && !i ? ln(s, c, !0) : ee(0);
  return {
    width: n.width * a.x,
    height: n.height * a.y,
    x: n.x * a.x - c.scrollLeft * a.x + m.x + p.x,
    y: n.y * a.y - c.scrollTop * a.y + m.y + p.y
  };
}
function Dr(e) {
  return Array.from(e.getClientRects());
}
function Fr(e) {
  const t = ne(e), n = Ge(e), r = e.ownerDocument.body, o = ge(t.scrollWidth, t.clientWidth, r.scrollWidth, r.clientWidth), i = ge(t.scrollHeight, t.clientHeight, r.scrollHeight, r.clientHeight);
  let s = -n.scrollLeft + wt(e);
  const u = -n.scrollTop;
  return Z(r).direction === "rtl" && (s += ge(t.clientWidth, r.clientWidth) - o), {
    width: o,
    height: i,
    x: s,
    y: u
  };
}
function Ir(e, t) {
  const n = Y(e), r = ne(e), o = n.visualViewport;
  let i = r.clientWidth, s = r.clientHeight, u = 0, c = 0;
  if (o) {
    i = o.width, s = o.height;
    const a = vt();
    (!a || a && t === "fixed") && (u = o.offsetLeft, c = o.offsetTop);
  }
  return {
    width: i,
    height: s,
    x: u,
    y: c
  };
}
function Mr(e, t) {
  const n = de(e, !0, t === "fixed"), r = n.top + e.clientTop, o = n.left + e.clientLeft, i = te(e) ? he(e) : ee(1), s = e.clientWidth * i.x, u = e.clientHeight * i.y, c = o * i.x, a = r * i.y;
  return {
    width: s,
    height: u,
    x: c,
    y: a
  };
}
function Pt(e, t, n) {
  let r;
  if (t === "viewport")
    r = Ir(e, n);
  else if (t === "document")
    r = Fr(ne(e));
  else if (Q(t))
    r = Mr(t, n);
  else {
    const o = cn(e);
    r = {
      x: t.x - o.x,
      y: t.y - o.y,
      width: t.width,
      height: t.height
    };
  }
  return $e(r);
}
function an(e, t) {
  const n = se(e);
  return n === t || !Q(n) || we(n) ? !1 : Z(n).position === "fixed" || an(n, t);
}
function Lr(e, t) {
  const n = t.get(e);
  if (n)
    return n;
  let r = oe(e, [], !1).filter((u) => Q(u) && xe(u) !== "body"), o = null;
  const i = Z(e).position === "fixed";
  let s = i ? se(e) : e;
  for (; Q(s) && !we(s); ) {
    const u = Z(s), c = bt(s);
    !c && u.position === "fixed" && (o = null), (i ? !c && !o : !c && u.position === "static" && !!o && ["absolute", "fixed"].includes(o.position) || Ae(s) && !c && an(e, s)) ? r = r.filter((m) => m !== s) : o = u, s = se(s);
  }
  return t.set(e, r), r;
}
function Nr(e) {
  let {
    element: t,
    boundary: n,
    rootBoundary: r,
    strategy: o
  } = e;
  const s = [...n === "clippingAncestors" ? Ye(t) ? [] : Lr(t, this._c) : [].concat(n), r], u = s[0], c = s.reduce((a, m) => {
    const f = Pt(t, m, o);
    return a.top = ge(f.top, a.top), a.right = at(f.right, a.right), a.bottom = at(f.bottom, a.bottom), a.left = ge(f.left, a.left), a;
  }, Pt(t, u, o));
  return {
    width: c.right - c.left,
    height: c.bottom - c.top,
    x: c.left,
    y: c.top
  };
}
function Br(e) {
  const {
    width: t,
    height: n
  } = un(e);
  return {
    width: t,
    height: n
  };
}
function $r(e, t, n) {
  const r = te(t), o = ne(t), i = n === "fixed", s = de(e, !0, i, t);
  let u = {
    scrollLeft: 0,
    scrollTop: 0
  };
  const c = ee(0);
  if (r || !r && !i)
    if ((xe(t) !== "body" || Ae(o)) && (u = Ge(t)), r) {
      const p = de(t, !0, i, t);
      c.x = p.x + t.clientLeft, c.y = p.y + t.clientTop;
    } else o && (c.x = wt(o));
  const a = o && !r && !i ? ln(o, u) : ee(0), m = s.left + u.scrollLeft - c.x - a.x, f = s.top + u.scrollTop - c.y - a.y;
  return {
    x: m,
    y: f,
    width: s.width,
    height: s.height
  };
}
function Je(e) {
  return Z(e).position === "static";
}
function kt(e, t) {
  if (!te(e) || Z(e).position === "fixed")
    return null;
  if (t)
    return t(e);
  let n = e.offsetParent;
  return ne(e) === n && (n = n.ownerDocument.body), n;
}
function fn(e, t) {
  const n = Y(e);
  if (Ye(e))
    return n;
  if (!te(e)) {
    let o = se(e);
    for (; o && !we(o); ) {
      if (Q(o) && !Je(o))
        return o;
      o = se(o);
    }
    return n;
  }
  let r = kt(e, t);
  for (; r && Sr(r) && Je(r); )
    r = kt(r, t);
  return r && we(r) && Je(r) && !bt(r) ? n : r || Or(e) || n;
}
const Wr = async function(e) {
  const t = this.getOffsetParent || fn, n = this.getDimensions, r = await n(e.floating);
  return {
    reference: $r(e.reference, await t(e.floating), e.strategy),
    floating: {
      x: 0,
      y: 0,
      width: r.width,
      height: r.height
    }
  };
};
function Vr(e) {
  return Z(e).direction === "rtl";
}
const _r = {
  convertOffsetParentRelativeRectToViewportRelativeRect: kr,
  getDocumentElement: ne,
  getClippingRect: Nr,
  getOffsetParent: fn,
  getElementRects: Wr,
  getClientRects: Dr,
  getDimensions: Br,
  getScale: he,
  isElement: Q,
  isRTL: Vr
};
function dn(e, t) {
  return e.x === t.x && e.y === t.y && e.width === t.width && e.height === t.height;
}
function Hr(e, t) {
  let n = null, r;
  const o = ne(e);
  function i() {
    var u;
    clearTimeout(r), (u = n) == null || u.disconnect(), n = null;
  }
  function s(u, c) {
    u === void 0 && (u = !1), c === void 0 && (c = 1), i();
    const a = e.getBoundingClientRect(), {
      left: m,
      top: f,
      width: p,
      height: l
    } = a;
    if (u || t(), !p || !l)
      return;
    const g = Pe(f), h = Pe(o.clientWidth - (m + p)), w = Pe(o.clientHeight - (f + l)), v = Pe(m), P = {
      rootMargin: -g + "px " + -h + "px " + -w + "px " + -v + "px",
      threshold: ge(0, at(1, c)) || 1
    };
    let T = !0;
    function C(S) {
      const y = S[0].intersectionRatio;
      if (y !== c) {
        if (!T)
          return s();
        y ? s(!1, y) : r = setTimeout(() => {
          s(!1, 1e-7);
        }, 1e3);
      }
      y === 1 && !dn(a, e.getBoundingClientRect()) && s(), T = !1;
    }
    try {
      n = new IntersectionObserver(C, {
        ...P,
        // Handle <iframe>s
        root: o.ownerDocument
      });
    } catch {
      n = new IntersectionObserver(C, P);
    }
    n.observe(e);
  }
  return s(!0), i;
}
function mn(e, t, n, r) {
  r === void 0 && (r = {});
  const {
    ancestorScroll: o = !0,
    ancestorResize: i = !0,
    elementResize: s = typeof ResizeObserver == "function",
    layoutShift: u = typeof IntersectionObserver == "function",
    animationFrame: c = !1
  } = r, a = yt(e), m = o || i ? [...a ? oe(a) : [], ...oe(t)] : [];
  m.forEach((v) => {
    o && v.addEventListener("scroll", n, {
      passive: !0
    }), i && v.addEventListener("resize", n);
  });
  const f = a && u ? Hr(a, n) : null;
  let p = -1, l = null;
  s && (l = new ResizeObserver((v) => {
    let [b] = v;
    b && b.target === a && l && (l.unobserve(t), cancelAnimationFrame(p), p = requestAnimationFrame(() => {
      var P;
      (P = l) == null || P.observe(t);
    })), n();
  }), a && !c && l.observe(a), l.observe(t));
  let g, h = c ? de(e) : null;
  c && w();
  function w() {
    const v = de(e);
    h && !dn(h, v) && n(), h = v, g = requestAnimationFrame(w);
  }
  return n(), () => {
    var v;
    m.forEach((b) => {
      o && b.removeEventListener("scroll", n), i && b.removeEventListener("resize", n);
    }), f == null || f(), (v = l) == null || v.disconnect(), l = null, c && cancelAnimationFrame(g);
  };
}
const jr = Tr, zr = Cr, Kr = Er, qr = (e, t, n) => {
  const r = /* @__PURE__ */ new Map(), o = {
    platform: _r,
    ...n
  }, i = {
    ...o.platform,
    _c: r
  };
  return xr(e, t, {
    ...o,
    platform: i
  });
};
var Me = typeof document < "u" ? Se : be;
function Ve(e, t) {
  if (e === t)
    return !0;
  if (typeof e != typeof t)
    return !1;
  if (typeof e == "function" && e.toString() === t.toString())
    return !0;
  let n, r, o;
  if (e && t && typeof e == "object") {
    if (Array.isArray(e)) {
      if (n = e.length, n !== t.length) return !1;
      for (r = n; r-- !== 0; )
        if (!Ve(e[r], t[r]))
          return !1;
      return !0;
    }
    if (o = Object.keys(e), n = o.length, n !== Object.keys(t).length)
      return !1;
    for (r = n; r-- !== 0; )
      if (!{}.hasOwnProperty.call(t, o[r]))
        return !1;
    for (r = n; r-- !== 0; ) {
      const i = o[r];
      if (!(i === "_owner" && e.$$typeof) && !Ve(e[i], t[i]))
        return !1;
    }
    return !0;
  }
  return e !== e && t !== t;
}
function pn(e) {
  return typeof window > "u" ? 1 : (e.ownerDocument.defaultView || window).devicePixelRatio || 1;
}
function Dt(e, t) {
  const n = pn(e);
  return Math.round(t * n) / n;
}
function et(e) {
  const t = d.useRef(e);
  return Me(() => {
    t.current = e;
  }), t;
}
function Ur(e) {
  e === void 0 && (e = {});
  const {
    placement: t = "bottom",
    strategy: n = "absolute",
    middleware: r = [],
    platform: o,
    elements: {
      reference: i,
      floating: s
    } = {},
    transform: u = !0,
    whileElementsMounted: c,
    open: a
  } = e, [m, f] = d.useState({
    x: 0,
    y: 0,
    strategy: n,
    placement: t,
    middlewareData: {},
    isPositioned: !1
  }), [p, l] = d.useState(r);
  Ve(p, r) || l(r);
  const [g, h] = d.useState(null), [w, v] = d.useState(null), b = d.useCallback((x) => {
    x !== S.current && (S.current = x, h(x));
  }, []), P = d.useCallback((x) => {
    x !== y.current && (y.current = x, v(x));
  }, []), T = i || g, C = s || w, S = d.useRef(null), y = d.useRef(null), E = d.useRef(m), B = c != null, H = et(c), D = et(o), N = et(a), R = d.useCallback(() => {
    if (!S.current || !y.current)
      return;
    const x = {
      placement: t,
      strategy: n,
      middleware: p
    };
    D.current && (x.platform = D.current), qr(S.current, y.current, x).then((F) => {
      const _ = {
        ...F,
        // The floating element's position may be recomputed while it's closed
        // but still mounted (such as when transitioning out). To ensure
        // `isPositioned` will be `false` initially on the next open, avoid
        // setting it to `true` when `open === false` (must be specified).
        isPositioned: N.current !== !1
      };
      O.current && !Ve(E.current, _) && (E.current = _, Zn.flushSync(() => {
        f(_);
      }));
    });
  }, [p, t, n, D, N]);
  Me(() => {
    a === !1 && E.current.isPositioned && (E.current.isPositioned = !1, f((x) => ({
      ...x,
      isPositioned: !1
    })));
  }, [a]);
  const O = d.useRef(!1);
  Me(() => (O.current = !0, () => {
    O.current = !1;
  }), []), Me(() => {
    if (T && (S.current = T), C && (y.current = C), T && C) {
      if (H.current)
        return H.current(T, C, R);
      R();
    }
  }, [T, C, R, H, B]);
  const k = d.useMemo(() => ({
    reference: S,
    floating: y,
    setReference: b,
    setFloating: P
  }), [b, P]), M = d.useMemo(() => ({
    reference: T,
    floating: C
  }), [T, C]), W = d.useMemo(() => {
    const x = {
      position: n,
      left: 0,
      top: 0
    };
    if (!M.floating)
      return x;
    const F = Dt(M.floating, m.x), _ = Dt(M.floating, m.y);
    return u ? {
      ...x,
      transform: "translate(" + F + "px, " + _ + "px)",
      ...pn(M.floating) >= 1.5 && {
        willChange: "transform"
      }
    } : {
      position: n,
      left: F,
      top: _
    };
  }, [n, u, M.floating, m.x, m.y]);
  return d.useMemo(() => ({
    ...m,
    update: R,
    refs: k,
    elements: M,
    floatingStyles: W
  }), [m, R, k, M, W]);
}
const gn = (e, t) => ({
  ...jr(e),
  options: [e, t]
}), hn = (e, t) => ({
  ...zr(e),
  options: [e, t]
}), bn = (e, t) => ({
  ...Kr(e),
  options: [e, t]
});
/*!
* tabbable 6.2.0
* @license MIT, https://github.com/focus-trap/tabbable/blob/master/LICENSE
*/
var Yr = ["input:not([inert])", "select:not([inert])", "textarea:not([inert])", "a[href]:not([inert])", "button:not([inert])", "[tabindex]:not(slot):not([inert])", "audio[controls]:not([inert])", "video[controls]:not([inert])", '[contenteditable]:not([contenteditable="false"]):not([inert])', "details>summary:first-of-type:not([inert])", "details:not([inert])"], dt = /* @__PURE__ */ Yr.join(","), vn = typeof Element > "u", Ce = vn ? function() {
} : Element.prototype.matches || Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector, _e = !vn && Element.prototype.getRootNode ? function(e) {
  var t;
  return e == null || (t = e.getRootNode) === null || t === void 0 ? void 0 : t.call(e);
} : function(e) {
  return e == null ? void 0 : e.ownerDocument;
}, He = function e(t, n) {
  var r;
  n === void 0 && (n = !0);
  var o = t == null || (r = t.getAttribute) === null || r === void 0 ? void 0 : r.call(t, "inert"), i = o === "" || o === "true", s = i || n && t && e(t.parentNode);
  return s;
}, Gr = function(t) {
  var n, r = t == null || (n = t.getAttribute) === null || n === void 0 ? void 0 : n.call(t, "contenteditable");
  return r === "" || r === "true";
}, Xr = function(t, n, r) {
  if (He(t))
    return [];
  var o = Array.prototype.slice.apply(t.querySelectorAll(dt));
  return n && Ce.call(t, dt) && o.unshift(t), o = o.filter(r), o;
}, Qr = function e(t, n, r) {
  for (var o = [], i = Array.from(t); i.length; ) {
    var s = i.shift();
    if (!He(s, !1))
      if (s.tagName === "SLOT") {
        var u = s.assignedElements(), c = u.length ? u : s.children, a = e(c, !0, r);
        r.flatten ? o.push.apply(o, a) : o.push({
          scopeParent: s,
          candidates: a
        });
      } else {
        var m = Ce.call(s, dt);
        m && r.filter(s) && (n || !t.includes(s)) && o.push(s);
        var f = s.shadowRoot || // check for an undisclosed shadow
        typeof r.getShadowRoot == "function" && r.getShadowRoot(s), p = !He(f, !1) && (!r.shadowRootFilter || r.shadowRootFilter(s));
        if (f && p) {
          var l = e(f === !0 ? s.children : f.children, !0, r);
          r.flatten ? o.push.apply(o, l) : o.push({
            scopeParent: s,
            candidates: l
          });
        } else
          i.unshift.apply(i, s.children);
      }
  }
  return o;
}, yn = function(t) {
  return !isNaN(parseInt(t.getAttribute("tabindex"), 10));
}, wn = function(t) {
  if (!t)
    throw new Error("No node provided");
  return t.tabIndex < 0 && (/^(AUDIO|VIDEO|DETAILS)$/.test(t.tagName) || Gr(t)) && !yn(t) ? 0 : t.tabIndex;
}, Zr = function(t, n) {
  var r = wn(t);
  return r < 0 && n && !yn(t) ? 0 : r;
}, Jr = function(t, n) {
  return t.tabIndex === n.tabIndex ? t.documentOrder - n.documentOrder : t.tabIndex - n.tabIndex;
}, xn = function(t) {
  return t.tagName === "INPUT";
}, eo = function(t) {
  return xn(t) && t.type === "hidden";
}, to = function(t) {
  var n = t.tagName === "DETAILS" && Array.prototype.slice.apply(t.children).some(function(r) {
    return r.tagName === "SUMMARY";
  });
  return n;
}, no = function(t, n) {
  for (var r = 0; r < t.length; r++)
    if (t[r].checked && t[r].form === n)
      return t[r];
}, ro = function(t) {
  if (!t.name)
    return !0;
  var n = t.form || _e(t), r = function(u) {
    return n.querySelectorAll('input[type="radio"][name="' + u + '"]');
  }, o;
  if (typeof window < "u" && typeof window.CSS < "u" && typeof window.CSS.escape == "function")
    o = r(window.CSS.escape(t.name));
  else
    try {
      o = r(t.name);
    } catch (s) {
      return console.error("Looks like you have a radio button with a name attribute containing invalid CSS selector characters and need the CSS.escape polyfill: %s", s.message), !1;
    }
  var i = no(o, t.form);
  return !i || i === t;
}, oo = function(t) {
  return xn(t) && t.type === "radio";
}, io = function(t) {
  return oo(t) && !ro(t);
}, so = function(t) {
  var n, r = t && _e(t), o = (n = r) === null || n === void 0 ? void 0 : n.host, i = !1;
  if (r && r !== t) {
    var s, u, c;
    for (i = !!((s = o) !== null && s !== void 0 && (u = s.ownerDocument) !== null && u !== void 0 && u.contains(o) || t != null && (c = t.ownerDocument) !== null && c !== void 0 && c.contains(t)); !i && o; ) {
      var a, m, f;
      r = _e(o), o = (a = r) === null || a === void 0 ? void 0 : a.host, i = !!((m = o) !== null && m !== void 0 && (f = m.ownerDocument) !== null && f !== void 0 && f.contains(o));
    }
  }
  return i;
}, Ft = function(t) {
  var n = t.getBoundingClientRect(), r = n.width, o = n.height;
  return r === 0 && o === 0;
}, uo = function(t, n) {
  var r = n.displayCheck, o = n.getShadowRoot;
  if (getComputedStyle(t).visibility === "hidden")
    return !0;
  var i = Ce.call(t, "details>summary:first-of-type"), s = i ? t.parentElement : t;
  if (Ce.call(s, "details:not([open]) *"))
    return !0;
  if (!r || r === "full" || r === "legacy-full") {
    if (typeof o == "function") {
      for (var u = t; t; ) {
        var c = t.parentElement, a = _e(t);
        if (c && !c.shadowRoot && o(c) === !0)
          return Ft(t);
        t.assignedSlot ? t = t.assignedSlot : !c && a !== t.ownerDocument ? t = a.host : t = c;
      }
      t = u;
    }
    if (so(t))
      return !t.getClientRects().length;
    if (r !== "legacy-full")
      return !0;
  } else if (r === "non-zero-area")
    return Ft(t);
  return !1;
}, co = function(t) {
  if (/^(INPUT|BUTTON|SELECT|TEXTAREA)$/.test(t.tagName))
    for (var n = t.parentElement; n; ) {
      if (n.tagName === "FIELDSET" && n.disabled) {
        for (var r = 0; r < n.children.length; r++) {
          var o = n.children.item(r);
          if (o.tagName === "LEGEND")
            return Ce.call(n, "fieldset[disabled] *") ? !0 : !o.contains(t);
        }
        return !0;
      }
      n = n.parentElement;
    }
  return !1;
}, lo = function(t, n) {
  return !(n.disabled || // we must do an inert look up to filter out any elements inside an inert ancestor
  //  because we're limited in the type of selectors we can use in JSDom (see related
  //  note related to `candidateSelectors`)
  He(n) || eo(n) || uo(n, t) || // For a details element with a summary, the summary element gets the focus
  to(n) || co(n));
}, It = function(t, n) {
  return !(io(n) || wn(n) < 0 || !lo(t, n));
}, ao = function(t) {
  var n = parseInt(t.getAttribute("tabindex"), 10);
  return !!(isNaN(n) || n >= 0);
}, fo = function e(t) {
  var n = [], r = [];
  return t.forEach(function(o, i) {
    var s = !!o.scopeParent, u = s ? o.scopeParent : o, c = Zr(u, s), a = s ? e(o.candidates) : u;
    c === 0 ? s ? n.push.apply(n, a) : n.push(u) : r.push({
      documentOrder: i,
      tabIndex: c,
      item: o,
      isScope: s,
      content: a
    });
  }), r.sort(Jr).reduce(function(o, i) {
    return i.isScope ? o.push.apply(o, i.content) : o.push(i.content), o;
  }, []).concat(n);
}, xt = function(t, n) {
  n = n || {};
  var r;
  return n.getShadowRoot ? r = Qr([t], n.includeContainer, {
    filter: It.bind(null, n),
    flatten: !1,
    getShadowRoot: n.getShadowRoot,
    shadowRootFilter: ao
  }) : r = Xr(t, n.includeContainer, It.bind(null, n)), fo(r);
};
function Xe(e) {
  return d.useMemo(() => e.every((t) => t == null) ? null : (t) => {
    e.forEach((n) => {
      typeof n == "function" ? n(t) : n != null && (n.current = t);
    });
  }, e);
}
let Mt = 0;
function ce(e, t) {
  t === void 0 && (t = {});
  const {
    preventScroll: n = !1,
    cancelPrevious: r = !0,
    sync: o = !1
  } = t;
  r && cancelAnimationFrame(Mt);
  const i = () => e == null ? void 0 : e.focus({
    preventScroll: n
  });
  o ? i() : Mt = requestAnimationFrame(i);
}
var X = typeof document < "u" ? Se : be;
function je() {
  return je = Object.assign ? Object.assign.bind() : function(e) {
    for (var t = 1; t < arguments.length; t++) {
      var n = arguments[t];
      for (var r in n)
        Object.prototype.hasOwnProperty.call(n, r) && (e[r] = n[r]);
    }
    return e;
  }, je.apply(this, arguments);
}
let tt = !1, mo = 0;
const Lt = () => "floating-ui-" + mo++;
function po() {
  const [e, t] = d.useState(() => tt ? Lt() : void 0);
  return X(() => {
    e == null && t(Lt());
  }, []), d.useEffect(() => {
    tt || (tt = !0);
  }, []), e;
}
const go = d.useId, Et = go || po;
function ho() {
  const e = /* @__PURE__ */ new Map();
  return {
    emit(t, n) {
      var r;
      (r = e.get(t)) == null || r.forEach((o) => o(n));
    },
    on(t, n) {
      e.set(t, [...e.get(t) || [], n]);
    },
    off(t, n) {
      var r;
      e.set(t, ((r = e.get(t)) == null ? void 0 : r.filter((o) => o !== n)) || []);
    }
  };
}
const bo = /* @__PURE__ */ d.createContext(null), vo = /* @__PURE__ */ d.createContext(null), En = () => {
  var e;
  return ((e = d.useContext(bo)) == null ? void 0 : e.id) || null;
}, Qe = () => d.useContext(vo);
function me(e) {
  return "data-floating-ui-" + e;
}
function Ee(e) {
  const t = gt(e);
  return X(() => {
    t.current = e;
  }), t;
}
const Nt = /* @__PURE__ */ me("safe-polygon");
function nt(e, t, n) {
  return n && !Ne(n) ? 0 : typeof e == "number" ? e : e == null ? void 0 : e[t];
}
function yo(e, t) {
  t === void 0 && (t = {});
  const {
    open: n,
    onOpenChange: r,
    dataRef: o,
    events: i,
    elements: {
      domReference: s,
      floating: u
    },
    refs: c
  } = e, {
    enabled: a = !0,
    delay: m = 0,
    handleClose: f = null,
    mouseOnly: p = !1,
    restMs: l = 0,
    move: g = !0
  } = t, h = Qe(), w = En(), v = Ee(f), b = Ee(m), P = d.useRef(), T = d.useRef(), C = d.useRef(), S = d.useRef(), y = d.useRef(!0), E = d.useRef(!1), B = d.useRef(() => {
  }), H = d.useCallback(() => {
    var O;
    const k = (O = o.current.openEvent) == null ? void 0 : O.type;
    return (k == null ? void 0 : k.includes("mouse")) && k !== "mousedown";
  }, [o]);
  d.useEffect(() => {
    if (!a)
      return;
    function O() {
      clearTimeout(T.current), clearTimeout(S.current), y.current = !0;
    }
    return i.on("dismiss", O), () => {
      i.off("dismiss", O);
    };
  }, [a, i]), d.useEffect(() => {
    if (!a || !v.current || !n)
      return;
    function O(M) {
      H() && r(!1, M);
    }
    const k = z(u).documentElement;
    return k.addEventListener("mouseleave", O), () => {
      k.removeEventListener("mouseleave", O);
    };
  }, [u, n, r, a, v, o, H]);
  const D = d.useCallback(function(O, k) {
    k === void 0 && (k = !0);
    const M = nt(b.current, "close", P.current);
    M && !C.current ? (clearTimeout(T.current), T.current = setTimeout(() => r(!1, O), M)) : k && (clearTimeout(T.current), r(!1, O));
  }, [b, r]), N = d.useCallback(() => {
    B.current(), C.current = void 0;
  }, []), R = d.useCallback(() => {
    if (E.current) {
      const O = z(c.floating.current).body;
      O.style.pointerEvents = "", O.removeAttribute(Nt), E.current = !1;
    }
  }, [c]);
  return d.useEffect(() => {
    if (!a)
      return;
    function O() {
      return o.current.openEvent ? ["click", "mousedown"].includes(o.current.openEvent.type) : !1;
    }
    function k(x) {
      if (clearTimeout(T.current), y.current = !1, p && !Ne(P.current) || l > 0 && nt(b.current, "open") === 0)
        return;
      const F = nt(b.current, "open", P.current);
      F ? T.current = setTimeout(() => {
        r(!0, x);
      }, F) : r(!0, x);
    }
    function M(x) {
      if (O())
        return;
      B.current();
      const F = z(u);
      if (clearTimeout(S.current), v.current) {
        n || clearTimeout(T.current), C.current = v.current({
          ...e,
          tree: h,
          x: x.clientX,
          y: x.clientY,
          onClose() {
            R(), N(), D(x);
          }
        });
        const I = C.current;
        F.addEventListener("mousemove", I), B.current = () => {
          F.removeEventListener("mousemove", I);
        };
        return;
      }
      (P.current === "touch" ? !j(u, x.relatedTarget) : !0) && D(x);
    }
    function W(x) {
      O() || v.current == null || v.current({
        ...e,
        tree: h,
        x: x.clientX,
        y: x.clientY,
        onClose() {
          R(), N(), D(x);
        }
      })(x);
    }
    if (q(s)) {
      const x = s;
      return n && x.addEventListener("mouseleave", W), u == null || u.addEventListener("mouseleave", W), g && x.addEventListener("mousemove", k, {
        once: !0
      }), x.addEventListener("mouseenter", k), x.addEventListener("mouseleave", M), () => {
        n && x.removeEventListener("mouseleave", W), u == null || u.removeEventListener("mouseleave", W), g && x.removeEventListener("mousemove", k), x.removeEventListener("mouseenter", k), x.removeEventListener("mouseleave", M);
      };
    }
  }, [s, u, a, e, p, l, g, D, N, R, r, n, h, b, v, o]), X(() => {
    var O;
    if (a && n && (O = v.current) != null && O.__options.blockPointerEvents && H()) {
      const W = z(u).body;
      if (W.setAttribute(Nt, ""), W.style.pointerEvents = "none", E.current = !0, q(s) && u) {
        var k, M;
        const x = s, F = h == null || (k = h.nodesRef.current.find((_) => _.id === w)) == null || (M = k.context) == null ? void 0 : M.elements.floating;
        return F && (F.style.pointerEvents = ""), x.style.pointerEvents = "auto", u.style.pointerEvents = "auto", () => {
          x.style.pointerEvents = "", u.style.pointerEvents = "";
        };
      }
    }
  }, [a, n, w, u, s, h, v, o, H]), X(() => {
    n || (P.current = void 0, N(), R());
  }, [n, N, R]), d.useEffect(() => () => {
    N(), clearTimeout(T.current), clearTimeout(S.current), R();
  }, [a, s, N, R]), d.useMemo(() => {
    if (!a)
      return {};
    function O(k) {
      P.current = k.pointerType;
    }
    return {
      reference: {
        onPointerDown: O,
        onPointerEnter: O,
        onMouseMove(k) {
          n || l === 0 || (clearTimeout(S.current), S.current = setTimeout(() => {
            y.current || r(!0, k.nativeEvent);
          }, l));
        }
      },
      floating: {
        onMouseEnter() {
          clearTimeout(T.current);
        },
        onMouseLeave(k) {
          i.emit("dismiss", {
            type: "mouseLeave",
            data: {
              returnFocus: !1
            }
          }), D(k.nativeEvent, !1);
        }
      }
    };
  }, [i, a, l, n, r, D]);
}
function wo(e, t) {
  var n;
  let r = [], o = (n = e.find((i) => i.id === t)) == null ? void 0 : n.parentId;
  for (; o; ) {
    const i = e.find((s) => s.id === o);
    o = i == null ? void 0 : i.parentId, i && (r = r.concat(i));
  }
  return r;
}
function Re(e, t) {
  let n = e.filter((o) => {
    var i;
    return o.parentId === t && ((i = o.context) == null ? void 0 : i.open);
  }), r = n;
  for (; r.length; )
    r = e.filter((o) => {
      var i;
      return (i = r) == null ? void 0 : i.some((s) => {
        var u;
        return o.parentId === s.id && ((u = o.context) == null ? void 0 : u.open);
      });
    }), n = n.concat(r);
  return n;
}
let pe = /* @__PURE__ */ new WeakMap(), ke = /* @__PURE__ */ new WeakSet(), De = {}, rt = 0;
const xo = () => typeof HTMLElement < "u" && "inert" in HTMLElement.prototype, Rn = (e) => e && (e.host || Rn(e.parentNode)), Eo = (e, t) => t.map((n) => {
  if (e.contains(n))
    return n;
  const r = Rn(n);
  return e.contains(r) ? r : null;
}).filter((n) => n != null);
function Ro(e, t, n, r) {
  const o = "data-floating-ui-inert", i = r ? "inert" : n ? "aria-hidden" : null, s = Eo(t, e), u = /* @__PURE__ */ new Set(), c = new Set(s), a = [];
  De[o] || (De[o] = /* @__PURE__ */ new WeakMap());
  const m = De[o];
  s.forEach(f), p(t), u.clear();
  function f(l) {
    !l || u.has(l) || (u.add(l), l.parentNode && f(l.parentNode));
  }
  function p(l) {
    !l || c.has(l) || Array.prototype.forEach.call(l.children, (g) => {
      if (u.has(g))
        p(g);
      else {
        const h = i ? g.getAttribute(i) : null, w = h !== null && h !== "false", v = (pe.get(g) || 0) + 1, b = (m.get(g) || 0) + 1;
        pe.set(g, v), m.set(g, b), a.push(g), v === 1 && w && ke.add(g), b === 1 && g.setAttribute(o, ""), !w && i && g.setAttribute(i, "true");
      }
    });
  }
  return rt++, () => {
    a.forEach((l) => {
      const g = (pe.get(l) || 0) - 1, h = (m.get(l) || 0) - 1;
      pe.set(l, g), m.set(l, h), g || (!ke.has(l) && i && l.removeAttribute(i), ke.delete(l)), h || l.removeAttribute(o);
    }), rt--, rt || (pe = /* @__PURE__ */ new WeakMap(), pe = /* @__PURE__ */ new WeakMap(), ke = /* @__PURE__ */ new WeakSet(), De = {});
  };
}
function Bt(e, t, n) {
  t === void 0 && (t = !1), n === void 0 && (n = !1);
  const r = z(e[0]).body;
  return Ro(e.concat(Array.from(r.querySelectorAll("[aria-live]"))), r, t, n);
}
const Rt = () => ({
  getShadowRoot: !0,
  displayCheck: (
    // JSDOM does not support the `tabbable` library. To solve this we can
    // check if `ResizeObserver` is a real function (not polyfilled), which
    // determines if the current environment is JSDOM-like.
    typeof ResizeObserver == "function" && ResizeObserver.toString().includes("[native code]") ? "full" : "none"
  )
});
function Tn(e, t) {
  const n = xt(e, Rt());
  t === "prev" && n.reverse();
  const r = n.indexOf(le(z(e)));
  return n.slice(r + 1)[0];
}
function Cn() {
  return Tn(document.body, "next");
}
function Sn() {
  return Tn(document.body, "prev");
}
function Te(e, t) {
  const n = t || e.currentTarget, r = e.relatedTarget;
  return !r || !j(n, r);
}
function To(e) {
  xt(e, Rt()).forEach((n) => {
    n.dataset.tabindex = n.getAttribute("tabindex") || "", n.setAttribute("tabindex", "-1");
  });
}
function Co(e) {
  e.querySelectorAll("[data-tabindex]").forEach((n) => {
    const r = n.dataset.tabindex;
    delete n.dataset.tabindex, r ? n.setAttribute("tabindex", r) : n.removeAttribute("tabindex");
  });
}
const Tt = {
  border: 0,
  clip: "rect(0 0 0 0)",
  height: "1px",
  margin: "-1px",
  overflow: "hidden",
  padding: 0,
  position: "fixed",
  whiteSpace: "nowrap",
  width: "1px",
  top: 0,
  left: 0
};
let So;
function $t(e) {
  e.key === "Tab" && (e.target, clearTimeout(So));
}
const ze = /* @__PURE__ */ d.forwardRef(function(t, n) {
  const [r, o] = d.useState();
  X(() => (ur() && o("button"), document.addEventListener("keydown", $t), () => {
    document.removeEventListener("keydown", $t);
  }), []);
  const i = {
    ref: n,
    tabIndex: 0,
    // Role is only for VoiceOver
    role: r,
    "aria-hidden": r ? void 0 : !0,
    [me("focus-guard")]: "",
    style: Tt
  };
  return /* @__PURE__ */ d.createElement("span", je({}, t, i));
}), On = /* @__PURE__ */ d.createContext(null);
function Oo(e) {
  let {
    id: t,
    root: n
  } = e === void 0 ? {} : e;
  const [r, o] = d.useState(null), i = Et(), s = Pn(), u = d.useMemo(() => ({
    id: t,
    root: n,
    portalContext: s,
    uniqueId: i
  }), [t, n, s, i]), c = d.useRef();
  return X(() => () => {
    r == null || r.remove();
  }, [r, u]), X(() => {
    if (c.current === u) return;
    c.current = u;
    const {
      id: a,
      root: m,
      portalContext: f,
      uniqueId: p
    } = u, l = a ? document.getElementById(a) : null, g = me("portal");
    if (l) {
      const h = document.createElement("div");
      h.id = p, h.setAttribute(g, ""), l.appendChild(h), o(h);
    } else {
      let h = m || (f == null ? void 0 : f.portalNode);
      h && !q(h) && (h = h.current), h = h || document.body;
      let w = null;
      a && (w = document.createElement("div"), w.id = a, h.appendChild(w));
      const v = document.createElement("div");
      v.id = p, v.setAttribute(g, ""), h = w || h, h.appendChild(v), o(v);
    }
  }, [u]), r;
}
function An(e) {
  let {
    children: t,
    id: n,
    root: r = null,
    preserveTabOrder: o = !0
  } = e;
  const i = Oo({
    id: n,
    root: r
  }), [s, u] = d.useState(null), c = d.useRef(null), a = d.useRef(null), m = d.useRef(null), f = d.useRef(null), p = (
    // The FocusManager and therefore floating element are currently open/
    // rendered.
    !!s && // Guards are only for non-modal focus management.
    !s.modal && // Don't render if unmount is transitioning.
    s.open && o && !!(r || i)
  );
  return d.useEffect(() => {
    if (!i || !o || s != null && s.modal)
      return;
    function l(g) {
      i && Te(g) && (g.type === "focusin" ? Co : To)(i);
    }
    return i.addEventListener("focusin", l, !0), i.addEventListener("focusout", l, !0), () => {
      i.removeEventListener("focusin", l, !0), i.removeEventListener("focusout", l, !0);
    };
  }, [i, o, s == null ? void 0 : s.modal]), /* @__PURE__ */ d.createElement(On.Provider, {
    value: d.useMemo(() => ({
      preserveTabOrder: o,
      beforeOutsideRef: c,
      afterOutsideRef: a,
      beforeInsideRef: m,
      afterInsideRef: f,
      portalNode: i,
      setFocusManagerState: u
    }), [o, i])
  }, p && i && /* @__PURE__ */ d.createElement(ze, {
    "data-type": "outside",
    ref: c,
    onFocus: (l) => {
      if (Te(l, i)) {
        var g;
        (g = m.current) == null || g.focus();
      } else {
        const h = Sn() || (s == null ? void 0 : s.refs.domReference.current);
        h == null || h.focus();
      }
    }
  }), p && i && /* @__PURE__ */ d.createElement("span", {
    "aria-owns": i.id,
    style: Tt
  }), i && /* @__PURE__ */ Gt(t, i), p && i && /* @__PURE__ */ d.createElement(ze, {
    "data-type": "outside",
    ref: a,
    onFocus: (l) => {
      if (Te(l, i)) {
        var g;
        (g = f.current) == null || g.focus();
      } else {
        const h = Cn() || (s == null ? void 0 : s.refs.domReference.current);
        h == null || h.focus(), s != null && s.closeOnFocusOut && (s == null || s.onOpenChange(!1, l.nativeEvent));
      }
    }
  }));
}
const Pn = () => d.useContext(On), Ao = /* @__PURE__ */ d.forwardRef(function(t, n) {
  return /* @__PURE__ */ d.createElement("button", je({}, t, {
    type: "button",
    ref: n,
    tabIndex: -1,
    style: Tt
  }));
});
function Po(e) {
  const {
    context: t,
    children: n,
    disabled: r = !1,
    order: o = ["content"],
    guards: i = !0,
    initialFocus: s = 0,
    returnFocus: u = !0,
    modal: c = !0,
    visuallyHiddenDismiss: a = !1,
    closeOnFocusOut: m = !0
  } = e, {
    open: f,
    refs: p,
    nodeId: l,
    onOpenChange: g,
    events: h,
    dataRef: w,
    elements: {
      domReference: v,
      floating: b
    }
  } = t, P = xo() ? i : !0, T = Ee(o), C = Ee(s), S = Ee(u), y = Qe(), E = Pn(), B = typeof s == "number" && s < 0, H = d.useRef(null), D = d.useRef(null), N = d.useRef(!1), R = d.useRef(null), O = d.useRef(!1), k = E != null, M = v && v.getAttribute("role") === "combobox" && Jt(v) && B, W = d.useCallback(function(I) {
    return I === void 0 && (I = b), I ? xt(I, Rt()) : [];
  }, [b]), x = d.useCallback((I) => {
    const $ = W(I);
    return T.current.map((L) => v && L === "reference" ? v : b && L === "floating" ? b : $).filter(Boolean).flat();
  }, [v, b, T, W]);
  d.useEffect(() => {
    if (r || !c) return;
    function I(L) {
      if (L.key === "Tab") {
        j(b, le(z(b))) && W().length === 0 && !M && Ze(L);
        const V = x(), G = Zt(L);
        T.current[0] === "reference" && G === v && (Ze(L), L.shiftKey ? ce(V[V.length - 1]) : ce(V[1])), T.current[1] === "floating" && G === b && L.shiftKey && (Ze(L), ce(V[0]));
      }
    }
    const $ = z(b);
    return $.addEventListener("keydown", I), () => {
      $.removeEventListener("keydown", I);
    };
  }, [r, v, b, c, T, p, M, W, x]), d.useEffect(() => {
    if (r || !m) return;
    function I() {
      O.current = !0, setTimeout(() => {
        O.current = !1;
      });
    }
    function $(L) {
      const V = L.relatedTarget;
      queueMicrotask(() => {
        const G = !(j(v, V) || j(b, V) || j(V, b) || j(E == null ? void 0 : E.portalNode, V) || V != null && V.hasAttribute(me("focus-guard")) || y && (Re(y.nodesRef.current, l).find((U) => {
          var J, ue;
          return j((J = U.context) == null ? void 0 : J.elements.floating, V) || j((ue = U.context) == null ? void 0 : ue.elements.domReference, V);
        }) || wo(y.nodesRef.current, l).find((U) => {
          var J, ue;
          return ((J = U.context) == null ? void 0 : J.elements.floating) === V || ((ue = U.context) == null ? void 0 : ue.elements.domReference) === V;
        })));
        V && G && !O.current && // Fix React 18 Strict Mode returnFocus due to double rendering.
        V !== R.current && (N.current = !0, g(!1, L));
      });
    }
    if (b && ve(v))
      return v.addEventListener("focusout", $), v.addEventListener("pointerdown", I), !c && b.addEventListener("focusout", $), () => {
        v.removeEventListener("focusout", $), v.removeEventListener("pointerdown", I), !c && b.removeEventListener("focusout", $);
      };
  }, [r, v, b, c, l, y, E, g, m]), d.useEffect(() => {
    var I;
    if (r) return;
    const $ = Array.from((E == null || (I = E.portalNode) == null ? void 0 : I.querySelectorAll("[" + me("portal") + "]")) || []);
    if (b) {
      const L = [b, ...$, H.current, D.current, T.current.includes("reference") || M ? v : null].filter((G) => G != null), V = c ? Bt(L, P, !P) : Bt(L);
      return () => {
        V();
      };
    }
  }, [r, v, b, c, T, E, M, P]), X(() => {
    if (r || !b) return;
    const I = z(b), $ = le(I);
    queueMicrotask(() => {
      const L = x(b), V = C.current, G = (typeof V == "number" ? L[V] : V.current) || b, U = j(b, $);
      !B && !U && f && ce(G, {
        preventScroll: G === b
      });
    });
  }, [r, f, b, B, x, C]), X(() => {
    if (r || !b) return;
    let I = !1;
    const $ = z(b), L = le($), V = w.current;
    R.current = L;
    function G(U) {
      if (U.type === "escapeKey" && p.domReference.current && (R.current = p.domReference.current), ["referencePress", "escapeKey"].includes(U.type))
        return;
      const J = U.data.returnFocus;
      typeof J == "object" ? (N.current = !1, I = J.preventScroll) : N.current = !J;
    }
    return h.on("dismiss", G), () => {
      h.off("dismiss", G);
      const U = le($);
      (j(b, U) || y && Re(y.nodesRef.current, l).some((ue) => {
        var Ct;
        return j((Ct = ue.context) == null ? void 0 : Ct.elements.floating, U);
      }) || V.openEvent && ["click", "mousedown"].includes(V.openEvent.type)) && p.domReference.current && (R.current = p.domReference.current), // eslint-disable-next-line react-hooks/exhaustive-deps
      S.current && ve(R.current) && !N.current && ce(R.current, {
        // When dismissing nested floating elements, by the time the rAF has
        // executed, the menus will all have been unmounted. When they try
        // to get focused, the calls get ignored — leaving the root
        // reference focused as desired.
        cancelPrevious: !1,
        preventScroll: I
      });
    };
  }, [r, b, S, w, p, h, y, l]), X(() => {
    if (!(r || !E))
      return E.setFocusManagerState({
        modal: c,
        closeOnFocusOut: m,
        open: f,
        onOpenChange: g,
        refs: p
      }), () => {
        E.setFocusManagerState(null);
      };
  }, [r, E, c, f, g, p, m]), X(() => {
    if (!r && b && typeof MutationObserver == "function" && !B) {
      const I = () => {
        const L = b.getAttribute("tabindex");
        T.current.includes("floating") || le(z(b)) !== p.domReference.current && W().length === 0 ? L !== "0" && b.setAttribute("tabindex", "0") : L !== "-1" && b.setAttribute("tabindex", "-1");
      };
      I();
      const $ = new MutationObserver(I);
      return $.observe(b, {
        childList: !0,
        subtree: !0,
        attributes: !0
      }), () => {
        $.disconnect();
      };
    }
  }, [r, b, p, T, W, B]);
  function F(I) {
    return r || !a || !c ? null : /* @__PURE__ */ d.createElement(Ao, {
      ref: I === "start" ? H : D,
      onClick: ($) => g(!1, $.nativeEvent)
    }, typeof a == "string" ? a : "Dismiss");
  }
  const _ = !r && P && !M && (k || c);
  return /* @__PURE__ */ d.createElement(d.Fragment, null, _ && /* @__PURE__ */ d.createElement(ze, {
    "data-type": "inside",
    ref: E == null ? void 0 : E.beforeInsideRef,
    onFocus: (I) => {
      if (c) {
        const L = x();
        ce(o[0] === "reference" ? L[0] : L[L.length - 1]);
      } else if (E != null && E.preserveTabOrder && E.portalNode)
        if (N.current = !1, Te(I, E.portalNode)) {
          const L = Cn() || v;
          L == null || L.focus();
        } else {
          var $;
          ($ = E.beforeOutsideRef.current) == null || $.focus();
        }
    }
  }), !M && F("start"), n, F("end"), _ && /* @__PURE__ */ d.createElement(ze, {
    "data-type": "inside",
    ref: E == null ? void 0 : E.afterInsideRef,
    onFocus: (I) => {
      if (c)
        ce(x()[0]);
      else if (E != null && E.preserveTabOrder && E.portalNode)
        if (m && (N.current = !0), Te(I, E.portalNode)) {
          const L = Sn() || v;
          L == null || L.focus();
        } else {
          var $;
          ($ = E.afterOutsideRef.current) == null || $.focus();
        }
    }
  }));
}
function Wt(e) {
  return ve(e.target) && e.target.tagName === "BUTTON";
}
function Vt(e) {
  return Jt(e);
}
function ko(e, t) {
  t === void 0 && (t = {});
  const {
    open: n,
    onOpenChange: r,
    dataRef: o,
    elements: {
      domReference: i
    }
  } = e, {
    enabled: s = !0,
    event: u = "click",
    toggle: c = !0,
    ignoreMouse: a = !1,
    keyboardHandlers: m = !0
  } = t, f = d.useRef(), p = d.useRef(!1);
  return d.useMemo(() => s ? {
    reference: {
      onPointerDown(l) {
        f.current = l.pointerType;
      },
      onMouseDown(l) {
        l.button === 0 && (Ne(f.current, !0) && a || u !== "click" && (n && c && (!o.current.openEvent || o.current.openEvent.type === "mousedown") ? r(!1, l.nativeEvent) : (l.preventDefault(), r(!0, l.nativeEvent))));
      },
      onClick(l) {
        if (u === "mousedown" && f.current) {
          f.current = void 0;
          return;
        }
        Ne(f.current, !0) && a || (n && c && (!o.current.openEvent || o.current.openEvent.type === "click") ? r(!1, l.nativeEvent) : r(!0, l.nativeEvent));
      },
      onKeyDown(l) {
        f.current = void 0, !(l.defaultPrevented || !m || Wt(l)) && (l.key === " " && !Vt(i) && (l.preventDefault(), p.current = !0), l.key === "Enter" && r(!(n && c), l.nativeEvent));
      },
      onKeyUp(l) {
        l.defaultPrevented || !m || Wt(l) || Vt(i) || l.key === " " && p.current && (p.current = !1, r(!(n && c), l.nativeEvent));
      }
    }
  } : {}, [s, o, u, a, m, i, c, n, r]);
}
const Do = d.useInsertionEffect, Fo = Do || ((e) => e());
function Le(e) {
  const t = d.useRef(() => {
    if (process.env.NODE_ENV !== "production")
      throw new Error("Cannot call an event handler while rendering.");
  });
  return Fo(() => {
    t.current = e;
  }), d.useCallback(function() {
    for (var n = arguments.length, r = new Array(n), o = 0; o < n; o++)
      r[o] = arguments[o];
    return t.current == null ? void 0 : t.current(...r);
  }, []);
}
const Io = {
  pointerdown: "onPointerDown",
  mousedown: "onMouseDown",
  click: "onClick"
}, Mo = {
  pointerdown: "onPointerDownCapture",
  mousedown: "onMouseDownCapture",
  click: "onClickCapture"
}, Lo = (e) => {
  var t, n;
  return {
    escapeKeyBubbles: typeof e == "boolean" ? e : (t = e == null ? void 0 : e.escapeKey) != null ? t : !1,
    outsidePressBubbles: typeof e == "boolean" ? e : (n = e == null ? void 0 : e.outsidePress) != null ? n : !0
  };
};
function kn(e, t) {
  t === void 0 && (t = {});
  const {
    open: n,
    onOpenChange: r,
    events: o,
    nodeId: i,
    elements: {
      reference: s,
      domReference: u,
      floating: c
    },
    dataRef: a
  } = e, {
    enabled: m = !0,
    escapeKey: f = !0,
    outsidePress: p = !0,
    outsidePressEvent: l = "pointerdown",
    referencePress: g = !1,
    referencePressEvent: h = "pointerdown",
    ancestorScroll: w = !1,
    bubbles: v
  } = t, b = Qe(), P = En() != null, T = Le(typeof p == "function" ? p : () => !1), C = typeof p == "function" ? T : p, S = d.useRef(!1), {
    escapeKeyBubbles: y,
    outsidePressBubbles: E
  } = Lo(v), B = Le((D) => {
    if (!n || !m || !f || D.key !== "Escape")
      return;
    const N = b ? Re(b.nodesRef.current, i) : [];
    if (!y && (D.stopPropagation(), N.length > 0)) {
      let R = !0;
      if (N.forEach((O) => {
        var k;
        if ((k = O.context) != null && k.open && !O.context.dataRef.current.__escapeKeyBubbles) {
          R = !1;
          return;
        }
      }), !R)
        return;
    }
    o.emit("dismiss", {
      type: "escapeKey",
      data: {
        returnFocus: {
          preventScroll: !1
        }
      }
    }), r(!1, cr(D) ? D.nativeEvent : D);
  }), H = Le((D) => {
    const N = S.current;
    if (S.current = !1, N || typeof C == "function" && !C(D))
      return;
    const R = Zt(D), O = "[" + me("inert") + "]", k = z(c).querySelectorAll(O);
    let M = q(R) ? R : null;
    for (; M && !er(M); ) {
      const F = nr(M);
      if (F === z(c).body || !q(F))
        break;
      M = F;
    }
    if (k.length && q(R) && !lr(R) && // Clicked on a direct ancestor (e.g. FloatingOverlay).
    !j(R, c) && // If the target root element contains none of the markers, then the
    // element was injected after the floating element rendered.
    Array.from(k).every((F) => !j(M, F)))
      return;
    if (ve(R) && c) {
      const F = R.clientWidth > 0 && R.scrollWidth > R.clientWidth, _ = R.clientHeight > 0 && R.scrollHeight > R.clientHeight;
      let I = _ && D.offsetX > R.clientWidth;
      if (_ && tr(R).direction === "rtl" && (I = D.offsetX <= R.offsetWidth - R.clientWidth), I || F && D.offsetY > R.clientHeight)
        return;
    }
    const W = b && Re(b.nodesRef.current, i).some((F) => {
      var _;
      return Ie(D, (_ = F.context) == null ? void 0 : _.elements.floating);
    });
    if (Ie(D, c) || Ie(D, u) || W)
      return;
    const x = b ? Re(b.nodesRef.current, i) : [];
    if (x.length > 0) {
      let F = !0;
      if (x.forEach((_) => {
        var I;
        if ((I = _.context) != null && I.open && !_.context.dataRef.current.__outsidePressBubbles) {
          F = !1;
          return;
        }
      }), !F)
        return;
    }
    o.emit("dismiss", {
      type: "outsidePress",
      data: {
        returnFocus: P ? {
          preventScroll: !0
        } : ir(D) || sr(D)
      }
    }), r(!1, D);
  });
  return d.useEffect(() => {
    if (!n || !m)
      return;
    a.current.__escapeKeyBubbles = y, a.current.__outsidePressBubbles = E;
    function D(O) {
      r(!1, O);
    }
    const N = z(c);
    f && N.addEventListener("keydown", B), C && N.addEventListener(l, H);
    let R = [];
    return w && (q(u) && (R = oe(u)), q(c) && (R = R.concat(oe(c))), !q(s) && s && s.contextElement && (R = R.concat(oe(s.contextElement)))), R = R.filter((O) => {
      var k;
      return O !== ((k = N.defaultView) == null ? void 0 : k.visualViewport);
    }), R.forEach((O) => {
      O.addEventListener("scroll", D, {
        passive: !0
      });
    }), () => {
      f && N.removeEventListener("keydown", B), C && N.removeEventListener(l, H), R.forEach((O) => {
        O.removeEventListener("scroll", D);
      });
    };
  }, [a, c, u, s, f, C, l, n, r, w, m, y, E, B, H]), d.useEffect(() => {
    S.current = !1;
  }, [C, l]), d.useMemo(() => m ? {
    reference: {
      onKeyDown: B,
      [Io[h]]: (D) => {
        g && (o.emit("dismiss", {
          type: "referencePress",
          data: {
            returnFocus: !1
          }
        }), r(!1, D.nativeEvent));
      }
    },
    floating: {
      onKeyDown: B,
      [Mo[l]]: () => {
        S.current = !0;
      }
    }
  } : {}, [m, o, g, l, h, r, B]);
}
let mt;
process.env.NODE_ENV !== "production" && (mt = /* @__PURE__ */ new Set());
function Dn(e) {
  var t;
  e === void 0 && (e = {});
  const {
    open: n = !1,
    onOpenChange: r,
    nodeId: o
  } = e;
  if (process.env.NODE_ENV !== "production") {
    var i;
    const y = "Floating UI: Cannot pass a virtual element to the `elements.reference` option, as it must be a real DOM element. Use `refs.setPositionReference` instead.";
    if ((i = e.elements) != null && i.reference && !q(e.elements.reference)) {
      var s;
      if (!((s = mt) != null && s.has(y))) {
        var u;
        (u = mt) == null || u.add(y), console.error(y);
      }
    }
  }
  const [c, a] = d.useState(null), m = ((t = e.elements) == null ? void 0 : t.reference) || c, f = Ur(e), p = Qe(), l = Le((y, E) => {
    y && (h.current.openEvent = E), r == null || r(y, E);
  }), g = d.useRef(null), h = d.useRef({}), w = d.useState(() => ho())[0], v = Et(), b = d.useCallback((y) => {
    const E = q(y) ? {
      getBoundingClientRect: () => y.getBoundingClientRect(),
      contextElement: y
    } : y;
    f.refs.setReference(E);
  }, [f.refs]), P = d.useCallback((y) => {
    (q(y) || y === null) && (g.current = y, a(y)), (q(f.refs.reference.current) || f.refs.reference.current === null || // Don't allow setting virtual elements using the old technique back to
    // `null` to support `positionReference` + an unstable `reference`
    // callback ref.
    y !== null && !q(y)) && f.refs.setReference(y);
  }, [f.refs]), T = d.useMemo(() => ({
    ...f.refs,
    setReference: P,
    setPositionReference: b,
    domReference: g
  }), [f.refs, P, b]), C = d.useMemo(() => ({
    ...f.elements,
    domReference: m
  }), [f.elements, m]), S = d.useMemo(() => ({
    ...f,
    refs: T,
    elements: C,
    dataRef: h,
    nodeId: o,
    floatingId: v,
    events: w,
    open: n,
    onOpenChange: l
  }), [f, o, v, w, n, l, T, C]);
  return X(() => {
    const y = p == null ? void 0 : p.nodesRef.current.find((E) => E.id === o);
    y && (y.context = S);
  }), d.useMemo(() => ({
    ...f,
    context: S,
    refs: T,
    elements: C
  }), [f, T, C, S]);
}
function No(e, t) {
  t === void 0 && (t = {});
  const {
    open: n,
    onOpenChange: r,
    dataRef: o,
    events: i,
    refs: s,
    elements: {
      floating: u,
      domReference: c
    }
  } = e, {
    enabled: a = !0,
    keyboardOnly: m = !0
  } = t, f = d.useRef(""), p = d.useRef(!1), l = d.useRef();
  return d.useEffect(() => {
    if (!a)
      return;
    const h = z(u).defaultView || window;
    function w() {
      !n && ve(c) && c === le(z(c)) && (p.current = !0);
    }
    return h.addEventListener("blur", w), () => {
      h.removeEventListener("blur", w);
    };
  }, [u, c, n, a]), d.useEffect(() => {
    if (!a)
      return;
    function g(h) {
      (h.type === "referencePress" || h.type === "escapeKey") && (p.current = !0);
    }
    return i.on("dismiss", g), () => {
      i.off("dismiss", g);
    };
  }, [i, a]), d.useEffect(() => () => {
    clearTimeout(l.current);
  }, []), d.useMemo(() => a ? {
    reference: {
      onPointerDown(g) {
        let {
          pointerType: h
        } = g;
        f.current = h, p.current = !!(h && m);
      },
      onMouseLeave() {
        p.current = !1;
      },
      onFocus(g) {
        var h;
        p.current || g.type === "focus" && ((h = o.current.openEvent) == null ? void 0 : h.type) === "mousedown" && Ie(o.current.openEvent, c) || r(!0, g.nativeEvent);
      },
      onBlur(g) {
        p.current = !1;
        const h = g.relatedTarget, w = q(h) && h.hasAttribute(me("focus-guard")) && h.getAttribute("data-type") === "outside";
        l.current = setTimeout(() => {
          j(s.floating.current, h) || j(c, h) || w || r(!1, g.nativeEvent);
        });
      }
    }
  } : {}, [a, m, c, s, o, r]);
}
function ot(e, t, n) {
  const r = /* @__PURE__ */ new Map();
  return {
    ...n === "floating" && {
      tabIndex: -1
    },
    ...e,
    ...t.map((o) => o ? o[n] : null).concat(e).reduce((o, i) => (i && Object.entries(i).forEach((s) => {
      let [u, c] = s;
      if (u.indexOf("on") === 0) {
        if (r.has(u) || r.set(u, []), typeof c == "function") {
          var a;
          (a = r.get(u)) == null || a.push(c), o[u] = function() {
            for (var m, f = arguments.length, p = new Array(f), l = 0; l < f; l++)
              p[l] = arguments[l];
            return (m = r.get(u)) == null ? void 0 : m.map((g) => g(...p)).find((g) => g !== void 0);
          };
        }
      } else
        o[u] = c;
    }), o), {})
  };
}
function Fn(e) {
  e === void 0 && (e = []);
  const t = e, n = d.useCallback(
    (i) => ot(i, e, "reference"),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    t
  ), r = d.useCallback(
    (i) => ot(i, e, "floating"),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    t
  ), o = d.useCallback(
    (i) => ot(i, e, "item"),
    // Granularly check for `item` changes, because the `getItemProps` getter
    // should be as referentially stable as possible since it may be passed as
    // a prop to many components. All `item` key values must therefore be
    // memoized.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    e.map((i) => i == null ? void 0 : i.item)
  );
  return d.useMemo(() => ({
    getReferenceProps: n,
    getFloatingProps: r,
    getItemProps: o
  }), [n, r, o]);
}
function In(e, t) {
  t === void 0 && (t = {});
  const {
    open: n,
    floatingId: r
  } = e, {
    enabled: o = !0,
    role: i = "dialog"
  } = t, s = Et();
  return d.useMemo(() => {
    const u = {
      id: r,
      role: i
    };
    return o ? i === "tooltip" ? {
      reference: {
        "aria-describedby": n ? r : void 0
      },
      floating: u
    } : {
      reference: {
        "aria-expanded": n ? "true" : "false",
        "aria-haspopup": i === "alertdialog" ? "dialog" : i,
        "aria-controls": n ? r : void 0,
        ...i === "listbox" && {
          role: "combobox"
        },
        ...i === "menu" && {
          id: s
        }
      },
      floating: {
        ...u,
        ...i === "menu" && {
          "aria-labelledby": s
        }
      }
    } : {};
  }, [o, i, n, r, s]);
}
const Mn = Gn(null);
function Ln() {
  const e = Xn(Mn);
  if (e === null)
    throw new Error("Popover components must be wrapped in <Popover />");
  return e;
}
function Bo({
  initialOpen: e = !1,
  modal: t,
  onOpenChange: n,
  open: r,
  placement: o = "bottom"
} = {}) {
  const [i, s] = re(e), [u, c] = re(), [a, m] = re(), f = r ?? i, p = n ?? s, l = Dn({
    middleware: [
      gn(5),
      bn({
        crossAxis: o.includes("-"),
        fallbackAxisSideDirection: "end",
        padding: 5
      }),
      hn({ padding: 5 })
    ],
    onOpenChange: p,
    open: f,
    placement: o,
    whileElementsMounted: mn
  }), { context: g } = l, h = ko(g, {
    enabled: r === null
  }), w = kn(g), v = In(g), b = Fn([h, w, v]);
  return Ut(
    () => ({
      open: f,
      setOpen: p,
      ...b,
      ...l,
      descriptionId: a,
      labelId: u,
      modal: t,
      setDescriptionId: m,
      setLabelId: c
    }),
    [f, p, b, l, t, u, a]
  );
}
function Nn({
  children: e,
  modal: t = !1,
  ...n
}) {
  const r = Bo({ modal: t, ...n });
  return /* @__PURE__ */ A(Mn.Provider, { value: r, children: e });
}
Nn.Trigger = ht(
  function({ asChild: t = !1, children: n, ...r }, o) {
    const i = Ln(), s = n == null ? void 0 : n.ref, u = Xe([i.refs.setReference, o, s]);
    return t && ut.isValidElement(n) ? ut.cloneElement(
      n,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      i.getReferenceProps({
        ref: u,
        ...r,
        ...n.props,
        "data-state": i.open ? "open" : "closed"
      })
    ) : /* @__PURE__ */ A(
      "button",
      {
        "data-state": i.open ? "open" : "closed",
        ref: u,
        type: "button",
        ...i.getReferenceProps(r),
        children: n
      }
    );
  }
);
Nn.Content = ht(
  // eslint-disable-next-line react/prop-types
  function({ style: t, ...n }, r) {
    const { context: o, ...i } = Ln(), s = Xe([i.refs.setFloating, r]);
    return o.open ? /* @__PURE__ */ A(An, { children: /* @__PURE__ */ A(Po, { context: o, modal: i.modal, children: /* @__PURE__ */ A(
      "div",
      {
        "aria-describedby": i.descriptionId,
        "aria-labelledby": i.labelId,
        ref: s,
        style: { ...i.floatingStyles, ...t },
        ...i.getFloatingProps(n),
        children: n.children
      }
    ) }) }) : null;
  }
);
function Bn({
  onChange: e,
  options: t,
  value: n,
  variant: r,
  ...o
}) {
  const i = window.matchMedia("(max-width: 431px"), u = i.matches ? "vertical" : o.direction ?? "vertical", [c, a] = re(u);
  return Se(() => {
    const m = (p) => {
      a(p ? "vertical" : "horizontal");
    }, f = ({ matches: p }) => {
      m(p);
    };
    return i.addEventListener("change", f), m(i.matches), () => {
      i.removeEventListener("change", f);
    };
  }, [i]), /* @__PURE__ */ A("div", { className: ie("radio-button-group", c, r), children: t.map((m) => /* @__PURE__ */ A(
    Bn.Option,
    {
      ...m,
      currentValue: n,
      onChange: e
    },
    m.id
  )) });
}
Bn.Option = function({
  currentValue: t,
  id: n,
  isDisabled: r,
  label: o,
  name: i,
  onChange: s,
  value: u
}) {
  return /* @__PURE__ */ ae(jt, { children: [
    /* @__PURE__ */ A(
      "input",
      {
        checked: t === u,
        disabled: r,
        id: n,
        name: i,
        type: "radio",
        value: u,
        onChange: s
      }
    ),
    /* @__PURE__ */ ae("label", { htmlFor: n, children: [
      /* @__PURE__ */ A("div", { className: "checkbox-container", children: /* @__PURE__ */ A(zn, { isChecked: t === u }) }),
      o
    ] })
  ] });
};
var Fe = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function $o(e) {
  return e.__esModule && Object.prototype.hasOwnProperty.call(e, "default") ? e.default : e;
}
var Wo = "Expected a function", _t = NaN, Vo = "[object Symbol]", _o = /^\s+|\s+$/g, Ho = /^[-+]0x[0-9a-f]+$/i, jo = /^0b[01]+$/i, zo = /^0o[0-7]+$/i, Ko = parseInt, qo = typeof Fe == "object" && Fe && Fe.Object === Object && Fe, Uo = typeof self == "object" && self && self.Object === Object && self, Yo = qo || Uo || Function("return this")(), Go = Object.prototype, Xo = Go.toString, Qo = Math.max, Zo = Math.min, it = function() {
  return Yo.Date.now();
};
function Jo(e, t, n) {
  var r, o, i, s, u, c, a = 0, m = !1, f = !1, p = !0;
  if (typeof e != "function")
    throw new TypeError(Wo);
  t = Ht(t) || 0, pt(n) && (m = !!n.leading, f = "maxWait" in n, i = f ? Qo(Ht(n.maxWait) || 0, t) : i, p = "trailing" in n ? !!n.trailing : p);
  function l(S) {
    var y = r, E = o;
    return r = o = void 0, a = S, s = e.apply(E, y), s;
  }
  function g(S) {
    return a = S, u = setTimeout(v, t), m ? l(S) : s;
  }
  function h(S) {
    var y = S - c, E = S - a, B = t - y;
    return f ? Zo(B, i - E) : B;
  }
  function w(S) {
    var y = S - c, E = S - a;
    return c === void 0 || y >= t || y < 0 || f && E >= i;
  }
  function v() {
    var S = it();
    if (w(S))
      return b(S);
    u = setTimeout(v, h(S));
  }
  function b(S) {
    return u = void 0, p && r ? l(S) : (r = o = void 0, s);
  }
  function P() {
    u !== void 0 && clearTimeout(u), a = 0, r = c = o = u = void 0;
  }
  function T() {
    return u === void 0 ? s : b(it());
  }
  function C() {
    var S = it(), y = w(S);
    if (r = arguments, o = this, c = S, y) {
      if (u === void 0)
        return g(c);
      if (f)
        return u = setTimeout(v, t), l(c);
    }
    return u === void 0 && (u = setTimeout(v, t)), s;
  }
  return C.cancel = P, C.flush = T, C;
}
function pt(e) {
  var t = typeof e;
  return !!e && (t == "object" || t == "function");
}
function ei(e) {
  return !!e && typeof e == "object";
}
function ti(e) {
  return typeof e == "symbol" || ei(e) && Xo.call(e) == Vo;
}
function Ht(e) {
  if (typeof e == "number")
    return e;
  if (ti(e))
    return _t;
  if (pt(e)) {
    var t = typeof e.valueOf == "function" ? e.valueOf() : e;
    e = pt(t) ? t + "" : t;
  }
  if (typeof e != "string")
    return e === 0 ? e : +e;
  e = e.replace(_o, "");
  var n = jo.test(e);
  return n || zo.test(e) ? Ko(e.slice(2), n ? 2 : 8) : Ho.test(e) ? _t : +e;
}
var ni = Jo;
const ri = /* @__PURE__ */ $o(ni), oi = 25, yi = ut.forwardRef(
  ({
    children: e,
    className: t,
    direction: n = "column",
    hideScrollbar: r = !1,
    role: o
  }, i) => {
    const s = gt(null), [u, c] = re(!1), [a, m] = re(!1), f = Yt(() => {
      const l = s == null ? void 0 : s.current;
      if (!l) return !1;
      const g = n.startsWith("row"), h = n.endsWith("reverse"), { offsetHeight: w, offsetWidth: v, scrollHeight: b, scrollLeft: P, scrollTop: T, scrollWidth: C } = l, S = g ? P : T, y = Math.ceil(h ? Math.abs(S * -1) : S), E = g ? C - v : b - w, B = g ? C > v : b > w;
      return c(y === 0 ? !1 : B), y >= E ? m(!1) : m(B), B;
    }, [n, s]), p = Ut(
      () => ri(f, oi),
      [f]
    );
    return Se(() => {
      f();
    }, [e, f]), be(() => (window.addEventListener("resize", p), () => window.removeEventListener("resize", p)), [p]), Qn(
      i,
      () => ({
        checkOverflow: f,
        innerRef: s
      }),
      [f]
    ), /* @__PURE__ */ A(
      "div",
      {
        className: ie(
          "scroll-container",
          `direction-${n}`,
          { "overflowing-end": a },
          { "overflowing-start": u },
          t
        ),
        role: o,
        children: /* @__PURE__ */ A(
          "div",
          {
            className: ie(`direction-${n}`, "scroll-container-content", {
              flex: n === "row",
              "hide-scrollbar": r
            }),
            ref: s,
            onScroll: p,
            children: e
          }
        )
      }
    );
  }
), wi = ht(
  ({
    autoComplete: e,
    autoFocus: t = !1,
    disableSelectOnFocus: n = !1,
    error: r,
    label: o,
    maxLength: i,
    minLength: s,
    name: u,
    onBlur: c,
    onChange: a,
    onKeyDown: m,
    value: f
  }, p) => {
    const l = ai(p);
    return /* @__PURE__ */ ae(jt, { children: [
      /* @__PURE__ */ A("label", { className: "max-w-full font-bold", htmlFor: u, children: o }),
      /* @__PURE__ */ A(
        "input",
        {
          autoComplete: e,
          autoFocus: t,
          className: "w-full max-w-full rounded-md border-2 border-shade-4 p-2 shadow-md focus:border-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500 dark:bg-shade-2",
          id: u,
          maxLength: i,
          minLength: s,
          ref: l,
          value: f,
          onBlur: c,
          onChange: a,
          onFocus: () => {
            var h;
            n || (h = l.current) == null || h.select();
          },
          onKeyDown: m
        }
      ),
      r && /* @__PURE__ */ A("div", { className: "rounded-sm bg-red-500 px-2 py-1 text-white", children: r })
    ] });
  }
);
function ii({
  initialOpen: e = !1,
  onOpenChange: t,
  open: n,
  placement: r = "top"
} = {}) {
  const [o, i] = d.useState(e), s = n ?? o, u = t ?? i, c = Dn({
    middleware: [
      gn(5),
      bn({
        crossAxis: r.includes("-"),
        fallbackAxisSideDirection: "start",
        padding: 5
      }),
      hn({ padding: 5 })
    ],
    onOpenChange: u,
    open: s,
    placement: r,
    whileElementsMounted: mn
  }), { context: a } = c, m = yo(a, {
    enabled: n == null,
    move: !1
  }), f = No(a, {
    enabled: n == null
  }), p = kn(a), l = In(a, { role: "tooltip" }), g = Fn([m, f, p, l]);
  return d.useMemo(
    () => ({
      open: s,
      setOpen: u,
      ...g,
      ...c
    }),
    [s, u, g, c]
  );
}
const $n = d.createContext(null), Wn = () => {
  const e = d.useContext($n);
  if (e == null)
    throw new Error("Tooltip components must be wrapped in <Tooltip />");
  return e;
};
function si({ children: e, ...t }) {
  const n = ii(t);
  return /* @__PURE__ */ A($n.Provider, { value: n, children: e });
}
const ui = d.forwardRef(function({ asChild: t = !1, children: n, ...r }, o) {
  const i = Wn(), s = n.ref, u = Xe([i.refs.setReference, o, s]);
  return t && d.isValidElement(n) ? d.cloneElement(
    n,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    i.getReferenceProps({
      ref: u,
      ...r,
      ...n.props,
      "data-state": i.open ? "open" : "closed"
    })
  ) : /* @__PURE__ */ A(
    "button",
    {
      "data-state": i.open ? "open" : "closed",
      ref: u,
      ...i.getReferenceProps(r),
      children: n
    }
  );
}), ci = d.forwardRef(
  // eslint-disable-next-line react/prop-types
  function({ style: t, ...n }, r) {
    const o = Wn(), i = Xe([o.refs.setFloating, r]);
    return o.open ? /* @__PURE__ */ A(An, { children: /* @__PURE__ */ A(
      "div",
      {
        ref: i,
        style: {
          ...o.floatingStyles,
          ...t,
          zIndex: 1e3
        },
        ...o.getFloatingProps(n)
      }
    ) }) : null;
  }
);
function xi({
  children: e,
  className: t,
  trigger: n = /* @__PURE__ */ A(Kn, { className: "size-4" })
}) {
  return /* @__PURE__ */ ae(si, { children: [
    /* @__PURE__ */ A(ui, { className: t, children: n }),
    /* @__PURE__ */ A(ci, { children: /* @__PURE__ */ A(
      zt,
      {
        className: "rounded-lg border border-shade-4 bg-shade-2 p-3 text-center text-sm leading-relaxed shadow-xl",
        size: "extra-small",
        children: /* @__PURE__ */ A(Kt, { children: e })
      }
    ) })
  ] });
}
function li(e) {
  return {
    current: (e == null ? void 0 : e.currentScores) ?? [],
    previous: (e == null ? void 0 : e.previousScores) ?? []
  };
}
function Ei(e) {
  const { current: t, previous: n } = li(e), r = n.length === 0 ? t : n, [o, i] = re(r);
  return be(() => {
    n.length !== 0 && setTimeout(() => {
      i(t);
    }, 2e3);
  }, [t, n.length]), {
    animatedScores: o
  };
}
function ai(e) {
  const t = gt(null);
  return Se(() => {
    e && (typeof e == "function" ? e(t.current) : e.current = t.current);
  }), t;
}
function Ri() {
  const [e, t] = re(!1), n = Yt(async () => {
    t(!0), await qn(1200), t(!1);
  }, []);
  return { showSuccess: e, showSuccessButtonState: n };
}
export {
  bi as ConfirmPrompt,
  K as Dialog,
  vi as ErrorDialog,
  xi as InfoTooltip,
  Nn as Popover,
  Mn as PopoverContext,
  Bn as RadioButtonGroup,
  yi as ScrollContainer,
  wi as TextInput,
  si as Tooltip,
  ci as TooltipContent,
  ui as TooltipTrigger,
  li as formatScores,
  Ei as useAnimatedScores,
  ai as useForwardedRef,
  Bo as usePopover,
  Ln as usePopoverContext,
  Ri as useSuccessButton
};
