export enum Collections {
  ADMINS = 'admins',
  ANALYTICS = 'analytics',
  ANALYTICS_TOTALS = 'analytics_totals',
  CALLED_BINGOS = 'called_bingos',
  CHECKOUT_SESSIONS = 'checkout_sessions',
  CONFIRMED_BINGOS = 'confirmed_bingos',
  CUSTOM_JOIN_SCREEN_URLS = 'custom_join_screen_urls',
  CUSTOM_PLAN_INVITES = 'custom_plan_invites',
  CUSTOMERS = 'customers',
  DENIED_BINGOS = 'denied_bingos',
  GAMES = 'games',
  INTEGRATIONS = 'integrations',
  LOBBY = 'lobby',
  MESSAGE_QUEUE = 'message_queue',
  PLAYERS = 'players',
  SCOREBOARD = 'scoreboard',
  SUBSCRIPTIONS = 'subscriptions',
  SYSTEM_PLAYLISTS = 'system_playlists',
  USER_TRIVIA_LISTS = 'user_custom_trivia_lists',
  USER_PLAYLISTS = 'user_custom_playlists',
  USERS = 'users',
  VENUES = 'venues',
  WINNER_CONTACT_DETAILS = 'winner_contact_details',
}
