import { TriviaList } from '@repo/types'
import { useMutation, useQueryClient } from 'react-query'

export function useUpdateTriviaList(
  mutationFn: (list: TriviaList) => Promise<{ listId: string }>,
  itemQueryKey: string[],
  itemQueryFn: () => Promise<TriviaList>,
  listQueryKey: string[],
  listQueryFn: () => Promise<TriviaList[]>
) {
  const queryClient = useQueryClient()

  const mutation = useMutation(mutationFn, {
    onError: (err, _, context) => {
      // Rollback on error
      if (!context?.previousTriviaList) return

      queryClient.setQueryData(itemQueryKey, context.previousTriviaList)
    },
    onMutate: async (updatedTriviaList: TriviaList) => {
      await queryClient.cancelQueries(itemQueryKey)

      const previousTriviaList = queryClient.getQueryData<TriviaList>(itemQueryKey)

      // Optimistically update the cache
      queryClient.setQueryData(itemQueryKey, updatedTriviaList)

      return { previousTriviaList }
    },
    onSettled: () => {
      queryClient.invalidateQueries(itemQueryKey)
      queryClient.fetchQuery(itemQueryKey, itemQueryFn)

      queryClient.invalidateQueries(listQueryKey)
      queryClient.fetchQuery(listQueryKey, listQueryFn)
    }
  })

  return mutation
}
