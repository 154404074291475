import { TriviaList } from '@repo/types'
import { z } from 'zod'

import { ValidatedItem, ValidatedTriviaList } from './edit-trivia-list/utils/validate-list'

export const schemaConfig = {
  answer: {
    maxLength: 60,
    minLength: 1
  },
  question: {
    maxLength: 120,
    minLength: 1
  }
} as const

function coerceToString(value: unknown) {
  return value == null ? '' : String(value)
}

export const SpreadsheetItemSchema = z.object({
  answer: z.preprocess(
    coerceToString,
    z.string().min(schemaConfig.answer.minLength, 'Answer is required')
  ),
  question: z.preprocess(
    coerceToString,
    z.string().min(schemaConfig.question.minLength, 'Question is required')
  )
})

export const SpreadsheetSchema = z.array(SpreadsheetItemSchema)

export type SpreadsheetInput = z.infer<typeof SpreadsheetSchema>

export const JsonInputItemSchema = z.object({
  answer: z
    .string()
    .optional()
    .transform(value => value ?? ''),
  question: z
    .string()
    .optional()
    .transform(value => value ?? '')
})

export const TriviaListJsonInputSchema = z.object({
  items: z.array(JsonInputItemSchema)
})

export type TriviaListJsonInput = z.infer<typeof TriviaListJsonInputSchema>

type RemoveValidationPropertiesInputItem = Omit<ValidatedItem, 'validation'> & {
  validation?: unknown
}

type RemoveValidationPropertiesInput = Omit<ValidatedTriviaList, 'validation' | 'items'> & {
  items: RemoveValidationPropertiesInputItem[]
  validation?: unknown
}

export function removeValidationProperties(input: RemoveValidationPropertiesInput): TriviaList {
  const { validation, ...list } = input

  const withoutValidation = list.items.map(item => {
    const { validation, ...rest } = item
    return rest
  })

  return {
    ...list,
    items: withoutValidation
  }
}
