import { TriviaList } from '@repo/types'
import { Button } from '@repo/ui'
import * as Sentry from '@sentry/react'
import { observer } from 'mobx-react-lite'
import { ChangeEvent, useRef } from 'react'

import { useDialogContext } from '@/dialogs/DialogContext'
import { MixpanelService } from '@/mixpanel'

import { ProcessedRows, processImportedRows } from '../edit-trivia-list/utils/deduplication'
import { TriviaListJsonInput, TriviaListJsonInputSchema } from '../schema'

function readFile(file: File): Promise<TriviaListJsonInput> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = event => {
      if (typeof event.target?.result !== 'string') return

      try {
        const input = JSON.parse(event.target.result)

        const adjustedInput = Array.isArray(input) ? { items: input } : input

        const parsed = TriviaListJsonInputSchema.parse(adjustedInput)
        resolve(parsed)
      } catch (error) {
        console.error('Could not parse JSON file:', error)
        Sentry.captureException(error)

        reject(error)
      }
    }

    reader.readAsText(file)
  })
}

type Props = {
  data: TriviaList
  onImport?: () => void
  setImportResult: (result: ProcessedRows) => void
  setShowImportResultDialog: (value: boolean) => void
  updateUserTriviaList: (data: TriviaList) => void
}

export const ImportTriviaListJson = observer(
  ({
    data,
    onImport: onClick,
    setImportResult,
    setShowImportResultDialog,
    updateUserTriviaList
  }: Props) => {
    const { setErrorDialog } = useDialogContext()

    const inputRef = useRef<HTMLInputElement | null>(null)
    const handleClick = () => {
      inputRef.current?.click()
    }

    const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0]

      if (!file || file.type !== 'application/json') {
        setErrorDialog({
          error: 'Please upload a JSON file in the correct format.',
          title: 'Invalid file'
        })
        return
      }

      try {
        const importedData = await readFile(file)

        const { deduplicatedRows, duplicates, similar } = processImportedRows(
          data.items,
          importedData.items
        )

        updateUserTriviaList({
          ...data,
          items: [
            ...data.items,
            ...deduplicatedRows.map(item => ({
              answer: item.answer,
              question: item.question
            }))
          ].map((item, index) => ({ ...item, id: index }))
        })

        setImportResult({ deduplicatedRows, duplicates, similar })
        setShowImportResultDialog(true)

        MixpanelService.importTriviaJson({
          duplicateCount: duplicates.length,
          questionCount: deduplicatedRows.length,
          similarCount: similar.length
        })
      } catch (error) {
        setErrorDialog({
          error:
            'An error occurred while importing the file. Please ensure the format is correct and try again.',
          title: 'Import failed'
        })
        Sentry.captureException(error)
      } finally {
        event.target.value = ''
        if (inputRef.current) {
          inputRef.current.value = ''
        }
        onClick?.()
      }
    }

    return (
      <div>
        <input
          accept='.json'
          className='hidden'
          ref={inputRef}
          type='file'
          onChange={handleFileChange}
        />

        <Button text='Import' onClick={handleClick} />
      </div>
    )
  }
)

const SAMPLE_TRIVIA_JSON = [
  {
    answer: 'Jupiter',
    question: 'What is the largest planet in the solar system?'
  },
  {
    answer: 'Mercury',
    question: 'What is the closest planet to the Sun?'
  }
]

export function DownloadTriviaJsonTemplate() {
  const generateJSONTemplate = () => {
    const jsonContent =
      'data:text/json;charset=utf-8,' +
      encodeURIComponent(JSON.stringify(SAMPLE_TRIVIA_JSON, null, 2))

    const link = document.createElement('a')
    link.setAttribute('href', jsonContent)
    link.setAttribute('download', 'Rockstar Bingo - Sample JSON Template.json')
    document.body.appendChild(link)

    link.click()
  }

  return <Button text='Download JSON Template' variant='anchor' onClick={generateJSONTemplate} />
}
