import { toErrorWithMessage } from '@repo/lib'
import { TriviaList } from '@repo/types'
import * as Sentry from '@sentry/react'
import { useQuery } from 'react-query'

import StoreService from '@/store/StoreService'

export function useQueryUserTriviaList(listId: string) {
  const { triviaListService } = StoreService.getStore()

  const { queryFn, queryKey } = triviaListService.queries.userTriviaList

  return useQuery<TriviaList, Error>(queryKey(listId), () => queryFn(listId), {
    onError: caughtError => {
      const error = toErrorWithMessage(caughtError)
      console.error(error)
      Sentry.captureException(error)
      return error
    },
    refetchOnWindowFocus: false
  })
}
