import { TriviaList } from '@repo/types'

import { createFilename, downloadFile } from '@/utils/export'

export function exportTriviaList(triviaList: TriviaList) {
  const filename = createFilename(triviaList.title)

  const json = JSON.stringify(triviaList)
  downloadFile(json, filename)
}
