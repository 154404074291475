import { observer } from 'mobx-react-lite'
import { useRef, useState } from 'react'
import CSVReader from 'react-csv-reader'
import styled from 'styled-components'

import { Button } from '@/components/Button'

import { SpreadsheetInput, SpreadsheetSchema } from './schema'

const SAMPLE_TRIVIA_TEMPLATE = [
  ['Question', 'Answer'],
  ['What is the largest planet in the solar system?', 'Jupiter']
]

type Props = {
  className?: string
  onImport: (data: SpreadsheetInput) => void
  text?: string
}

export const ImportTriviaSpreadsheet = observer(
  ({ className, onImport, text = 'Import' }: Props) => {
    const inputRef = useRef<HTMLInputElement | null>(null)

    const [, setFileData] = useState<SpreadsheetInput | null>(null)

    const transformHeader = (header: string) => {
      return header.toLowerCase().replace(/\W/g, '_')
    }

    const handleFileLoaded = (data: unknown[]) => {
      try {
        const parsed = SpreadsheetSchema.parse(data)
        setFileData(parsed)
        onImport(parsed)
      } catch (error) {
        console.error(error)
      } finally {
        reset()
      }
    }

    const reset = () => {
      setFileData(null)

      if (inputRef?.current) {
        inputRef.current.value = ''
      }
    }

    return (
      <FileInputContainerStyled className={className}>
        <Button
          text={text}
          onClick={() => {
            inputRef?.current?.click()
          }}
        />

        <CSVReader
          inputId='csvUpload'
          parserOptions={{
            dynamicTyping: true,
            header: true,
            skipEmptyLines: true,
            transformHeader
          }}
          ref={inputRef}
          onError={error => {
            alert(error)
          }}
          onFileLoaded={handleFileLoaded}
        />
      </FileInputContainerStyled>
    )
  }
)

const FileInputContainerStyled = styled.div`
  input[type='file'] {
    display: none;
  }
`

export function DownloadTriviaCSVTemplate() {
  const generateCSVTemplate = () => {
    const rows = SAMPLE_TRIVIA_TEMPLATE

    const csvContent = 'data:text/csv;charset=utf-8,' + rows.map(e => e.join(',')).join('\n')

    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', 'Rockstar Bingo - Sample CSV Template.csv')
    document.body.appendChild(link)

    link.click()
  }

  return <Button text='Download Sample Template' variant='anchor' onClick={generateCSVTemplate} />
}
