import '@/styles/globals.css'

import { observer } from 'mobx-react-lite'
import { HelmetProvider } from 'react-helmet-async'
import { QueryClientProvider } from 'react-query'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { Provider as BalancerProvider } from 'react-wrap-balancer'

import { AdminBrowseGameRoute } from './admin/admin-browse-game'
import { AdminBrowseGamesRoute } from './admin/admin-browse-games'
import { AdminRoute } from './admin/admin-route'
import { AnonymousRoute as AnonymousAuthRoute } from './authentication/anonymous-auth-route'
import { ProtectedRoute } from './authentication/protected-route'
import { WithAuth } from './authentication/with-auth'
import { WithNetworkConnectivity } from './components/WithNetworkConnectivity'
import { DialogContextProvider } from './dialogs/DialogContext'
import { CreateAccountRoute } from './pages/account/create-account'
import { SetupAccountRoute } from './pages/account/setup-account'
import { AuthActionRoute } from './pages/auth/action'
import { LoginRoute } from './pages/auth/login'
import { NoAccountRoute } from './pages/auth/no-account'
import { ResetPasswordRoute } from './pages/auth/reset-password'
import { VerifyPasswordRoute } from './pages/auth/verify-password-reset'
import { GameRoute } from './pages/game/game-routes'
import { JumbotronRoute } from './pages/game/jumbotron/jumbotron'
import { SidekickRoute } from './pages/game/sidekick/sidekick'
import { GameDetailsRoute } from './pages/game-history/game-details'
import { GameHistoryRoute } from './pages/game-history/game-history-screen'
import { HomeRoute } from './pages/home/home'
import { ChoosePlaylistRoute } from './pages/home/music/choose-playlist-route'
import {
  CreatePlaylistRoute,
  CreatePlaylistRouteAdmin
} from './pages/playlists/create-playlist/create-playlist-route'
import { ManagePlaylistsRoute } from './pages/playlists/manage-playlists/manage-playlists-route'
import { ManagePlaylistsRouteAdmin } from './pages/playlists/manage-playlists/manage-playlists-route-admin'
import { EditPlaylistRoute } from './pages/playlists/playlist-editor/edit-playlist-route'
import { EditPlaylistRouteAdmin } from './pages/playlists/playlist-editor/edit-playlist-route-admin'
import {
  ImportJsonPlaylistRoute,
  ImportJsonPlaylistRouteAdmin
} from './pages/playlists/playlist-import/import-json'
import {
  ImportSpotifyPlaylistRoute,
  ImportSpotifyPlaylistRouteAdmin
} from './pages/playlists/playlist-import/import-spotify'
import {
  ImportSpotifyPlaylistLibraryRoute,
  ImportSpotifyPlaylistLibraryRouteAdmin
} from './pages/playlists/playlist-import/import-spotify-library/import-spotify-library-route'
import {
  ImportSpotifyPlaylistUrlRoute,
  ImportSpotifyPlaylistUrlRouteAdmin
} from './pages/playlists/playlist-import/import-spotify-url/import-spotify-url-route'
import {
  ImportSpreadsheetRoute,
  ImportSpreadsheetRouteAdmin
} from './pages/playlists/playlist-import/import-spreadsheet'
import { PlaylistPublic } from './pages/playlists/playlist-public/playlist-public'
import { BrandingSettingsRoute } from './pages/settings/pages/branding-settings-global'
import { JoinScreenSettingsRoute } from './pages/settings/pages/join-screen-settings'
import { VenueCreateRoute } from './pages/settings/pages/venue-create'
import { VenueListRoute } from './pages/settings/pages/venue-list'
import { VenueUpdateRoute } from './pages/settings/pages/venue-update'
import { CreateTriviaListRoute } from './pages/trivia-lists/create-trivia-list/create-trivia-list-route'
import { EditTriviaListRoute } from './pages/trivia-lists/edit-trivia-list/edit-trivia-list-route'
import { ManageTriviaListsRoute } from './pages/trivia-lists/manage-trivia-lists/manage-trivia-lists-route'
import { queryClient } from './queryClient'
import { AuthSpotify } from './store/integrations/spotify/auth/auth-spotify'
import { SpotifyPremiumRequired } from './store/integrations/spotify/auth/spotify-premium-required'
import { ThemeContextProvider } from './theme/ThemeContext'

export default observer(function App() {
  return (
    <HelmetProvider>
      <BalancerProvider>
        <QueryClientProvider client={queryClient}>
          <ThemeContextProvider>
            <DialogContextProvider>
              <Router>
                <WithNetworkConnectivity>
                  <WithAuth>
                    <Switch>
                      <Route component={AuthSpotify} path='/auth-spotify' />

                      <Route component={CreateAccountRoute} path='/create-account' />

                      <Route component={LoginRoute} path='/login' />

                      <Route component={SpotifyPremiumRequired} path='/spotify-premium-required' />

                      <Route component={NoAccountRoute} path='/no-account' />

                      <Route component={ResetPasswordRoute} path='/reset-password' />

                      <Route component={VerifyPasswordRoute} path='/verify-password-reset' />

                      <Route component={AuthActionRoute} path='/action' />

                      <AnonymousAuthRoute
                        component={SidekickRoute}
                        path='/:hostIdOrCustomUrl/sidekick'
                      />

                      <AnonymousAuthRoute
                        component={JumbotronRoute}
                        path='/:hostIdOrCustomUrl/jumbotron'
                      />

                      <AnonymousAuthRoute
                        component={PlaylistPublic}
                        path='/:hostIdOrCustomUrl/playlist/:playlistId'
                      />

                      <ProtectedRoute
                        component={SetupAccountRoute}
                        path='/setup-account/:checkoutCancelled?'
                        shouldRedirectToNoAccount={false}
                        exact
                      />

                      <ProtectedRoute component={BrandingSettingsRoute} path='/settings/branding' />

                      <ProtectedRoute component={VenueCreateRoute} path='/settings/venues/create' />

                      <ProtectedRoute
                        component={VenueUpdateRoute}
                        path='/settings/venues/:venueId'
                      />

                      <ProtectedRoute component={VenueListRoute} path='/settings/venues' />

                      <ProtectedRoute
                        component={JoinScreenSettingsRoute}
                        path='/settings/join-screen'
                      />

                      <ProtectedRoute
                        component={GameDetailsRoute}
                        path='/game-history/:gameId'
                        exact
                      />

                      <ProtectedRoute component={GameHistoryRoute} path='/game-history' />

                      <ProtectedRoute component={GameRoute} path='/game' />

                      <ProtectedRoute component={ManagePlaylistsRoute} path='/playlists' exact />

                      <ProtectedRoute
                        component={CreatePlaylistRoute}
                        path='/playlists/create'
                        exact
                      />

                      <ProtectedRoute
                        component={CreateTriviaListRoute}
                        path='/trivia-lists/create'
                        exact
                      />

                      <ProtectedRoute
                        component={EditTriviaListRoute}
                        path='/trivia-lists/edit/:listId'
                        exact
                      />

                      <ProtectedRoute
                        component={ImportJsonPlaylistRoute}
                        path='/playlists/create/import-json'
                        exact
                      />

                      <ProtectedRoute
                        component={ImportSpotifyPlaylistRoute}
                        path='/playlists/create/import-spotify'
                        exact
                      />

                      <ProtectedRoute
                        component={ImportSpotifyPlaylistLibraryRoute}
                        path='/playlists/create/import-spotify/library'
                        exact
                      />

                      <ProtectedRoute
                        component={ImportSpotifyPlaylistUrlRoute}
                        path='/playlists/create/import-spotify/url'
                        exact
                      />

                      <ProtectedRoute
                        component={ImportSpreadsheetRoute}
                        path='/playlists/create/import-spreadsheet'
                        exact
                      />

                      <ProtectedRoute
                        component={EditPlaylistRoute}
                        path='/playlists/edit/:playlistId'
                        exact
                      />

                      <AdminRoute
                        component={CreatePlaylistRouteAdmin}
                        path='/admin/playlists/create'
                        exact
                      />

                      <AdminRoute
                        component={ManagePlaylistsRouteAdmin}
                        path={['/admin', '/admin/playlists']}
                        exact
                      />

                      <AdminRoute
                        component={ImportJsonPlaylistRouteAdmin}
                        path='/admin/playlists/create/import-json'
                        exact
                      />

                      <AdminRoute
                        component={ImportSpotifyPlaylistRouteAdmin}
                        path='/admin/playlists/create/import-spotify'
                        exact
                      />

                      <AdminRoute
                        component={ImportSpotifyPlaylistLibraryRouteAdmin}
                        path='/admin/playlists/create/import-spotify/library'
                        exact
                      />

                      <AdminRoute
                        component={ImportSpotifyPlaylistUrlRouteAdmin}
                        path='/admin/playlists/create/import-spotify/url'
                        exact
                      />

                      <AdminRoute
                        component={ImportSpreadsheetRouteAdmin}
                        path='/admin/playlists/create/import-spreadsheet'
                        exact
                      />

                      <AdminRoute
                        component={EditPlaylistRouteAdmin}
                        path='/admin/playlists/edit/:playlistId'
                        exact
                      />

                      <AdminRoute component={AdminBrowseGamesRoute} path='/admin/games' exact />

                      <AdminRoute
                        component={AdminBrowseGameRoute}
                        path='/admin/games/:gameId'
                        exact
                      />

                      <ProtectedRoute component={ChoosePlaylistRoute} path='/music' />

                      <ProtectedRoute component={ManageTriviaListsRoute} path='/trivia' />

                      <ProtectedRoute component={HomeRoute} path='/' />
                    </Switch>
                  </WithAuth>
                </WithNetworkConnectivity>
              </Router>
            </DialogContextProvider>
          </ThemeContextProvider>
        </QueryClientProvider>
      </BalancerProvider>
    </HelmetProvider>
  )
})
