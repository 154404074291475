import { Badge, Button, Checkbox, Container, Description, Label, merge, Panel } from '@repo/ui'
import { ScrollContainer } from '@repo/ui/client'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  RowData,
  useReactTable
} from '@tanstack/react-table'
import { forwardRef, useEffect, useMemo, useState } from 'react'

import { ExternalLink } from '@/components/ExternalLink'
import { TipBadge } from '@/components/TipBadge'
import { WarningBadge } from '@/components/WarningBadge'

import { Filter } from './Filter'
import { InputCell } from './InputCell'
import { useSkipper } from './useSkipper'
import { schemaConfig } from '../../schema'
import { TRIVIA_KNOWLEDGE_BASE_URL } from '../edit-trivia-list'
import { ValidatedItem, ValidatedTriviaList } from '../utils/validate-list'

export const SCROLL_INTO_VIEW_OPTIONS = { behavior: 'smooth', block: 'nearest' } as const

export type Item = ValidatedItem
export type Cell = { columnId: string; rowIndex: number }

declare module '@tanstack/react-table' {
  interface TableMeta<TData extends RowData> {
    addNewItem?: () => void
    focusCell?: (cell: Cell) => void
    focusNextCell?: (currentCell: Cell) => void
    focusPreviousCell?: (currentCell: Cell) => void
    focusedCell?: Cell
    onBlurCell?: (cell: Cell) => void
    onFocusCell?: (cell: Cell) => void
    updateData: (rowIndex: number, columnId: string, value: unknown) => void
  }

  interface Table<TData extends RowData> {
    id: string
  }

  interface ColumnMeta<TData extends RowData, TValue> {
    className: string
    maxLength?: number
    placeholder?: string
  }

  interface TableOptions<TData extends RowData> {
    id?: string
  }
}

export type FilterValue = 'duplicates' | 'incomplete' | 'invalid' | 'similar' | 'too-long' | ''

type TriviaListTableProps = {
  filter: FilterValue
  focusedCell?: Cell
  initialValues: Item[]
  onAddItem?: () => void
  onBlurCell?: (cell: Cell) => void
  onFocusCell?: (cell: Cell) => void
  onSelectRows: (rows: string[]) => void
  onUpdate?: (data: Item[]) => void
  selectedRows: string[]
  setFilter: (filter: FilterValue) => void
  validation: ValidatedTriviaList['validation']
}

const pluralize = (count: number, singular: string) => (count === 1 ? singular : singular + 's')

const FILTER_MESSAGES = {
  duplicates: (count: number) => `Showing ${count} duplicate ${pluralize(count, 'item')}`,
  incomplete: (count: number) => `Showing ${count} incomplete ${pluralize(count, 'item')}`,
  invalid: (count: number) => `Showing ${count} invalid ${pluralize(count, 'item')}`,
  similar: (count: number) => `Showing ${count} similar ${pluralize(count, 'item')}`,
  'too-long': (count: number) =>
    `Showing ${count} ${pluralize(count, 'row')} with text that is too long`
}

export const TriviaListTable = forwardRef<HTMLTableElement, TriviaListTableProps>(
  (
    {
      filter,
      focusedCell,
      initialValues,
      onAddItem,
      onBlurCell,
      onFocusCell,
      onSelectRows,
      onUpdate,
      selectedRows,
      setFilter,
      validation
    },
    ref
  ) => {
    const [data, setData] = useState(initialValues)

    // Add this state to track the anchor point of the selection
    const [selectionAnchor, setSelectionAnchor] = useState<number | null>(null)

    const filteredData = useMemo(() => {
      if (filter === 'incomplete') {
        return validation.incompleteItems
      }

      if (filter === 'invalid') {
        return validation.invalidItems
      }

      if (filter === 'similar') {
        return validation.similarItems
      }

      if (filter === 'duplicates') {
        return validation.duplicateItems
      }

      if (filter === 'too-long') {
        return validation.lengthViolationItems
      }

      return data
    }, [validation, data, filter])

    const columns = useMemo<ColumnDef<Item>[]>(
      () => [
        {
          cell: ({ row }) => (
            <div className='flex h-full w-14 flex-1 items-center justify-center rounded-md bg-shade-3'>
              <Checkbox
                className='w-auto rounded-md border-2 border-shade-2'
                id={`select-row-${row.id}`}
                isChecked={row.getIsSelected()}
                onChange={e => {
                  if (
                    e.nativeEvent instanceof MouseEvent &&
                    e.nativeEvent.shiftKey &&
                    selectionAnchor !== null
                  ) {
                    // Handle shift-click
                    const currentIndex = row.index
                    const start = Math.min(selectionAnchor, currentIndex)
                    const end = Math.max(selectionAnchor, currentIndex)

                    // Get IDs within the new range
                    const newSelection = filteredData
                      .slice(start, end + 1)
                      .map(item => item.id.toString())

                    onSelectRows(newSelection)
                  } else {
                    // Normal click - set new anchor point
                    row.getToggleSelectedHandler()(e)
                    setSelectionAnchor(row.index)
                  }
                }}
              />
            </div>
          ),
          enableColumnFilter: false,
          enableSorting: false,
          header: ({ table }) => (
            <Checkbox
              id={`select-all-${table.id}`}
              isChecked={table.getIsAllRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
          ),
          id: 'select',
          meta: { className: 'w-14 justify-center shrink-0' }
        },
        {
          accessorKey: 'id',
          cell: ({ getValue }) => (
            <div className='flex h-full w-16 flex-1 items-center justify-center rounded-md bg-shade-3'>
              <Badge>{parseInt(getValue<string>(), 10) + 1}</Badge>
            </div>
          ),
          className: 'w-12',
          enableColumnFilter: false,
          footer: props => props.column.id,
          header: () => <div className='w-full text-center'>#</div>,
          meta: { className: 'min-w-16 justify-center text-center' }
        },
        {
          accessorKey: 'question',
          footer: props => props.column.id,
          header: () => 'Question',
          meta: {
            className: 'w-full',
            maxLength: schemaConfig.question.maxLength,
            placeholder: 'Enter a question...'
          }
        },
        {
          accessorKey: 'answer',
          footer: props => props.column.id,
          header: () => 'Answer',
          meta: {
            className: 'w-full',
            maxLength: schemaConfig.answer.maxLength,
            placeholder: 'Enter an answer...'
          }
        }
      ],
      [selectionAnchor, onSelectRows, filteredData]
    )

    const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper()

    const table = useReactTable({
      autoResetPageIndex,
      columns,
      data: filteredData,
      defaultColumn: {
        cell: ({ column, getValue, row, table }) => (
          <InputCell
            columnDef={column.columnDef}
            getValue={getValue}
            id={column.id}
            index={row.index}
            table={table}
          />
        )
      },
      enableMultiRowSelection: true,
      enableRowSelection: true,
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      id: 'trivia-list-table',
      meta: {
        addNewItem: onAddItem,
        focusCell: onFocusCell,
        focusedCell,
        focusNextCell: (currentCell: Cell) => {
          const columns = ['question', 'answer']
          const currentColIndex = columns.indexOf(currentCell.columnId)

          if (currentColIndex < columns.length - 1) {
            // Move to next column in same row
            onFocusCell?.({
              columnId: columns[currentColIndex + 1],
              rowIndex: currentCell.rowIndex
            })
          } else if (currentCell.rowIndex < filteredData.length - 1) {
            // Move to first column of next row
            onFocusCell?.({
              columnId: columns[0],
              rowIndex: currentCell.rowIndex + 1
            })
          }
        },
        focusPreviousCell: (currentCell: Cell) => {
          const columns = ['question', 'answer']
          const currentColIndex = columns.indexOf(currentCell.columnId)

          if (currentColIndex > 0) {
            // Move to previous column in same row
            onFocusCell?.({
              columnId: columns[currentColIndex - 1],
              rowIndex: currentCell.rowIndex
            })
          } else if (currentCell.rowIndex > 0) {
            // Move to last column of previous row
            onFocusCell?.({
              columnId: columns[columns.length - 1],
              rowIndex: currentCell.rowIndex - 1
            })
          }
        },
        onBlurCell,
        onFocusCell,
        updateData: (rowIndex: number, columnId: string, value: unknown) => {
          skipAutoResetPageIndex()

          // Locate the row in the full dataset (not just the filtered view)
          const fullRowIndex = data.findIndex(row => row.id === filteredData[rowIndex]?.id)

          if (fullRowIndex === -1) return

          // Update the specific field in the full dataset
          const updatedData = [...data]
          updatedData[fullRowIndex] = {
            ...updatedData[fullRowIndex],
            [columnId]: value
          }

          setData(updatedData)
          onUpdate?.(updatedData)
        }
      },
      onRowSelectionChange: updater => {
        const newSelection =
          typeof updater === 'function' ? updater(table.getState().rowSelection) : updater

        // Get the selected row indices and map them to their corresponding IDs from the filtered data
        const selectedIds = Object.keys(newSelection)
          .filter(key => newSelection[key])
          .map(index => filteredData[parseInt(index)].id.toString())

        onSelectRows(selectedIds)
      },
      state: {
        // Map the selected IDs to their current indices in the filtered data
        rowSelection: Object.fromEntries(
          filteredData
            .map((row, index) => [index, selectedRows.includes(row.id.toString())])
            .filter(([_, selected]) => selected)
        )
      }
    })

    useEffect(() => {
      setData(initialValues)
    }, [initialValues])

    const getFilterMessage = (filter: string) => {
      if (filter in FILTER_MESSAGES) {
        return FILTER_MESSAGES[filter as keyof typeof FILTER_MESSAGES](filteredData.length)
      }

      return ''
    }

    const handleFilterChange = (newFilter: string) => {
      setFilter(newFilter as FilterValue)
      onSelectRows([]) // Reset selection when filter changes
    }

    return (
      <div className='relative'>
        {!Boolean(selectedRows.length) && (
          <div className='mb-4 flex h-16 items-center justify-between'>
            <div className='flex gap-2'>
              {!Boolean(filter) && (
                <>
                  {validation.hasInvalidItems && (
                    <Button
                      className='min-w-[140px] border-red-500'
                      size='small'
                      text={`All Invalid (${validation.invalidItems.length})`}
                      variant='outlined'
                      onClick={() => handleFilterChange('invalid')}
                    />
                  )}

                  {validation.hasIncompleteItems && (
                    <Button
                      className='min-w-[140px] border-red-500'
                      size='small'
                      text={`Incomplete (${validation.incompleteItems.length})`}
                      variant='outlined'
                      onClick={() => handleFilterChange('incomplete')}
                    />
                  )}

                  {validation.hasLengthViolationItems && (
                    <Button
                      className='min-w-[160px] border-red-500'
                      size='small'
                      text={`Too Long (${validation.lengthViolationItems.length})`}
                      variant='outlined'
                      onClick={() => handleFilterChange('too-long')}
                    />
                  )}

                  {validation.hasDuplicateItems && (
                    <Button
                      className='min-w-[160px] border-red-500'
                      size='small'
                      text={`Duplicates (${validation.duplicateItems.length})`}
                      variant='outlined'
                      onClick={() => handleFilterChange('duplicates')}
                    />
                  )}

                  {validation.hasSimilarItems && (
                    <Button
                      className='min-w-[140px] border-yellow-500'
                      size='small'
                      text={`Similar (${validation.similarItems.length})`}
                      variant='outlined'
                      onClick={() => handleFilterChange('similar')}
                    />
                  )}
                </>
              )}

              {Boolean(filter) && (
                <div className='flex gap-4'>
                  <div className='flex items-center'>
                    <span>{getFilterMessage(filter)}</span>
                  </div>
                  <div className='flex items-center'>
                    <Button
                      className='min-w-[120px]'
                      size='small'
                      text='Clear Filter'
                      onClick={() => handleFilterChange('')}
                    />
                  </div>
                </div>
              )}
            </div>

            <span>Total Rows: {data.length}</span>
          </div>
        )}

        {Boolean(selectedRows.length) && (
          <div className='mb-4'>
            <div className='flex items-center justify-between gap-4 rounded-lg bg-shade-1 p-4 shadow-lg'>
              <div className='text-lg font-medium'>
                {selectedRows.length} {pluralize(selectedRows.length, 'item')} selected
              </div>
              <div>
                <Button
                  className='min-w-[180px] border-red-500 bg-red-500'
                  size='small'
                  text='Remove Selected'
                  onClick={() => {
                    // Get the filtered view's selected items to find their original indices
                    const selectedItems = filteredData
                      .filter(item => selectedRows.includes(String(item.id)))
                      .map(item => item.id)

                    // Remove items from the master data list
                    const updatedData = data
                      .filter((_, index) => !selectedItems.includes(index))
                      .map((item, index) => ({ ...item, id: index }))

                    setData(updatedData)
                    onUpdate?.(updatedData)
                    onSelectRows([])
                  }}
                />
              </div>
            </div>
          </div>
        )}

        {validation.hasDuplicateItems && filter === 'duplicates' && (
          <Panel className='mb-6 lg:p-4'>
            <div className='flex items-center justify-start gap-6'>
              <div>
                <WarningBadge />
              </div>

              <div className='flex flex-col gap-2'>
                <Description>
                  To ensure a smooth game experience, avoid repetitive answers like 'True/False',
                  'Yes/No', or multiple-choice answers that may appear more than once.{' '}
                  <ExternalLink href={TRIVIA_KNOWLEDGE_BASE_URL}>Learn more</ExternalLink>
                </Description>
              </div>
            </div>
          </Panel>
        )}

        {validation.hasSimilarItems && filter === 'similar' && (
          <Panel className='mb-6 lg:p-4'>
            <div className='flex items-center justify-start gap-6'>
              <div>
                <TipBadge />
              </div>

              <div className='flex flex-col gap-2'>
                <Description>
                  Similar items in your list won’t prevent you from playing, but consider revising
                  them to ensure each answer is as unique and distinct as possible for a smoother
                  game experience.{' '}
                  <ExternalLink href={TRIVIA_KNOWLEDGE_BASE_URL}>Learn more</ExternalLink>
                </Description>
              </div>
            </div>
          </Panel>
        )}

        <table className='w-full' ref={ref}>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr className='flex w-full' key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <th
                      className={merge('flex', header.column.columnDef.meta?.className)}
                      colSpan={header.colSpan}
                      key={header.id}
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          className={merge(
                            'flex w-full justify-center items-center rounded-lg bg-shade-1 px-4 py-2',
                            { 'pr-2': header.column.getCanFilter() }
                          )}
                        >
                          <Label className={merge({ 'w-full': header.column.getCanFilter() })}>
                            {flexRender(header.column.columnDef.header, header.getContext())}
                          </Label>

                          {header.column.getCanFilter() && (
                            <div>
                              <Filter column={header.column} />
                            </div>
                          )}
                        </div>
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody className='flex w-full'>
            <ScrollContainer className='max-h-[calc(100dvh-540px)]'>
              {table.getRowModel().rows.map(row => {
                return (
                  <tr className='group/row relative flex w-full' key={row.id}>
                    {row.getVisibleCells().map(cell => {
                      return (
                        <td
                          className={merge(
                            'flex items-center justify-center',
                            cell.column.columnDef.meta?.className
                          )}
                          key={cell.id}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </ScrollContainer>
          </tbody>
        </table>

        {Boolean(data.length) && !Boolean(table.getFilteredRowModel().rows.length) && (
          <Panel className='mt-8 flex flex-col gap-4 text-center'>
            <div>No rows to show</div>

            <Container size='extra-small'>
              <Button
                text='Clear All Filters'
                onClick={() => {
                  handleFilterChange('')

                  table.getAllColumns().forEach(column => {
                    column.setFilterValue('')
                  })
                }}
              />
            </Container>
          </Panel>
        )}
      </div>
    )
  }
)
