import { ButtonGroup } from '@repo/ui'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'

import { Button } from '@/components/Button'
import {
  getNextIndex,
  getPreviousIndex,
  updateGameCurrentDrawnItem
} from '@/store/game/updateGameDocument'
import { FINAL_ITEM_INDEX } from '@/utils/constants'

import { useGameContext } from '../context/game-context'

export const TriviaControls = observer(() => {
  const { gameData, gameId, isStartedGame, tracker } = useGameContext()

  const [isLoading, setIsLoading] = useState(false)

  const handleClickNext = async () => {
    setIsLoading(true)

    const nextItemIndex = getNextIndex(gameData.currentItemIndex)
    if (!nextItemIndex) throw new Error('No next item index')

    await updateGameCurrentDrawnItem(gameId, nextItemIndex)
    tracker.nextItem()

    setIsLoading(false)
  }

  const handleClickPrevious = async () => {
    setIsLoading(true)

    const previousItemIndex = getPreviousIndex(gameData.currentItemIndex)

    if (previousItemIndex === null) {
      throw new Error('No previous item index')
    }

    await updateGameCurrentDrawnItem(gameId, previousItemIndex)
    tracker.prevItem()

    setIsLoading(false)
  }

  return (
    <ButtonGroup direction='row'>
      <Button
        disabled={isLoading || !isStartedGame || !gameData.currentItemIndex}
        text='Previous Question'
        variant='outlined'
        onClick={handleClickPrevious}
      />

      <Button
        disabled={isLoading || !isStartedGame || gameData.currentItemIndex === FINAL_ITEM_INDEX}
        text='Next Question'
        onClick={handleClickNext}
      />
    </ButtonGroup>
  )
})
