import { Collections, Game, GameStatus } from '@repo/types'
import { collection, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore'

import { databaseRef } from '@/firebase/firebase'

import { transformGameData } from '../../game/GameStore'
import SnapshotListener from '../../game/snapshot-listeners/AbstractSnapshotListener'
import StoreService from '../../StoreService'

class CurrentGameListener extends SnapshotListener {
  public subscribe(hostId: string): void {
    const gamesCollection = collection(databaseRef, Collections.GAMES)
    const gamesQuery = query(
      gamesCollection,
      where('gameStatus', 'in', [GameStatus.GAME_CREATED, GameStatus.GAME_STARTED]),
      where('hostId', '==', hostId),
      orderBy('timestamp', 'desc'),
      limit(1)
    )

    this._unsubscribe = onSnapshot(
      gamesQuery,
      snapshot => {
        const store = StoreService.getStore()

        if (snapshot.empty) {
          store.setCurrentGame(undefined)
          return
        }

        const gameDocs = snapshot.docs

        const [currentGame] = gameDocs.map(gameDoc => transformGameData(gameDoc.data() as Game))

        store.setCurrentGame(currentGame)
      },
      error => {
        console.error(`Encountered error: ${error.message}`)
      }
    )
  }
}

export const currentGameListener = new CurrentGameListener()
