import { Collections, Game } from '@repo/types'
import { doc, updateDoc } from 'firebase/firestore'

import { Timestamp, databaseRef, setServerTimestamp } from '@/firebase/firebase'

export async function updateGameDrawnItems(gameId: string, drawnItems: Game['drawnItems']) {
  const data: Pick<Game, 'drawnItems' | 'lastActivity'> = {
    drawnItems,
    lastActivity: setServerTimestamp() as Timestamp
  }

  return updateDoc(doc(databaseRef, Collections.GAMES, gameId), data)
}

export async function updateGameSetting(
  gameId: string,
  update: { [key: keyof Game]: unknown }
): Promise<void> {
  const data: Pick<Game, 'lastActivity'> = {
    ...update,
    lastActivity: setServerTimestamp() as Timestamp
  }

  return updateDoc(doc(databaseRef, Collections.GAMES, gameId), data)
}

export async function updateGameLastActivity(gameId: string): Promise<void> {
  const data: Pick<Game, 'lastActivity'> = {
    lastActivity: setServerTimestamp() as Timestamp
  }

  return updateDoc(doc(databaseRef, Collections.GAMES, gameId), data)
}

export async function updateGameCurrentDrawnItem(gameId: string, updatedItemIndex: number) {
  const data: Pick<Game, 'currentItemIndex' | 'lastActivity'> = {
    currentItemIndex: updatedItemIndex,
    lastActivity: setServerTimestamp() as Timestamp
  }

  await updateDoc(doc(databaseRef, Collections.GAMES, gameId), data)

  return updatedItemIndex
}

export function getNextIndex(currentItemIndex: number | null) {
  let nextIndex =
    currentItemIndex === null || currentItemIndex === undefined ? 0 : currentItemIndex + 1

  if (nextIndex >= 74) {
    nextIndex = 74
  }

  return nextIndex
}

export function getPreviousIndex(currentItemIndex: number | null) {
  if (currentItemIndex === null) {
    return null
  }

  return currentItemIndex === 0 ? null : currentItemIndex - 1
}
