import { Spinner } from '@repo/ui'
import { observer } from 'mobx-react-lite'
import { Helmet } from 'react-helmet-async'
import { Redirect, Route, useRouteMatch } from 'react-router-dom'

import StoreService from '@/store/StoreService'

import { ManageTriviaLists } from './manage-trivia-lists'
import { HomeScreenSettingsRoute } from '../../settings/pages/global-settings'

export const ManageTriviaListsRoute = observer(() => {
  const { path } = useRouteMatch()
  const { currentGame, isLoadingGame } = StoreService.getStore()

  if (isLoadingGame) {
    return <Spinner label='Loading...' />
  }

  if (currentGame) {
    return <Redirect to='/game' />
  } else {
    return (
      <>
        <Helmet>
          <title>Manage Trivia Lists | Rockstar Bingo</title>
        </Helmet>

        <Route path='/settings'>
          <HomeScreenSettingsRoute />
        </Route>

        <Route path={path} exact>
          <ManageTriviaLists />
        </Route>
      </>
    )
  }
})
