import { Collections, Device } from '@repo/types'
import { deleteDoc, doc, setDoc } from 'firebase/firestore'
import { makeAutoObservable, toJS } from 'mobx'

import { databaseRef, setServerTimestamp } from '@/firebase/firebase'
import StoreService from '@/store/StoreService'

import { INTEGRATIONS, IntegrationModel } from '..'
import { SpotifyAuthClient } from './auth/auth-client/SpotifyAuthClient'
import { SpotifyPlayback } from './player/SpotifyPlayback'
import { SpotifyPlayerService } from './player/SpotifyPlayerService'
import { SpotifyPlayerStore } from './player/SpotifyPlayerStore'
import { SpotifyClient } from './SpotifyClient'
import { SpotifyDeviceService } from './SpotifyDeviceService'
import { SpotifyDevicesStore } from './SpotifyDevicesStore'
import { SpotifyPlaylistService } from './SpotifyPlaylistService'
import { SpotifyProfileService } from './SpotifyProfileService'

export const SPOTIFY_INTEGRATION_DEFAULTS = {
  enableExternalDevices: true,
  supportsExternalDevices: true,
} as const

export class SpotifyIntegration implements IntegrationModel {
  public id = INTEGRATIONS.SPOTIFY
  public auth
  public client

  public deviceService
  public playerService
  public playlistService
  public profileService

  public devices
  public playback
  public player

  public constructor() {
    makeAutoObservable(this)

    this.auth = new SpotifyAuthClient()
    this.client = new SpotifyClient(this.auth)

    this.deviceService = new SpotifyDeviceService(this)
    this.playerService = new SpotifyPlayerService()
    this.playlistService = new SpotifyPlaylistService(this.client)
    this.profileService = new SpotifyProfileService(this.client)

    this.devices = new SpotifyDevicesStore(
      this.id,
      SPOTIFY_INTEGRATION_DEFAULTS.enableExternalDevices,
      SPOTIFY_INTEGRATION_DEFAULTS.supportsExternalDevices
    )
    this.playback = new SpotifyPlayback()
    this.player = new SpotifyPlayerStore()
  }

  private get user() {
    return StoreService.getStore().user
  }

  private get docRef() {
    return doc(
      databaseRef,
      Collections.USERS,
      this.user.userId,
      Collections.INTEGRATIONS,
      this.id
    )
  }

  init() {
    void this.deviceService.init()
  }

  connect(options?: { currentUrl?: string; showDialog?: boolean }) {
    this.auth.connect(options)
  }

  async disconnect() {
    this.deviceService.settingsListener.unsubscribe()

    await this.devices.setActiveDevice(undefined)

    await this.removeIntegrationSettings()

    this.playerService.disconnect()
    this.auth.disconnect()
  }

  async switchAccount(options?: { currentUrl?: string }) {
    const { currentUrl } = options ?? {}

    await this.disconnect()
    this.connect({ currentUrl, showDialog: true })
  }

  private removeIntegrationSettings(): Promise<void> {
    return deleteDoc(this.docRef)
  }

  public setEnableExternalDevices(
    enableExternalDevices: boolean
  ): Promise<void> {
    return setDoc(
      this.docRef,
      { playback: { enableExternalDevices } },
      { merge: true }
    )
  }

  public setPlaybackDevice(device: Device): Promise<void> {
    const playback = { device: toJS(device), timestamp: setServerTimestamp() }

    return setDoc(this.docRef, { playback }, { merge: true })
  }
}
