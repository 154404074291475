import { observer } from 'mobx-react-lite'

import { useDialogContext } from '@/dialogs/DialogContext'
import { useGameContext } from '@/pages/game/context/game-context'
import { updateGameLastActivity } from '@/store/game/updateGameDocument'

import { PlaybackProgressSpotify } from './PlaybackProgressSpotify'
import { useBeforeUnload } from '../../../../../hooks/useBeforeUnload'
import { useSpotify } from '../../useSpotify'
import { PlaybackButtons } from '../PlaybackButtons'
import { useSpotifyPlaybackError } from '../useSpotifyPlaybackError'

interface PlaybackControlsInternalProps {
  setShowDeviceSelectorModal: (show: boolean) => void
}

export const PlaybackControlsInternal = observer(function PlaybackControlsInternal({
  setShowDeviceSelectorModal
}: PlaybackControlsInternalProps) {
  const { gameData, gameId, isStartedGame } = useGameContext()
  const { setConfirmPrompt } = useDialogContext()

  const { withErrorHandling } = useSpotifyPlaybackError({
    setShowDeviceSelectorModal
  })

  useBeforeUnload()

  const { devices, playback } = useSpotify()
  const { activeDevice } = devices

  const { isLoading, isPlaying } = playback

  const isPlaybackDisabled = !isStartedGame || !activeDevice || isLoading

  const handleClickPrevious = () => {
    setConfirmPrompt({
      heading: 'Are you sure you want to go back to the previous song?',
      onConfirm: () => {
        withErrorHandling(() => {
          updateGameLastActivity(gameId)
          return playback.previous()
        })
      }
    })
  }

  const handleClickNext = () => {
    withErrorHandling(() => {
      updateGameLastActivity(gameId)
      return playback.next()
    })
  }

  const handleClickPause = () => {
    withErrorHandling(() => {
      updateGameLastActivity(gameId)
      return playback.pause()
    })
  }

  const handleClickPlay = () => {
    withErrorHandling(() => {
      updateGameLastActivity(gameId)
      return playback.play()
    })
  }

  return (
    <>
      <PlaybackButtons
        currentItemIndex={gameData.currentItemIndex}
        isPlaybackDisabled={isPlaybackDisabled}
        isPlaying={isPlaying}
        onClickNext={handleClickNext}
        onClickPause={handleClickPause}
        onClickPlay={handleClickPlay}
        onClickPrevious={handleClickPrevious}
      />

      <PlaybackProgressSpotify isPlaying={isPlaying} />
    </>
  )
})
