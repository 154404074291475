import { CreateGameOptions, Pattern, Player, Playlist, Song, Square, TriviaList } from '@repo/types'
import { getAuth } from 'firebase/auth'

import { firebaseApp } from '@/firebase/firebase'
import { removeValidationProperties } from '@/pages/trivia-lists/schema'

import { apiUrl } from '../utils/constants'

enum FetchMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT'
}

class APIClient {
  public async send<T>(
    endpoint: string,
    options?: {
      body?: { [key: string]: unknown }
      method?: FetchMethod
      signal?: AbortSignal
    }
  ): Promise<T> {
    const auth = getAuth(firebaseApp)
    const token = await auth.currentUser?.getIdToken()

    if (!token) {
      throw new Error('Invalid auth token')
    }

    const method = options?.method || FetchMethod.POST
    const body = options?.body

    const response = await fetch(`${apiUrl}${endpoint}`, {
      body: body ? JSON.stringify(body) : null,
      headers: {
        Accept: 'application/json',
        Authorization: `BEARER ${token}`,
        'Content-Type': 'application/json'
      },
      method,
      signal: options?.signal
    })

    let output

    if (response.status === 200) {
      const data = (await response.json()) as T
      if (data) {
        output = data
      }
    }

    if (!response.ok) {
      if (response.status === 401) {
        throw new Error('Unauthorized')
      }

      const error = (await response.json()) as {
        message: Error
        status: number
      }
      throw error
    }

    return output as T
  }

  public createGame(options: CreateGameOptions): Promise<{ gameId: string }> {
    return this.send('/create-game', { body: { ...options } })
  }

  public startGame(gameId: string): Promise<Response> {
    return this.send('/start-game', { body: { gameId } })
  }

  public finishGame(gameId: string): Promise<Response> {
    return this.send('/finish-game', { body: { gameId } })
  }

  public nextSong(gameId: string): Promise<number> {
    return this.send('/next-song', { body: { gameId } })
  }

  public prevSong(gameId: string): Promise<number | null> {
    return this.send('/prev-song', { body: { gameId } })
  }

  public nextItem(gameId: string): Promise<number> {
    return this.send('/next-item', { body: { gameId } })
  }

  public updateTarget(
    gameId: string,
    target: Pattern,
    disableMessageQueue: boolean
  ): Promise<Response> {
    return this.send('/update-target', {
      body: { disableMessageQueue, gameId, target }
    })
  }

  public admitPlayers(gameId: string, playerIdsInLobby: string[]): Promise<Response> {
    return this.send('/admit-players', { body: { gameId, playerIdsInLobby } })
  }

  public admitAllPlayers(gameId: string): Promise<Response> {
    return this.send('/admit-all-players', { body: { gameId } })
  }

  public removePlayers(gameId: string, playerIdsToRemove: string[]): Promise<Response> {
    return this.send('/remove-players', {
      body: { gameId, playerIdsToRemove }
    })
  }

  public readmitPlayers(gameId: string, playerIdsToReadmit: string[]): Promise<Response> {
    return this.send('/readmit-players', {
      body: { gameId, playerIdsToReadmit }
    })
  }

  public verifyBingo(gameId: string, playerId: string): Promise<{ bingoConfirmed: boolean }> {
    return this.send('/verify-bingo', { body: { gameId, playerId } })
  }

  public confirmBingo(
    gameId: string,
    markedPlayer: Player,
    correctLines: { [key: number]: Square[] } = {}
  ): Promise<{ bingoConfirmed: boolean }> {
    return this.send('/confirm-bingo', {
      body: { correctLines, gameId, markedPlayer }
    })
  }

  public denyBingo(gameId: string, playerId: string): Promise<{ bingoConfirmed: boolean }> {
    return this.send('/deny-bingo', { body: { gameId, playerId } })
  }

  public drawAllSongs(gameId: string, playlistTracks: Song[]): Promise<Response> {
    return this.send('/draw-all-songs', {
      body: {
        gameId,
        playlistTracks
      }
    })
  }

  public deleteGame(gameId: string): Promise<Response> {
    return this.send('/delete-game', { body: { gameId } })
  }

  public deleteAllGames(): Promise<Response> {
    return this.send('/delete-all-games')
  }

  public userUpdatePlaylist(options: Playlist): Promise<{ playlistId: string }> {
    return this.send('/update-playlist', {
      body: options
    })
  }

  public userDeletePlaylist(playlistId: string): Promise<Response> {
    return this.send('/delete-playlist', { body: { playlistId } })
  }

  public adminUpdateSystemPlaylist(options: Playlist): Promise<{ playlistId: string }> {
    return this.send('/admin/update-system-playlist', {
      body: options
    })
  }

  public adminDeleteSystemPlaylist(playlistId: string): Promise<Response> {
    return this.send('/admin/delete-system-playlist', { body: { playlistId } })
  }

  public userCreateTriviaList(options: Pick<TriviaList, 'title'>): Promise<{ listId: string }> {
    return this.send('/create-trivia-list', {
      body: options
    })
  }

  public userUpdateTriviaList(options: TriviaList): Promise<{ listId: string }> {
    return this.send('/update-trivia-list', {
      body: removeValidationProperties(options)
    })
  }

  public userDeleteTriviaList(listId: string): Promise<Response> {
    return this.send('/delete-trivia-list', { body: { listId } })
  }

  public getLyrics(title: string, artist: string): Promise<string> {
    return this.send('/get-lyrics', { body: { artist, title } })
  }
}

export const apiClient = new APIClient()
