import { Badge, CheckCircleIcon } from '@repo/ui'
import { InfoTooltip } from '@repo/ui/client'

import { ValidatedTriviaList } from './edit-trivia-list/utils/validate-list'
import { InvalidIcon } from './InvalidIcon'

export function ListValidationIcon({ validatedValues }: { validatedValues: ValidatedTriviaList }) {
  if (validatedValues.validation.validItems.length < validatedValues.validation.minimumItemCount) {
    return (
      <InfoTooltip trigger={<InvalidIcon className='size-6' />}>
        You need at least <strong>{validatedValues.validation.minimumItemCount}</strong> items to
        play a bingo game
      </InfoTooltip>
    )
  }

  if (!validatedValues.validation.isValid) {
    return (
      <InfoTooltip trigger={<InvalidIcon className='size-6' />}>
        <Badge className='w-auto border-red-500' size='small'>
          {validatedValues.validation.invalidItems.length}
        </Badge>{' '}
        invalid items
      </InfoTooltip>
    )
  }

  return (
    <InfoTooltip trigger={<CheckCircleIcon />}>This list is ready to play a bingo game</InfoTooltip>
  )
}
