import { Container } from '@repo/ui'

import { PlaybackAction, PlaybackButton, PlaybackButtonVariant } from '@/components/PlaybackButton'
import { FINAL_ITEM_INDEX } from '@/utils/constants'

interface PlaybackButtonsProps {
  currentItemIndex: number | null
  isPlaybackDisabled: boolean
  isPlaying: boolean
  onClickNext: () => void
  onClickPause: () => void
  onClickPlay: () => void
  onClickPrevious: () => void
}

export function PlaybackButtons({
  currentItemIndex,
  isPlaybackDisabled,
  isPlaying,
  onClickNext,
  onClickPause,
  onClickPlay,
  onClickPrevious
}: PlaybackButtonsProps) {
  return (
    <Container className='flex items-center justify-between' size='extra-small'>
      <PlaybackButton
        actionType={PlaybackAction.PREVIOUS}
        disabled={isPlaybackDisabled || currentItemIndex === 0}
        onClick={onClickPrevious}
      />

      {isPlaying && (
        <PlaybackButton
          actionType={PlaybackAction.PAUSE}
          disabled={isPlaybackDisabled || currentItemIndex === null}
          variant={PlaybackButtonVariant.LARGE}
          onClick={onClickPause}
        />
      )}

      {!isPlaying && (
        <PlaybackButton
          actionType={PlaybackAction.PLAY}
          disabled={isPlaybackDisabled || currentItemIndex === null}
          variant={PlaybackButtonVariant.LARGE}
          onClick={onClickPlay}
        />
      )}

      <PlaybackButton
        actionType={PlaybackAction.NEXT}
        disabled={isPlaybackDisabled || currentItemIndex === FINAL_ITEM_INDEX}
        onClick={onClickNext}
      />
    </Container>
  )
}
