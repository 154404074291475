import { UserSettings } from '@repo/types'
import { makeAutoObservable } from 'mobx'

import { applyProperties } from '../utils'

export interface Host {
  hostId: string
  settings?: UserSettings
}

export class HostStore implements Host {
  hostId = ''
  settings?: UserSettings

  public constructor(host: Host) {
    this.apply(host)
    makeAutoObservable(this)
  }

  get brandImageUrl(): string | undefined {
    return this.settings?.branding?.image?.url
  }

  public apply(updates: Partial<Host>): void {
    applyProperties(this, updates)
  }
}
