import { observer } from 'mobx-react-lite'

import { SidekickMusic } from './sidekick-music'
import { SidekickTrivia } from './sidekick-trivia'
import { useGameContext } from '../context/game-context'

export const SidekickControls = observer(() => {
  const { gameData } = useGameContext()

  if (gameData.type === 'music') {
    return <SidekickMusic />
  }

  if (gameData.type === 'trivia') {
    return <SidekickTrivia />
  }
})
