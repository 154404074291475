import { Column } from '@tanstack/react-table'

export function Filter({ column }: { column: Column<any, any> }) {
  const getValue = () => {
    const columnFilterValue = column.getFilterValue()

    if (typeof columnFilterValue === 'string') {
      return columnFilterValue
    }

    return ''
  }

  return (
    <input
      className='h-8 w-32 rounded border border-shade-2 bg-shade-0 text-sm font-normal'
      placeholder='Filter...'
      type='text'
      value={getValue()}
      onChange={e => column.setFilterValue(e.target.value)}
    />
  )
}
