import { Device, Pattern, PlaybackOption, Player, Song, UserBrandingSettings } from '@repo/types'
import startCase from 'lodash.startcase'

import { getGameDetails } from './getGameDetails'
import { getSongDetails } from './getSongDetails'
import MixpanelService from './MixpanelService'
import { compareChanges } from './utils'
import { VenueFormValues } from '../pages/settings/components/VenueForm'
import { GameStore } from '../store/game/GameStore'

export class GameTracker {
  private game: GameStore

  public constructor(game: GameStore) {
    this.game = game
  }

  private track(eventName: string, properties?: Record<string, unknown>) {
    const gameDetails = getGameDetails(this.game)

    MixpanelService.track(eventName, {
      ...gameDetails,
      ...properties
    })
  }

  public admitAllPlayers(playerCount: number): void {
    this.track('All Players Admitted', {
      'Players in Lobby Count': playerCount
    })
  }

  public admitPlayer(playerId: string): void {
    this.track('Player Admitted', { 'Player ID': playerId })
  }

  public changeBranding(previous: UserBrandingSettings, updated: UserBrandingSettings): void {
    const changes = compareChanges(previous, updated)
    this.track('Branding Changed', changes)
  }

  public changePlaybackDevice(device: Device): void {
    this.track('Playback Device Changed', {
      'Playback Device Type': startCase(device.type)
    })
  }

  public changePlaybackSource(playbackSource: PlaybackOption): void {
    this.track('Playback Source Changed', {
      'Playback Source': startCase(playbackSource)
    })
  }

  public changeTarget(pattern: Pattern): void {
    this.track('Bingo Target Changed', { 'Bingo Target': startCase(pattern) })
  }

  public changeToggle(name: string, isEnabled: boolean): void {
    this.track(`${name} Changed`, {
      [`${name} Enabled`]: isEnabled
    })
  }

  public changeVenue(venueId: string, venueName: string): void {
    this.track('Venue Changed', {
      'Venue ID': venueId,
      'Venue Name': venueName
    })
  }

  public createVenue(venueId: string, venue: VenueFormValues): void {
    this.track('Venue Created', {
      'Venue Address': venue.address,
      'Venue City': venue.city,
      'Venue Country': venue.country,
      'Venue ID': venueId,
      'Venue Name': venue.name,
      'Venue State': venue.state
    })
  }

  public confirmBingo(player: Player, bingoConfirmed: boolean, isManual = false): void {
    this.track('Bingo Confirmed', {
      'Bingo Confirmed': bingoConfirmed,
      'Manually Confirmed': isManual,
      'Player ID': player.playerId
    })
  }

  public copyLinkJumbotron(): void {
    this.track('Jumbotron Link Copied')
  }

  public copyLinkSidekick(): void {
    this.track('Sidekick Link Copied')
  }

  public denyBingo(bingoConfirmed: boolean, isManual: boolean): void {
    this.track('Bingo Denied', {
      'Bingo Confirmed': bingoConfirmed,
      'Manually Denied': isManual
    })
  }

  public finishGame(): void {
    this.track('Game Completed', {
      'Game Completed Reason': 'Closed by User'
    })
  }

  public nextSong(): void {
    this.track('Next Song')
  }

  public prevSong(): void {
    this.track('Previous Song')
  }

  public nextItem(): void {
    this.track('Next Drawn Item')
  }

  public prevItem(): void {
    this.track('Previous Drawn Item')
  }

  public startPlayback(song: Song): void {
    this.track('Playback Started', getSongDetails(song))
  }

  public pausePlayback(song: Song): void {
    this.track('Playback Paused', getSongDetails(song))
  }

  public removePlayer(playerId: string): void {
    this.track('Player Removed', { 'Player ID': playerId })
  }

  public readmitPlayer(playerId: string): void {
    this.track('Player Readmitted', { 'Player ID': playerId })
  }

  public startGame(): void {
    this.track('Game Started')
  }

  public verifyBingo(bingoConfirmed: boolean): void {
    this.track('Bingo Verified', { 'Bingo Confirmed': bingoConfirmed })
  }

  public spotifyDeviceError(): void {
    this.track('Spotify Device Became Unresponsive')
  }

  public spotifyFixDeviceClicked(): void {
    this.track('Spotify Device Unresponsive: Fix Now Clicked')
  }

  public spotifyDeviceRefreshClicked(): void {
    this.track('Spotify Devices: Refresh Clicked')
  }

  public spotifyConnectionError(): void {
    this.track('Spotify Connection Failed')
  }

  public spotifyTrackError(): void {
    this.track('Spotify Track Playback Failed')
  }

  public spotifyPremiumError(): void {
    this.track('Spotify Non-Premium Playback Attempted')
  }
}
