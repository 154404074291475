import { TriviaList } from '@repo/types'

import { apiClient } from '@/api/api-client'
import StoreService from '@/store/StoreService'

import { useCreateTriviaList } from './useCreateTriviaList'

export function useCreateUserTriviaList() {
  const { triviaListService } = StoreService.getStore()

  const { queryFn, queryKey } = triviaListService.queries.userTriviaLists

  const mutationFn = (triviaList: TriviaList) => apiClient.userCreateTriviaList(triviaList)

  return useCreateTriviaList(queryKey, mutationFn, queryFn)
}
