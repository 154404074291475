import { observer } from 'mobx-react-lite'
import { useState } from 'react'

import { Button } from '@/components/Button'
import { getNextIndex, updateGameCurrentDrawnItem } from '@/store/game/updateGameDocument'
import { FINAL_ITEM_INDEX } from '@/utils/constants'

import { useGameContext } from '../context/game-context'

export const NumbersControls = observer(() => {
  const { gameData, gameId, isStartedGame, tracker } = useGameContext()

  const [isLoading, setIsLoading] = useState(false)

  const handleClickNext = async () => {
    setIsLoading(true)

    const nextItemIndex = getNextIndex(gameData.currentItemIndex)
    if (!nextItemIndex) throw new Error('No next item index')

    await updateGameCurrentDrawnItem(gameId, nextItemIndex)
    tracker.nextItem()

    setIsLoading(false)
  }

  return (
    <Button
      disabled={isLoading || !isStartedGame || gameData.currentItemIndex === FINAL_ITEM_INDEX}
      text='Next Number'
      onClick={handleClickNext}
    />
  )
})
