import { Playlist, TriviaList } from '@repo/types'
import { useMutation, useQueryClient } from 'react-query'

export function useDeleteTriviaList(
  mutationFn: (listId: string) => Promise<Response>,
  listQueryKey: string[],
  listQueryFn: () => Promise<TriviaList[]>
) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn,
    onError: (_err, _variables, context: any) => {
      queryClient.setQueryData(listQueryKey, context?.previousLists)
    },
    onMutate: async listId => {
      await queryClient.cancelQueries(listQueryKey)

      const previousLists = queryClient.getQueryData(listQueryKey)

      queryClient.setQueryData(listQueryKey, (oldLists: TriviaList[] = []) =>
        oldLists?.filter(list => list.id !== listId)
      )

      return { previousLists }
    },
    onSettled: () => {
      queryClient.invalidateQueries(listQueryKey)
      queryClient.fetchQuery(listQueryKey, listQueryFn)
    }
  })
}
