import { TriviaList } from '@repo/types'

import { MINIMUM_ITEM_COUNT } from '@/utils/constants'

import { generateHashes, isSimilar } from './deduplication'
import { schemaConfig } from '../../schema'

type Item = {
  answer: string
  id: number
  question: string
}

export function validateTriviaList(list: TriviaList) {
  const { items, validation } = validateTriviaListItems(list.items)

  return {
    ...list,
    items,
    validation
  }
}

export function validateTriviaListItems(items: TriviaList['items'], existingRows: Item[] = []) {
  const questionCounts = new Map<string, number>()
  const answerCounts = new Map<string, number>()
  const allItems = [...existingRows, ...items]

  allItems.forEach(item => {
    const { answerHash, questionHash } = generateHashes(item)
    questionCounts.set(questionHash, (questionCounts.get(questionHash) || 0) + 1)
    answerCounts.set(answerHash, (answerCounts.get(answerHash) || 0) + 1)
  })

  const validatedItems = items.map(item => {
    const isAnswerIncomplete = item.answer.trim().length < schemaConfig.answer.minLength
    const isQuestionIncomplete = item.question.trim().length < schemaConfig.question.minLength

    const isBlank = isAnswerIncomplete && isQuestionIncomplete
    const isIncomplete = isAnswerIncomplete || isQuestionIncomplete

    const hasLengthError =
      item.answer.length > schemaConfig.answer.maxLength ||
      item.question.length > schemaConfig.question.maxLength

    const { answerHash, questionHash } = generateHashes(item)

    const isDuplicate =
      (questionCounts.get(questionHash) || 0) > 1 || (answerCounts.get(answerHash) || 0) > 1

    const similarItems = allItems.filter(
      otherItem => otherItem.id !== item.id && isSimilar(otherItem, item)
    )

    const isSimilarItem = similarItems.length > 0

    const validation = {
      hasLengthError,
      isBlank,
      isDuplicate,
      isIncomplete,
      isSimilar: isSimilarItem && !isDuplicate,
      isValid: !hasLengthError && !isDuplicate && !isIncomplete,
      similarItems
    }

    return {
      ...item,
      validation
    }
  })

  const validItems = validatedItems.filter(item => item.validation.isValid)
  const invalidItems = validatedItems.filter(item => !item.validation.isValid)
  const hasInvalidItems = Boolean(invalidItems.length)

  const incompleteItems = validatedItems.filter(item => item.validation.isIncomplete)
  const hasIncompleteItems = Boolean(incompleteItems.length)

  const similarItems = validatedItems.filter(item => item.validation.isSimilar)
  const hasSimilarItems = Boolean(similarItems.length)
  const duplicateItems = validatedItems.filter(item => item.validation.isDuplicate)
  const hasDuplicateItems = Boolean(duplicateItems.length)

  const lengthViolationItems = validatedItems.filter(item => item.validation.hasLengthError)
  const hasLengthViolationItems = Boolean(lengthViolationItems.length)

  const validation = {
    duplicateItems,
    hasDuplicateItems,
    hasIncompleteItems,
    hasInvalidItems,
    hasLengthViolationItems,
    hasSimilarItems,
    incompleteItems,
    invalidItems,
    isValid: validItems.length >= MINIMUM_ITEM_COUNT && !hasInvalidItems,
    lengthViolationItems,
    minimumItemCount: MINIMUM_ITEM_COUNT,
    similarItems,
    validItems
  }

  return {
    items: validatedItems,
    validation
  }
}

export type ValidatedTriviaList = ReturnType<typeof validateTriviaList>
export type ValidatedItem = ValidatedTriviaList['items'][number]
