import { TriviaList } from '@repo/types'

import { apiClient } from '@/api/api-client'
import StoreService from '@/store/StoreService'

import { useUpdateTriviaList } from './useUpdateTriviaList'

export function useUpdateUserTriviaList({ listId }: { listId: string }) {
  const { triviaListService } = StoreService.getStore()

  const { queryFn: itemQueryFn, queryKey: itemQueryKey } = triviaListService.queries.userTriviaList

  const { queryFn: listQueryFn, queryKey: listQueryKey } = triviaListService.queries.userTriviaLists

  const mutationFn = (triviaList: TriviaList) => {
    return apiClient.userUpdateTriviaList(triviaList)
  }

  return useUpdateTriviaList(
    mutationFn,
    itemQueryKey(listId),
    () => itemQueryFn(listId),
    listQueryKey,
    listQueryFn
  )
}
