import { apiClient } from '@/api/api-client'
import StoreService from '@/store/StoreService'

import { useDeleteTriviaList } from './useDeleteTriviaList'

export function useDeleteUserTriviaList() {
  const { triviaListService } = StoreService.getStore()

  const mutationFn = (listId: string) => apiClient.userDeleteTriviaList(listId)

  const { queries } = triviaListService
  const { queryFn, queryKey } = queries.userTriviaLists

  return useDeleteTriviaList(mutationFn, queryKey, queryFn)
}
